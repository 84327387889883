import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { getWorkflows } from '../../lib/apiClient';
import NewWorkflowButton from './NewButton';

import NoWorkflows from './NoWorkflows';
import { Offcanvas, Badge } from 'react-bootstrap';
import BuilderHeader from './BuilderHeader';
import NewWorkflowFromScratch from './NewFromScratch';
import Activepieces from './Activepieces';
import GenericLoader from '../Common/Loader';
import DisplayDateTime from '../Common/DisplayDateTime';
import DeleteWorkflowDialog from './DeleteWorkflow';
import useFeatureSecurity from '../Common/FeatureSecurity';

const WorkflowsList = observer((props) => {

  useFeatureSecurity("workflowsEnabled", props);


  const [loading, setLoading] = useState(true);
  const [workflows, setWorkflows] = useState([]);
  const [showBuilder, setShowBuilder] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [workflow, setWorkflow] = useState();
  const [signinData, setSigninData] = useState();
  const [builderView, setBuilderView] = useState();
  const [builderViewTitle, setBuilderViewTitle] = useState();
  const [builderDrawerClass, setBuilderDrawerClass] = useState('offcanvas-fullscreen');
  const [showNewFromScratch, setShowNewFromScratch] = useState(false);

  const handleClose = () => {
    listWorkflows();
    setShowBuilder(false);
  }

  useEffect(() => {
    if(props.store.SessionStore.subscription.getFeature("workflowsEnabled")){
      console.log('redirect');
    }
  }, [props.store.SessionStore.subscription]);

  useEffect(() => {
    listWorkflows();  
    // eslint-disable-next-line
  }, [props.store.SessionStore?.streamView?.sid, props.store.SessionStore?.environment?.sid]);


  const handleShowBuilder = async (view, workflow, signinData) => {
    
    //setBuilderDrawerClass(view !== "builder" ? "offcanvas-halfscreen" : "offcanvas-fullscreen");
    setBuilderDrawerClass("offcanvas-fullscreen");

    setWorkflow(workflow);
    setBuilderView(view);
    setSigninData(signinData);
    setShowBuilder(true);
    
    switch (view) {
      case "builder":
        setBuilderViewTitle("Workflow Builder");
        break;
      case "newFromScratch":
        setBuilderViewTitle("Create Workflow");
        break;
      case "newFromTemplate":
        setBuilderViewTitle("Workflow Templates");
        break;
      case "attachExisting":
        setBuilderViewTitle("Attach Existing Workflow");
        break;
      case "runs":
          setBuilderViewTitle("Workflow Runs");
          break;
      default:
        setBuilderViewTitle("Workflow");
    }   
  }

  async function listWorkflows(prepend=true) {
    setLoading(true);
    const options = {
      accountSid : props.store.SessionStore.accountSid,
      bucketSid : props.store.SessionStore.bucket.sid,
      streamViewSid : props.store.SessionStore?.streamView?.sid,
      environmentSid : props.store.SessionStore?.environment?.sid,
    }

    const workflowsResult = await getWorkflows(options);

    if(workflowsResult.successful){
      setWorkflows(workflowsResult.data.records);
    }
    setLoading(false);
  }

  const handleShowDelete = (workflow) => {
    setWorkflow(workflow);
    setShowDeleteDialog(true);
  }

  const displayEnabled = (workflow) => {
    if(workflow.environments.length){
      if(workflow.environments[0].enabled){
        return(<Badge bg="success">Enabled</Badge>);
      }else{
        return (<Badge bg="secondary">Disabled</Badge>);
      }
    }else{
      if(workflow.enabled){
        return(<Badge bg="success">Enabled</Badge>);
      }else{
        return (<Badge bg="secondary">Disabled</Badge>);
      }
    }
  }

  return (
    <>
      {!loading ? (
      <>
        
            {workflows.length > 0 ? (
              <>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="mb-0">Workflows</h2>
                  <NewWorkflowButton {...props} setShowNewFromScratch={setShowNewFromScratch} />
                </div>
                <div className="table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th className="text-center">Per Environment</th>
                        <th className="text-center">Views Attached</th>
                        <th className="text-center">Created</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                    {workflows.map((workflow) => (                      
                      <tr key={`detail_${workflow.sid}`}>
                        <td>{workflow.name}</td>
                        <td className="text-center">{workflow.isPerEnvironment ? (<i className="ti ti-circle-check"></i>):(<i className="ti ti-circle"></i>)}</td>
                        <td className="text-center">{workflow.attachedStreamViewCount}</td>                      
                        <td className="text-center"><DisplayDateTime timestamp={workflow.createdAt} timezone={props.store.SessionStore.user.timezone} /></td>                      
                        <td className="text-center">{displayEnabled(workflow)}</td>
                        <td className="text-center">
                          <div className="dropdown text-center">
                            <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown"><i className="ti ti-dots-vertical"></i></button>
                            <div className="dropdown-menu">
                              <button className="dropdown-item btn-link" onClick={()=>handleShowBuilder("builder", workflow)} ><i className="ti ti-pencil me-1 mb-1"></i>Edit</button>
                              <button className="dropdown-item btn-link" onClick={()=>handleShowBuilder("runs", workflow)} ><i className="ti ti-list-search me-1 mb-1"></i>Runs</button>
                              <button className="dropdown-item btn-link" onClick={()=>handleShowDelete(workflow)} ><i className="ti ti-trash me-1 mb-1"></i>Delete</button>
                            </div>
                          </div>
                        </td>
                      </tr>    
                      ))}          
                    </tbody> 
                  </table>
                </div>
              </>
            ):(<NoWorkflows {...props} handleShowBuilder={handleShowBuilder} setShowNewFromScratch={setShowNewFromScratch} />)}
          
        
        <DeleteWorkflowDialog {...props}
                              workflow={workflow}
                              show={showDeleteDialog} 
                              close={() => setShowDeleteDialog(false)}
                              onPostDelete={(workflow)=>{  
                                listWorkflows();
                                setShowDeleteDialog(false);                                
                              }} />

        <NewWorkflowFromScratch {...props} 
            show={showNewFromScratch} 
            setShow={setShowNewFromScratch}
            onPostCreation={(workflow, signin)=>{
                setShowNewFromScratch(false);
                handleShowBuilder("builder", workflow, signin);
                listWorkflows();  
              }} />

        <Offcanvas show={showBuilder} onHide={handleClose} placement="end" className={builderDrawerClass}>
          <Offcanvas.Body>
            <BuilderHeader {...props} workflow={workflow} close={handleClose} setShowBuilder={setShowBuilder} />
            <Activepieces {...props} 
                          workflow={workflow} 
                          signinData={signinData} 
                          closeDrawer={handleClose}
                          view={builderView}
                          builderViewTitle={builderViewTitle}
                          handleShowBuilder={handleShowBuilder}  />
          </Offcanvas.Body>
        </Offcanvas>
      </>
      ):(<GenericLoader message="Loading Workflows..." />)}
    </>
  );
});

export default WorkflowsList;