import React from 'react';
import { observer } from 'mobx-react';
import { Link } from "react-router-dom";
import { APP_ROUTES } from '../../utils/constants';
import { replacePathPlaceholders } from '../../utils/helpers';

const StreamViews = observer((props) => {  
  return (
    <>
      <li className="menu-header small text-uppercase">
        <span className="menu-header-text">Stream Views</span>
      </li>  

      {props.store.SessionStore.streamViews.map((view) => (
          <li className="menu-item" key={`${view.sid}-menu-item`}>
            <Link to={`${replacePathPlaceholders(APP_ROUTES.STREAM_VIEW, {bucketSid: props.store.SessionStore.getActiveBucketSid(), streamViewSid: view.sid})}?criteria=${encodeURIComponent(view.criteria)}`} className="menu-link">
            {view.icon === "search" ? (<i className="menu-icon tf-icons ti ti-search"></i>) : (<></>)}
            {view.icon === "star" ? (<i className="menu-icon tf-icons ti ti-star"></i>) : (<></>)}
            {view.icon === "alert" ? (<i className="menu-icon tf-icons ti ti-alert-triangle"></i>) : (<></>)}
            {view.icon === "check" ? (<i className="menu-icon tf-icons ti ti-circle-check"></i>) : (<></>)}
            {view.icon === "bolt" ? (<i className="menu-icon tf-icons ti ti-bolt"></i>) : (<></>)}
            {view.icon === "bookmark" ? (<i className="menu-icon tf-icons ti ti-bookmark"></i>) : (<></>)}
            {view.icon === "flag" ? (<i className="menu-icon tf-icons ti ti-flag"></i>) : (<></>)}
            {view.icon === "heart" ? (<i className="menu-icon tf-icons ti ti-heart"></i>) : (<></>)}
             
              <div data-i18n="{view.name}">{view.name}</div>
            </Link>
          </li>
      ))}

    </>
  );
});

export default StreamViews;