import React from 'react';
import { observer } from 'mobx-react';

const MiscLayout = observer((props) => {
  
    return (
        <>

          <div className="container-xxl container-p-y">
            <div className="misc-wrapper">
                {/* Content */}

                {props.children}
                
                {/* / Content */}
            </div>
          </div>
        </>
      
      
    );
});

export default MiscLayout;