import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import { GATEWAY_DOMAIN } from '../utils/constants';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const UrlHelper = (props) => {
    const [formattedGatewayUrl, setFormattedGatewayUrl] = useState('');
    const [formattingSteps, setFormattingSteps] = useState([]);
    const [copied, setCopied] = useState(false);
    const [listenerAdded, setListenerAdded] = useState(false);
    
    useEffect(() => {
        if(!listenerAdded){
            const myModalEl = document.getElementById('urlHelper')
            setListenerAdded(true);
            myModalEl.addEventListener('hidden.bs.modal', event => {
                setFormattedGatewayUrl("");
                setFormattingSteps([]);
                setCopied(false);
                document.getElementById("sourceUrl").value = "";
            })
        }
        
      }, [setFormattedGatewayUrl,setFormattingSteps,setCopied, listenerAdded]);

    const createGatewayUrl = function (event) {
        const target = event.target.value;
        const inputElement = document.createElement('input');
        inputElement.type = 'url';
        inputElement.value = target;

        if (inputElement.checkValidity()) {
            const url = new URL(target);

            const conversionSteps = [];
            let hostname = url.hostname;

            // replace any dashes with double dashes
            hostname = hostname.replace(/-/g, '--');
            conversionSteps.push({ description: "Replace any dash with a double dash.", result: hostname });

            // replace any periods with a dash
            hostname = hostname.replace(/\./g, '-');
            conversionSteps.push({ description: "Replace any period with a dash.", result: hostname });

            // add the bucket to the end.
            hostname = hostname + '-' + props.store.SessionStore.bucket.sid + props.store.SessionStore.environment.sid;
            conversionSteps.push({ description: `Append a dash and then id of this bucket "${props.store.SessionStore.bucket.sid}" and the current environment "${props.store.SessionStore.environment.sid}" `, result: hostname });

            // add the api traffic domain.
            hostname = hostname + '.' + GATEWAY_DOMAIN;
            conversionSteps.push({ description: "Append the string as the subdomain to the API Traffic gateway hostname.", result: hostname });

            // just replace the host name now...
            url.hostname = hostname;

            setFormattedGatewayUrl(url.toString());
            setFormattingSteps(conversionSteps);
        } else {
            setFormattedGatewayUrl("");
            setFormattingSteps([]);
        }
    }

    return (
        <>
            <div className="modal fade" id="urlHelper" tabIndex="-1">
                <div className="modal-dialog">
                    <form className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTopTitle">URL Helper</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col mb-3">
                                    <p>Enter the URL for the API call you wish to proxy through this bucket.</p>
                                    <input type="text" id="sourceUrl" className="form-control" placeholder="https://api.yourdomain.com/endpoint" onKeyUp={createGatewayUrl} autoComplete="off" />
                                </div>
                            </div>
                            {formattingSteps.length > 0 ?(
                                <>
                                    <div className="row" id="url-helper-results">
                                        <div className="col mb-3">
                                            <h5>API Traffic Gateway URL</h5>
                                            <p>Use this URL in your client or code to send request to the gateway and subsequently stored in this bucket.</p>
                                            <div className="input-group">
                                                <input type="text" id="targetUrl" className="form-control" value={formattedGatewayUrl} readOnly data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Tooltip on top" />
                                                <CopyToClipboard text={formattedGatewayUrl} onCopy={() => {
                                                    setCopied(true);
                                                    // only show the message for a bit.
                                                    setTimeout(() => {
                                                        setCopied(false);
                                                    }, "2000");
                                                    }}>
                                                    <span id="url-helper-copy-url" className="input-group-text cursor-pointer"><i className="ti ti-copy"></i></span>
                                                </CopyToClipboard>                            
                                            </div>
                                            {copied ? (
                                                <p className="text-success">The Gateway URL has been copied to your clipboard</p>
                                            ):("")}
                                            
                                            
                                        </div>
                                    </div>

                                    <div className="accordion accordion-without-arrow accordion-bordered" id="url-helper-explain">
                                        <div className="card accordion-item text-black-75">
                                            <h2 className="accordion-header" id="url-helper-explain-title">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#url-helper-explain-detail" aria-controls="url-helper-explain-detail" role="tabpanel">
                                                    <i className="ti ti-star ti-xs me-2"></i>
                                                    Explain the process to create this.
                                                </button>
                                            </h2>

                                            <div id="url-helper-explain-detail" className="accordion-collapse collapse" data-bs-parent="#url-helper-explain">
                                                <div className="accordion-body">
                                                    <p>The hostname is the only thing that needs to change for your URL.</p>
                                                    <ol>
                                                        {formattingSteps.map((step) => (
                                                            <li key={uuid()}>
                                                                {step.description}
                                                                <div className="ms-2"><code>{step.result}</code></div>
                                                            </li>
                                                        ))}
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </>
                                ):(
                                    ""
                                )}   
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" id="url-helper-close-modal">Close</button>
                        </div>
                    </form>





                </div>
            </div>
        </>

    );
}

export default UrlHelper;