import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";
import uuid from 'react-uuid';
import { replacePathPlaceholders } from '../../utils/helpers';
import { APP_ROUTES } from '../../utils/constants';

import Dropdown from 'react-bootstrap/Dropdown';
import { limitTextDisplay } from '../../utils/helpers';
import EnvironmentModal from './EnvironmentModal';

const EnvironmentSelector = observer((props) => {
  const navigate = useNavigate();
  const [showNew, setShowNew] = useState(false);


  const handleClose = () => setShowNew(false);
  const handleShow = () => setShowNew(true);
  
  // Handles modifying the URL when the user selects a different bucket...
  const handleEnvironmentSelection = (environment) => {
    // set the active environment...
    props.store.SessionStore.setActiveEnvironment(environment.sid);
  };
    
    // The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button className="btn btn-link text-dark fw-bold mb-1" ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <i className="ti ti-caret-down"></i>
  </button>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, ] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {/* 
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        */}
        <ul className="list-unstyled mb-0">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);

  return (
    <>
    <Dropdown className="d-inline">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {props.store.SessionStore.environment?.name}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {props.store.SessionStore.environments.map((environment) => (
          <Dropdown.Item key={uuid()} onClick={() => handleEnvironmentSelection(environment)}>{limitTextDisplay(environment.name, 40)}</Dropdown.Item> 
        ))}
        <Dropdown.Divider />
        <Dropdown.Item key={uuid()} onClick={handleShow}><i className="ti align-top ti-circle-plus"></i> Create Environment</Dropdown.Item> 
      </Dropdown.Menu>
    </Dropdown>


    <EnvironmentModal {...props}
                      show={showNew} 
                      close={()=>{
                        setShowNew(false);
                      }}
                      onHide={handleClose} 
                      onPostCreate={(environment)=>{
                        // Set the newly created Environment as the active Environment.
                        props.store.SessionStore.setActiveEnvironment(environment.sid);
                        // navigate to the new Environments...
                        navigate(replacePathPlaceholders(APP_ROUTES.DASHBOARD, {bucketSid: props.store.SessionStore.bucket.sid}));
                      }}/>

    </>
  );
});

export default EnvironmentSelector;