import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useForm } from "react-hook-form";
import { createApiToken } from '../../lib/apiClient';
import { Form, Button} from 'react-bootstrap';
import AlertMessage from '../Common/Alert';

const TokenStep = observer((props) => {
    const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm();
    const [tokens, setTokens] = useState([]);
    //const [appIsConnected, setAppIsConnected] = useState(props.store.SessionStore.bucket?.appIsConnected);
    //const [appPreviouslyConnected] = useState(props.store.SessionStore.bucket?.appIsConnected);
    const [, setShowErrorMessage] = useState(false);


    //let intervalId = null;

    useEffect(() => {

        listApiTokens();
        setTokens(props.store.ApiKeyStore.getEnvironmentTokens(props.store.SessionStore.environment.sid, "ingest"));
      
      // eslint-disable-next-line
      }, [props.store.ApiKeyStore]);

      const onSubmit = async(data, e)  => {
        // ensure the error message is removed in case it is showing...
        setShowErrorMessage(false);

        const params = {
            accountSid: props.store.SessionStore.getAccountSid(),
            environmentSid: props.store.SessionStore.environment.sid,
            name: data.name,
            role: "ingest"
        };
    
        const response = await createApiToken(params);
    
        if(response.successful){
            props.setApiToken(response.data);
            reset();
        }else{
            // there was some error
        }
    } 

    const onError = (errors, e) => {
        console.log(errors);
    };

    async function listApiTokens(prepend=true) {
        await props.store.ApiKeyStore.loadApiTokens();
    }

    const CreateNewToken = ()=>{
        return (
            <>
                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Control type="text" {...register("name", { required: true, maxLength: 50 })}   placeholder="API Token Name" />
                        {errors.name?.type === 'required' && <div className="error-message text-danger">Please enter a name</div>}
                        {errors.name?.type === 'maxLength' && <div className="error-message text-danger">The name cannot be longer than 50 characters</div>}
                      <Form.Text className="text-muted">
                        Provide a name for this API token.
                      </Form.Text>
                    </Form.Group>
                    {!isSubmitting ? (
                    <Button type="submit">Create</Button>
                    ) : (
                        <Button type="submit" disabled>
                            <span className="spinner-border me-1" role="status" aria-hidden="true"></span>
                            Creating...
                        </Button>
                    )}  
                </Form>
            </>
        );
    }

    const TokenDetails = ()=>{
        return (
            <AlertMessage show="true" variant="primary" className="mt-2">
                The token was created! Now just replace "API Token Placeholder" value in the embed code with this value. The example above has been updated.
                <br/>
                <br/>
                {props.apiToken.token}
                <br/>
                <br/>
                ** Please keep this token in a safe place, it will not be shown again.
                <br/>
                <br/>
                Once complete, proceed to the next step.
            </AlertMessage>
        );
    }

    const ExistingToken = ()=>{
        return (
            <AlertMessage show="true" variant="primary" className="mt-2">
                Great! Go ahead and grab that token and place it into the embed code as the value to the "token" property. Once complete, proceed to the next step.
            </AlertMessage>
        );
    }
    
    return (
            <>
                {!tokens.length ? (
                    <>
                        <h3 className="text-primary mt-4">Step {props.step}: Create API Ingest Token</h3>
                        <p className="card-text">
                        When pushing data to ApiTraffic, a valid ingest API token is necessary. Currently, no tokens have been created for this environment.
                        </p>
                        <div class="container d-flex justify-content-center">
                            <div class="w-60" style={{width: "50%"}}>
                                {(!props.apiToken) && <CreateNewToken />}
                                {(props.apiToken) && <TokenDetails />}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h3 className="text-primary mt-4">Step {props.step}: API Ingest Token Selection</h3>
                        <p className="card-text">
                            There {tokens.length === 1 ? (<>is 1 existing ingest token, that token can be used if you have it stil, or a new one can be created.</>) : (<>are {tokens.length} existing ingest tokens, any of those tokens can be used if you still have them. If not you can create a new one.</>)}
                        </p>
                        <div class="row">
                            <div class="col-md mb-md-0 mb-2">
                                <div class="form-check custom-option custom-option-basic">
                                    <label class="form-check-label custom-option-content" for="customRadioTemp1">
                                        <input name="customRadioTemp" class="form-check-input" type="radio" value="" id="customRadioTemp1" onClick={()=>props.setTokenStatus('new')} />
                                        <span class="custom-option-header">
                                            <span class="fw-medium">Create New</span>
                                        </span>
                                        <span class="custom-option-body">
                                            {(props.tokenStatus === 'new' && !props.apiToken) && <CreateNewToken />}
                                            {(props.tokenStatus === 'new' && props.apiToken) && <TokenDetails />}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-check custom-option custom-option-basic">
                                    <label class="form-check-label custom-option-content" for="customRadioTemp2">
                                        <input name="customRadioTemp" class="form-check-input" type="radio" value="" id="customRadioTemp2" onClick={()=>props.setTokenStatus('existing')}/>
                                        <span class="custom-option-header">
                                            <span class="fw-medium">Use Existing Token</span>
                                        </span>
                                        <span class="custom-option-body">
                                        {props.tokenStatus === 'existing' && <ExistingToken />}
                                    </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>
                )}                
            </>      
    );
});

export default TokenStep;



