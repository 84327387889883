import React from 'react';
import ResponseTab from './ResponseTab';
import RequestTab from './RequestTab';
import TimingsTab from './TimingsTab';

import TagsTab from './TagsTab';
import TracesTab from './TracesTab';

const RequestDetail = (props) => {
    let requestActive = "";
    let responseActive = "";
    let requestActiveDetail = "";
    let responseActiveDetail = "";

    if(!props.request.capture){
        requestActive = "";
        responseActive = "active";
        requestActiveDetail = "";
        responseActiveDetail = "show active";
    }else{
        requestActive = "active";
        responseActive = "";
        requestActiveDetail = "show active";
        responseActiveDetail = "";
    }

  return (
    <>      

    {/*<!--- Request Details --->*/}
    <div className="row d small request-detail" id={"request-" + props.request.sid}>
        <div className="col-12">       
            <ul className="nav nav-tabs me-0 ms-0" role="tablist">
                <li className="nav-item">
                    <button type="button" className={"request-detail-tab-request nav-link " + requestActive} data-bs-toggle="tab" data-bs-target={"#request-" + props.request.sid + "-request"} role="tab" title="Request Details">Request</button>
                </li>
                {!props.request.capture ? (
                <>
                    <li className="nav-item">
                        <button type="button" className={"request-detail-tab-response nav-link " + responseActive} data-bs-toggle="tab" data-bs-target={"#request-" + props.request.sid + "-response"} role="tab" title="Response Details">Response</button>
                    </li>
                    {props.request.timings.phases ? (
                        <>
                            <li className="nav-item">
                                <button type="button" className="request-detail-tab-connection nav-link" data-bs-toggle="tab" data-bs-target={"#request-" + props.request.sid + "-connection"} role="tab" title="Connection">Connection</button>
                            </li>
                        </>
                    ) : ('')}
                    {/* 
                    <li className="nav-item">
                        <button type="button" className="request-detail-tab-firewall nav-link" data-bs-toggle="tab" data-bs-target={"#request-" + props.request.sid + "-firewall"} role="tab" title="Firewall">Firewall</button>
                    </li>
                    */}
                    <li className="nav-item">
                        <button type="button" className="request-detail-tab-tags nav-link" data-bs-toggle="tab" data-bs-target={"#request-" + props.request.sid + "-tags"} role="tab" title="Tags">Tags ({props.request?.tags && Object.keys(props.request.tags).length ? Object.keys(props.request.tags).length : 0})</button>
                    </li>
                    <li className="nav-item">
                        <button type="button" className="request-detail-tab-traces nav-link" data-bs-toggle="tab" data-bs-target={"#request-" + props.request.sid + "-traces"} role="tab" title="Traces">Traces ({props.request?.traces && props.request.traces.length ? props.request.traces.length : 0})</button>
                    </li>
                </>
                ) : ('')}
                
            </ul>
            <div className="tab-content p-3 pt-4">
                <div className={"tab-pane fade " + requestActiveDetail} id={"request-" + props.request.sid + "-request"} role="tabpanel">
                    <RequestTab key={`request_tab_${props.request.sid}`} request={props.request.request} sid={props.request.sid} capture={props.request.capture} />
                </div>
                
                <div className={"tab-pane fade " + responseActiveDetail} id={"request-" + props.request.sid + "-response"} role="tabpanel">
                
                    {props.request.firewall.blocked === false && !props.request.capture ? (
                        <ResponseTab key={`response_tab_${props.request.sid}`} response={props.request.response} sid={props.request.sid} />
                    ) : (
                        <div className="alert alert-danger" role="alert">
                            This request was blocked by the firewall, see firewall tab for details.
                        </div>
                    )}    

                </div>
                {props.request?.timings?.phases ? (
                    <>
                        <div className="tab-pane fade" id={"request-" + props.request.sid + "-connection"} role="tabpanel">
                            <TimingsTab key={`timings_tab_${props.request.sid}`} timings={props.request?.timings} sid={props.request.sid} />
                        </div>
                    </>
                ) : ('')}
{/* 
                <div className="tab-pane fade" id={"request-" + props.request.sid + "-firewall"} role="tabpanel">
                    
                    {props.request.firewall.blocked === false ? (
                        <div className="alert alert-success" role="alert">
                            This request was not blocked by the firewall.
                        </div>
                    ) : (
                        <div className="alert alert-danger" role="alert">
                            This request was blocked by the firewall.
                        </div>
                    )}    
                </div>
*/}

                <div className="tab-pane fade" id={"request-" + props.request.sid + "-traces"} role="tabpanel">
                    <TracesTab key={`traces_tab_${props.request.sid}`} traces={props.request.traces} sid={props.request.sid}  />
                </div>

                <div className="tab-pane fade" id={"request-" + props.request.sid + "-tags"} role="tabpanel">
                    <TagsTab key={`tags_tab_${props.request.sid}`} tags={props.request.tags} sid={props.request.sid}  />
                </div>
            </div>        
        </div>
    </div>
    {/* <!--- / Request Details ---> */}     
    </>
  );
}

export default RequestDetail;