import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import Alert from 'react-bootstrap/Alert';
import uuid from 'react-uuid';

const AlertMessage = observer((props) => {
  const [show, setShow] = useState(props.show || false);
 
  useEffect(() => {
    setShow(props.show);
    // eslint-disable-next-line
  }, [props.show]);

  const clearAlert = () => {
    setShow(false);
    if(props.onClear){
      props.onClear();
    }
  }

  if(show){
    return (
      <Alert key={uuid()} variant={props.variant || "danger"} onClose={() => clearAlert()} dismissible={props.dismissible || false}>
        {props.icon ? (<i className={`ti mb-1 ${props.icon}`}></i>) : (<></>)} {props.children}
      </Alert>
    );
  }

});

export default AlertMessage;



