// some of these changes may need to be made in /public/settings.js as well 

export let API_URL = 'http://localhost:82/v1';
export let GATEWAY_DOMAIN = 'dev.apitraffic.io';
export let OUTSETA_HOST = 'api-traffic-inspector-staging.outseta.com';
export let OUTSETA_DEFAULT_PLAN = 'pWrwVgmn';
export let POST_HOG_KEY = 'phc_GkV14W0iWloL6188KXjT8b8bq5WQsLPZCUu5NVwHSpV';
export let POST_HOG_HOST  = 'https://app.posthog.com';

export let ACTIVEPIECES_URL = 'https://flow.dev.apitraffic.io';

export let DEFAULT_FEATURE_SETTINGS = {
  teamMembers: 5,
  requestLimit: 100,
  workflowsEnabled: true,
  streamViewsEnabled: true,
  environmentsEnabled: true
};

if (process.env.REACT_APP_ENV === 'development') {
  API_URL = 'https://api.dev.apitraffic.io/v1';
  GATEWAY_DOMAIN = 'dev.apitraffic.io';
}

if (process.env.REACT_APP_ENV === 'production') {
  API_URL = 'https://api.apitraffic.io/v1';
  OUTSETA_HOST = 'apitraffic.outseta.com';
  OUTSETA_DEFAULT_PLAN = 'A934V3W0';
  GATEWAY_DOMAIN = 'apitraffic.io';
  ACTIVEPIECES_URL = 'https://flow.apitraffic.io';
  POST_HOG_KEY = 'phc_8pHLNKz1NFRhxGchC1EJfa5UEAO6WLojGjwMBmPHiIc';
}

export let KB_URL = `https://${OUTSETA_HOST}/support/kb`;

export const API_ROUTES = {
  SIGN_UP: `${API_URL}/auth/signup`,
  SIGN_IN: `${API_URL}/auth/signin`,
  GET_USER: `${API_URL}/authentication/me`
}

export const APP_ROUTES = {
  DEFAULT: '/',
  SIGN_UP: '/signup',
  SIGN_IN: '/signin',
  SIGN_OUT: '/signout',  
  BUCKET_BASE: '/buckets',
  DASHBOARD: '/buckets/:bucketSid',
  TRAFFIC: '/buckets/:bucketSid/traffic',
  STREAM_VIEW: '/buckets/:bucketSid/traffic/:streamViewSid',
  ERRORS: '/buckets/:bucketSid/errors',
  SHARED: '/buckets/:bucketSid/shared',
  CAPTURES: '/buckets/:bucketSid/captures',
  SETTINGS: '/buckets/:bucketSid/settings',
  COLLECTIONS: '/buckets/:bucketSid/collections',
  WORKFLOWS: '/buckets/:bucketSid/workflows',
  GETTING_STARTED: '/buckets/:bucketSid/setup',  
  ACCOUNT_BASE: '/account',
  API_TOKENS: '/account/integration-tokens',
  ENVIRONMENTS: '/account/environments',
  PROFILE: '/account/profile',
  ACCOUNT: '/account/info',
  ACCOUNT_PLAN: '/account/plan',
  ACCOUNT_TEAM: '/account/team-members',
  ACCOUNT_PLAN_CHANGE: '/account/plan-change',
  ACCOUNT_BILLING: '/account/billing',
  SUPPORT: '/account/support'
}