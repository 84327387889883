import React from 'react';
import { observer } from 'mobx-react';
import { Link, useLocation } from "react-router-dom";

import { APP_ROUTES } from '../../utils/constants';
import { replacePathPlaceholders } from '../../utils/helpers';
import StreamViews from './StreamViews';

const LeftNav = observer((props) => {

  const location = useLocation();

  const isActive = function(route){
    const checkRoute = replacePathPlaceholders(route, {bucketSid: props.store.SessionStore.getActiveBucketSid()});
    const currentRoute = location.pathname;
    if(currentRoute === checkRoute){
      return "menu-item active";
    }
    return "menu-item";
  }

  return (
    <>
    {/* Menu */}
    
    <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
      <div className="app-brand demo">
        <a href={replacePathPlaceholders(APP_ROUTES.DASHBOARD, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="app-brand-link">
          <img src="/assets/images/apitraffic-logo.svg" width="200" alt="ApiTraffic Logo"/>
        </a>
      </div>

    <div className="menu-inner-shadow"></div>
    
    
    <ul className="menu-inner py-1">
        
      {/* Apps & Pages */}
      {props.store.SessionStore.bucket?.appIsConnected ? (
        <>
          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Analyze</span>
          </li>  
            
          <li className={isActive(APP_ROUTES.DASHBOARD)} id="left-nav-dashboard">
            <Link to={replacePathPlaceholders(APP_ROUTES.DASHBOARD, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
              <i className="menu-icon tf-icons ti ti-chart-infographic"></i>
              <div data-i18n="Dashboard">Dashboard</div>
            </Link>
          </li>
        </>
      ) : (<>
        <li className="menu-header small text-uppercase mt-4">
            <span className="menu-header-text">General</span>
          </li>  
        <li className={isActive(APP_ROUTES.DASHBOARD)} id="left-nav-dashboard">
            <Link to={replacePathPlaceholders(APP_ROUTES.GETTING_STARTED, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
              <i className="menu-icon tf-icons ti ti-chart-infographic"></i>
              <div data-i18n="Dashboard">Getting Started</div>
            </Link>
          </li>
      </>)}

      
      
      <li className="menu-header small text-uppercase">
        <span className="menu-header-text">Streams</span>
      </li>

      {props.store.SessionStore.bucket?.appIsConnected ? (
        <>
            <li className={isActive(APP_ROUTES.TRAFFIC) || isActive(APP_ROUTES.DASHBOARD)} id="left-nav-all-traffic">
              <Link to={replacePathPlaceholders(APP_ROUTES.TRAFFIC, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
                <i className="menu-icon tf-icons ti ti-arrow-ramp-right"></i>
                <div data-i18n="All Traffic">All Traffic</div>
              </Link>
            </li>
            {/* 
          <li className={isActive(APP_ROUTES.COLLECTIONS)}>
            <Link to={replacePathPlaceholders(APP_ROUTES.COLLECTIONS, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
              <i className="menu-icon tf-icons ti ti-package"></i>
              <div data-i18n="Collections">Collections</div>
            </Link>
          </li>
          */}
          <li className={isActive(APP_ROUTES.ERRORS)} id="left-nav-errors">
            <Link to={replacePathPlaceholders(APP_ROUTES.ERRORS, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
              <i className="menu-icon tf-icons ti ti-zoom-exclamation"></i>
              <div data-i18n="Errors">Errors</div>
            </Link>
          </li>
          {/* 
          <li className={isActive(APP_ROUTES.SHARED)}>
            <Link to={replacePathPlaceholders(APP_ROUTES.SHARED, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
              <i className="menu-icon tf-icons ti ti-share"></i>
              <div data-i18n="Shared">Shared</div>
            </Link>
          </li>
        */}
        </>
      ) : (<></>)}

      
      <li className={isActive(APP_ROUTES.CAPTURES)} id="left-nav-captures">
          <Link to={replacePathPlaceholders(APP_ROUTES.CAPTURES, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link" data-o-person-activity="Viewed Captures">
              <i className="menu-icon tf-icons ti ti-capture"></i>
              <div data-i18n="Captures">Captures</div>
          </Link>
      </li>
{/* 
      <li className="menu-header small text-uppercase">
        <span className="menu-header-text">Management</span>
      </li>

      <li className={isActive(APP_ROUTES.WORKFLOWS)} id="left-nav-captures">
          <Link to={replacePathPlaceholders(APP_ROUTES.WORKFLOWS, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link" data-o-person-activity="Viewed Workflows">
              <i className="menu-icon tf-icons ti ti-switch-3"></i>
              <div data-i18n="Captures">Workflows</div>
          </Link>
      </li>

      <li className={isActive(APP_ROUTES.WORKFLOWS)} id="left-nav-captures">
          <Link to={replacePathPlaceholders(APP_ROUTES.WORKFLOWS, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link" data-o-person-activity="Viewed Workflows">
              <i className="menu-icon tf-icons ti ti-viewfinder"></i>
              <div data-i18n="Captures">Stream Views</div>
          </Link>
      </li>
*/}
      {props.store.SessionStore.streamViews.length > 0 ? (<StreamViews {...props} />) : (<></>)}

      <li className="menu-header small text-uppercase">
        <span className="menu-header-text">Utilities</span>
      </li>

      {props.store.SessionStore.bucket?.appIsConnected ? (
        <>
          <li className="menu-item" id="left-nav-url-helper">
            <a className="menu-link" id="url-helper-menu-item" href={replacePathPlaceholders(APP_ROUTES.TRAFFIC, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} data-bs-toggle="modal" data-bs-target="#urlHelper" data-o-person-activity="Clicked URL Helper">
              <i className="menu-icon tf-icons ti ti-wand"></i>
              <div data-i18n="URL Helper">URL Helper</div>
            </a>
          </li>
          <li className="menu-item" id="left-nav-url-helper">
            <Link to={replacePathPlaceholders(APP_ROUTES.GETTING_STARTED, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
              <i className="menu-icon tf-icons ti ti-code"></i>
              <div data-i18n="URL Helper">SDK Setup</div>
            </Link>
          </li>
        </>
      ) : (<></>)}

        
        <li className={isActive(APP_ROUTES.SETTINGS)} id="left-nav-bucket-settings">
          <Link to={replacePathPlaceholders(APP_ROUTES.SETTINGS, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link" data-o-person-activity="Clicked Bucket Settings">
            <i className="menu-icon tf-icons ti ti-adjustments"></i>
            <div data-i18n="Bucket Settings">Bucket Settings</div>
          </Link>
        </li>


        {/*
        <li className="menu-item">
          <a className="menu-link" id="url-helper-menu-item" href={handleClick} data-bs-toggle="modal" data-bs-target="#tourOptions">
            <i className="menu-icon tf-icons ti ti-wand"></i>
            <div data-i18n="URL Helper">Tours</div>
          </a>
        </li>
        */}
       </ul>

      {/*  
      <div className="card text-primary bg-transparent border border-success mb-5 mx-2">
        <div className="card-body">
          <h5 className="card-title text-primary">Send all API traffic to this bucket</h5>
          <p className="card-text">
            Alias your domain name to send all API traffic through this bucket to gain complete insights and leverage our security features.
          </p>
        </div>
        <div className="card-footer">
          <a href="https://forms.gle/G1pPBrEBYhwb4BU88" target="_blank" rel="noreferrer">Learn more</a>
        </div>
      </div>
      */}

      
      <div className=" mb-3 mx-2 text-secondary text-center">
        Bucket: {props.store.SessionStore.getBucketSidFromUrl()}
      </div>
      

  </aside>
  {/* / Menu */}
  </>
  );
});

export default LeftNav;