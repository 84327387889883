import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { getURLSegment } from '../utils/helpers';
import { useLocation } from 'react-router-dom';
import { useOutseta } from '../Providers/OutsetaProvider';

const Account = observer((props) => {
    const { outsetaRef, wrapperRef, embedOutseta, status } = useOutseta();
    const location = useLocation();
    const [isOutsetaLoaded, setIsOutsetaLoaded] = useState(false);
    const [tab, setTab] = useState(null);
    
    useEffect(() => {
        if (status === "ready") {   
            embedOutseta();
            setIsOutsetaLoaded(true);
            setTab(getURLSegment(2));
        }
        // eslint-disable-next-line
      }, [status]);

      useEffect(() => {
        if (isOutsetaLoaded) {   
            setTab(getURLSegment(2));
        }
        // eslint-disable-next-line
      }, [location]);

      useEffect(() => {
        if(tab){
            switch (tab) {
                case "plan":
                    openProfileTab("plan");
                    break;
                case "billing":
                    openProfileTab("billing");
                    break;
                case "info":
                    openProfileTab("account");
                    break;
                case "profile":
                    openProfileTab("profile");
                    break;
                case "plan-change":
                    openProfileTab("planChange");
                    break;
                case "team-members":
                    openProfileTab("team");
                    break;
                default:
                    openProfileTab("profile");
                    break;
            }
        }
        // eslint-disable-next-line
      }, [tab]);
      
      const openProfileTab = (loadTab) => {
        if(loadTab){
            try{
                outsetaRef.current.profile.open({ tab: loadTab });                
            }catch(e){
                console.log(e);
                setTimeout(function(){
                    openProfileTab(loadTab);
                }, 500);
            }            
        }        
      };

    
      return (
        <div className="row">
            <div className="col-md-12">
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">                    
                                <div ref={wrapperRef} id="profile-embed-wrapper"></div>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Account;



