import React from "react";

const ChartNoData = (props) => {
    const defaultLabel = "No data to display";
    return (
        <div className="text-center mt-5">
            {props?.label ? (props.label) : (defaultLabel)}
        </div>
      );
};

export default ChartNoData;