

import React from 'react';
import uuid from 'react-uuid';
import {KB_URL} from '../../utils/constants';

const TagsTab = (props) => {
  return (
    <ul className="list-unstyled mx-2">
        {Object.keys(props.tags).length > 0 ? (
            <>
                {Object.keys(props.tags).map(key => (
                    <li key={uuid()}><strong>{key}:</strong> {props.tags[key]}</li>
                ))}
            </>
        ) : (
            <div className="alert alert-warning" role="alert">
                No tags were logged for this request. <a href={`${KB_URL}/articles/E9L05Z9w/tagging-requests`} className="alert-warning" target="_blank" rel="noreferrer"><u>Learn more</u></a> about tagging requests.
            </div>
        )}                    
    </ul>
  );
}

export default TagsTab;

