import React from 'react';
import Chart from "react-apexcharts";

const RequestDetail = (props) => {
    const chartDef = {
        series: [
            {
                data: []
            }
        ],
        options: {
            chart: {
              height: 0, // height will be calculated below...
              type: 'rangeBar',
              toolbar: {show: false},
              zoom: {enabled: false}
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                  }
              }
            },
            xaxis: {
                labels: {
                    show: false
                }
            },
            yaxis: {
                
              },
            dataLabels: {
                enabled: true,
                //textAnchor: 'end',
                offsetX: 42,
                style: {
                    colors: ['#333']
                },
                formatter: function (val, opts) {
                    return `${val[1]-val[0]}ms` 
                }
            },
            
          }
          
    };

    const phases = [
        {
            key: 'dns',
            label: 'DNS'
        },        
        {
            key: 'tcp',
            label: 'TCP',
            color: ''
        },
        {
            key: 'tls',
            label: 'TLS',
            color: ''
        },
        {
            key: 'wait',
            label: 'Wait',
            color: ''
        },       
        {
            key: 'request',
            label: 'Request',
            color: ''
        },
        {
            key: 'firstByte',
            label: 'First Byte',
            color: ''
        },        
        {
            key: 'download',
            label: 'Download',
            color: ''
        }        
    ];
    let runningTime = 0;
    // loop the phases building up the series...
    for (const phase of phases) {

        const item = {
            x: phase.label,
            y: [runningTime , runningTime + props.timings?.phases[phase.key]],
            fillColor: '#675DE6'
        };
        
        runningTime = runningTime + props.timings?.phases[phase.key];
        if(props.timings?.phases[phase.key] > 0){
            chartDef.series[0].data.push(item);
            chartDef.options.chart.height = chartDef.options.chart.height + 50;
        }       
    };     

  return (
    <>      
        <div className="text-lighter fw-semibold">REQUEST TIMING</div>
        
        {props.timings?.phases ? (
            <>
                <div className="mt-2">This request took a total of <strong>{props.timings.phases.total} ms</strong> to complete. The breakdown of each phase of the request is below.</div>
                <div className="request-detail-request-timing-chart-container">
                    <Chart options={chartDef.options} series={chartDef.series} type="rangeBar" height={chartDef.options.chart.height} />
                </div>
            </>
        ) : (
            <div className="alert alert-danger" role="alert">
                Captures do not have timings.
            </div>
        )}       
    </>
  );
}

export default RequestDetail;