import React, { useEffect } from "react";
import { observer } from "mobx-react";
import EnvironmentTokens from "../Components/ApiTokens/EnvironmentTokens";

const ApiTokens = observer((props) => {
    
  useEffect(() => {

    listApiTokens();
  
    // eslint-disable-next-line
  }, [props.store.ApiKeyStore]);
 
  async function listApiTokens() {
    await props.store.ApiKeyStore.loadApiTokens();
  }

  return (
    <>

    {props.store.SessionStore.environments.map((environment) => (
      <EnvironmentTokens {...props} environment={environment} listApiTokens={listApiTokens} />
    ))}

    </>
  );
});

export default ApiTokens;
