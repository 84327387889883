import { action, observable, runInAction } from 'mobx';
import { listApiTokens} from '../lib/apiClient';

import SessionStore from './SessionStore';

function ApiKeyStore() {

  let obx = observable({
    authenticated: false,
    user: null,
    tokens: [],
    
    getIngestToken: action (async function() {
      let tokenSid = "";
      for (const token of obx.tokens) {
        if (token.role === 'ingest') {
            tokenSid = token.token;
            break;  // Breaks the loop when cherry is found
        }
      }
      return tokenSid;
    }),

    loadApiTokens: action (async function() {
      try {
            const results = await listApiTokens(SessionStore.getAccountSid());

            if(results.successful){
              runInAction(() => {
                obx.tokens = results.data.records;
              });
            }

        return true;
      }
      catch (err) {
        //console.log('Error loading buckets...', err);
        return false;
      }
    }),

    getEnvironmentTokens: action (function(environmentSid, role) {
      const result = [];
      
      // eslint-disable-next-line
      obx.tokens.map((token) => {
        //console.log(`${token.environmentSid} === ${environmentSid}`);
        if(token.environmentSid === environmentSid){
          if(!role || role === token.role){
            result.push(token);
            return true;
          }          
        }
      });
      
      return result;
    })
    
  });

  


  async function init() {
    //await obx.isAuthenticated()
  }

  init();

  return obx;

}

export default ApiKeyStore();