import { action, observable, runInAction, reaction } from 'mobx';

import { listRequests, getRequest} from '../lib/apiClient'
import {getParam} from '../utils/helpers';

import sha256 from 'crypto-js/sha256';

// Components
import SessionStore from './SessionStore';

function TrafficStore(){

  let obx = observable({
    view: 'list',
    requests: [],
    requestsNext: null,
    hasNewRequests: false,
    newRequestCount: 0,
    newTrafficListenerDelay: 5000,
    from: null,
    defaultCriteria:"",
    userDefinedCriteria:"",
    fullSearchCriteria: "",
    fullSearchCriteriaHash: "",
    filterContextProperties: {},
    hasError:false,
    errorMessage: null,
    loadingTraffic: false,
    hasMore: false,
    prepend: false,
    stores: {},

    reset: action(function reset(){
      obx.requests = [];
      obx.requestsNext = null;
      obx.hasNewRequests = false;
      obx.newRequestCount = 0;
      obx.lastRequestTime = null;
      obx.from = null;
      obx.defaultCriteria = "";
      obx.userDefinedCriteria = "";
    }),
    
    /**
     * resets the criteria variable...
     */
    resetView: action( function resetView(criteria="", view="") {
      obx.requests = [];
      obx.defaultCriteria = criteria.length ?  criteria : "";
      obx.userDefinedCriteria = "";
      obx.setFullSearchCriteria();
      obx.setCriteriaFromUrl();
      obx.fromRequestTime = null;
      obx.view = view;
    }),

    /**
     * Sets the full search criteria
     */
    setFullSearchCriteria: action( function setFullSearchCriteria() {
      if(obx.userDefinedCriteria.length){
        obx.fullSearchCriteria = `${obx.defaultCriteria} AND ${obx.userDefinedCriteria}`.trim();
      }else{
        obx.fullSearchCriteria = obx.defaultCriteria;
      }            
      obx.fullSearchCriteriaHash = sha256(obx.fullSearchCriteria);
    }),

    /**
     * If user defined criteria was passed in...
     */
    hasUserDefinedCriteria: action( function hasUserDefinedCriteria() {
      if(obx.userDefinedCriteria.length){
        return true;
      }else{
        return false;
      }
    }),

    /**
     * Checks to see if a value is set as user defined criteria...
     */
    isUserDefinedCriteria: action( function isUserDefinedCriteria(keyToCheck) {
      if(!obx.userDefinedCriteria.length){
        return "";
      }else{
        const parts = obx.userDefinedCriteria.split(" AND ");

        for (const part of parts) {
          const stringParts = part.split(" ");

          if(stringParts.length){
            const key = stringParts[0];
            if(key === keyToCheck){
              return stringParts[2];
            }
          }
        }
        return "";
      }
    }),

    /**
     * adds criteria
     */
    addCriteria: action( function addCriteria(criteria="") {
      obx.userDefinedCriteria = criteria.length ? criteria : "";
      obx.setFullSearchCriteria();
    }),

    /**
     * Gets criteria
     */
    getSearchCriteria: action( function getSearchCriteria() {
      return obx.fullSearchCriteria.trim();
    }),

    setCriteriaFromUrl: action( function setCriteriaFromUrl() {
      const criteriaQueryStringValue = getParam("criteria", window.location.href);
      if(criteriaQueryStringValue){
        obx.addCriteria(criteriaQueryStringValue);
      }
    }),


    /**
     * Get the requests and store in the requests array.
     */
    listTraffic: action( async function listTraffic(options={}) {

    //  SessionStore.startLoading('Loading…', 'GetSessions');
      const result = {successful: true, numberLoaded: 0};
      obx.hasError = false;
      obx.errorMessage = null;
      
      // if we are not paginating, now the general loading traffic state...
      if(!obx.from){
        obx.loadingTraffic = true;
      }
      
      options.criteria = encodeURIComponent(obx.getSearchCriteria());
      
      if(!options?.limit){
        options.limit = 50;
      }
      
      // if from is passed in, go ahead and set the from in OBX....
      if(options.from){
        obx.from = options.from;
      }else if(options.prepend || options.append){
        options.from = obx.from || null;
      }

      let bucketSid = null;

      if(SessionStore?.bucket?.sid){
        bucketSid = SessionStore.bucket.sid;
      }else{
        bucketSid = SessionStore.getBucketSidFromUrl();
      }

      const environmentSid = SessionStore.environment.sid;

      const _requests = await listRequests(SessionStore.accountSid, bucketSid, environmentSid, options);
      
      if(!options){
        options = {};
      }

      // if there are no results, just return...
      if(!_requests.data?.records || _requests.data?.records.length === 0){
        obx.loadingTraffic = false;
        obx.from = null;
        obx.requests = [];
        return result;
      }

      runInAction(() => {
        if(_requests.successful){
          
          if(options.prepend){
            obx.requests = [..._requests.data.records, ...obx.requests];
          }
          if(options.append){
            obx.requests = [...obx.requests, ..._requests.data.records];
          }

          if(!options.prepend && !options.append){
            obx.requests = _requests.data.records;
          }

          result.numberLoaded = _requests.data.records.length;

          obx.filterContextProperties = _requests.data?.properties;          
          
          obx.hasMore = _requests.data.hasMore;
          obx.from = _requests.data.from;

          if(result.numberLoaded === options.limit){
            //obx.hasMore = true;
          }else if(result.numberLoaded === 0){
            obx.hasNewRequests = false;
          }else{
            obx.hasNewRequests = false;
          }
          
          obx.hasNewRequests = false;
          
        }else{
          obx.hasError = true;
          obx.errorMessage = _requests.error.response.data.message;
        }        
        obx.loadingTraffic = false;
      })

      return result;
    
    }),

    /**
     * Get the full details of a single request.
     */
    getRequest: action( async function listTraffic(accountSid, bucketSid, requestSid) {
        const _requests = await getRequest(accountSid, bucketSid, requestSid);
        return _requests;
      
      }),

    checkForNewRequests: action( async function checkForNewRequests(accountSid, bucketSid) {
      //  SessionStore.startLoading('Loading…', 'GetSessions');
        
        const options = {
          criteria : this.getSearchCriteria(),
          lastRequestTime: obx.lastRequestTime
        }

        const _requests = await listRequests(accountSid,bucketSid, options );
      
          runInAction(() => {

            // if there were no requests, but now there are requests, lets go ahead and load those requests..
            if(obx.requests.length === 0 && _requests.data.records.length){
              obx.requests = _requests.data.records;
              // set the last request time...
              if(obx.requests.length && obx.requests[0].createdAt){
                obx.lastRequestTime  =  _requests.data.records[0].createdAt;
              }
              obx.hasNewRequests = false;
            }else{
              if(_requests.data.records.length){
                obx.hasNewRequests = true;
                obx.newRequestCount = _requests.data.records.length;
              }else{
                  obx.hasNewRequests = false;
                  obx.newRequestCount = 0;
              }  
            }

              
          });
    
        return true;
      
      })

  });


  // listen to any bucket change and react......
  reaction(() => obx.fullSearchCriteria, async (current, previous) => {
    // ensure there was an actual change...
    if(previous !== current){

      
    }

  });

  function init() {
    //console.log("Setup Traffic Store");
    obx.setCriteriaFromUrl();
  }

  init();

return obx;

}

export default TrafficStore();