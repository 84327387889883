import React from 'react';
import { observer } from 'mobx-react';
import GeneralSettings from '../Components/Settings/General';
import DomainSettings from '../Components/Settings/Domain';
import SecuritySettings from '../Components/Settings/Security';

const BucketSettings = observer((props) => {

    return (
        <div className="row">
            <div className="col-md-12">

                <div className="nav-align-top">
                    <ul className="nav nav-pills mb-3" role="tablist">
                        <li className="nav-item">
                            <button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-general" aria-controls="navs-pills-general" aria-selected="true"><i className="ti-xs ti ti-settings me-1"></i> General</button>
                        </li>
                        {/*
                        <li className="nav-item">
                            <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-domain" aria-controls="navs-pills-domain" aria-selected="false"><i className="ti-xs ti ti-world me-1"></i> Custom Domain</button>
                        </li>
                        <li className="nav-item">
                            <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-security" aria-controls="navs-pills-security" aria-selected="false"><i className="ti-xs ti ti-lock me-1"></i> Security & Firewall</button>
                        </li>
                        */}
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="navs-pills-general" role="tabpanel">
                            <GeneralSettings {...props} />
                        </div>
                        <div className="tab-pane fade" id="navs-pills-domain" role="tabpanel">
                            <DomainSettings {...props} />
                        </div>
                        <div className="tab-pane fade" id="navs-pills-security" role="tabpanel">
                            <SecuritySettings {...props} />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
});

export default BucketSettings;



