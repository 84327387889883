import { useEffect, useState, createContext, useContext, useRef } from "react";
import { observer } from 'mobx-react';
import { loadOutseta, unloadOutseta, bootstrapEmbed } from "../lib/outseta";

/*
 * Setting up a auth context to be used globally
 *
 */

const OutsetaContext = createContext({});

export function useOutseta() {
  return useContext(OutsetaContext);
}

const OutsetaProvider = observer(({ children, store }) => {
    const outsetaRef = useRef();
    const wrapperRef = useRef(null);
    const [status, setStatus] = useState("init");
    const [, setUser] = useState();
  
    useEffect(() => {
      async function init() {
        outsetaRef.current = await loadOutseta(wrapperRef);
        outsetaRef.current.setAccessToken(store.SessionStore.getOutsetaToken());
        const outsetaUser = await outsetaRef.current.getUser();
        // Update user state
        setUser(outsetaUser);
        setStatus("ready");
      }
      
      // now call the get User Function...
      init();

      return () => {
        unloadOutseta(wrapperRef);
      };

    // eslint-disable-next-line
    }, []);
/*

  const handleOutsetaUserEvents = (onEvent) => {
    // Subscribe to user related events
    // with onEvent function
    const outseta = outsetaRef.current;
    outseta.on("subscription.update", onEvent);
    outseta.on("profile.update", onEvent);
    outseta.on("account.update", onEvent);
  };
*/
  const logout = () => {
    // Unset access token
    outsetaRef.current.setAccessToken("");
    // and remove user state
    setUser(null);
  };

  const openLogin = async (options) => {
    outsetaRef.current.auth.open({
      widgetMode: "login|register",
      authenticationCallbackUrl: window.location.href,
      ...options
    });
  };

  const openSignup = async (options) => {
    outsetaRef.current.auth.open({
      widgetMode: "register",
      authenticationCallbackUrl: window.location.href,
      ...options
    });
  };

  const openProfile = async (options) => {
    outsetaRef.current.profile.open({ tab: "profile", ...options });
  };

  const embedOutseta = () => {
    bootstrapEmbed(wrapperRef);
  };

  return (
    <OutsetaContext.Provider
      value={{
        outsetaRef,
        wrapperRef,
        embedOutseta,
        isLoading: status !== "ready",
        logout,
        openLogin,
        openSignup,
        openProfile,
        status
      }}
    >
      {children}
    </OutsetaContext.Provider>
  );
});

export default OutsetaProvider;

