import React  from 'react';
import { observer } from 'mobx-react';
import WorkflowsList from '../Components/Workflows/List';
const BucketWorkFlows = observer((props) => {  
  


  return (
        <WorkflowsList {...props} />
  );
});

export default BucketWorkFlows;



