import { action, observable } from 'mobx';
import moment from 'moment';

// Components
//import SessionStore from './SessionStore';

function MetricsStore(){

  let obx = observable({
    view: 'list',
    dateRange: {
      start: null, end: null
    },
    period: '24hrs',
    periods: {},
    reset: action(function reset(){

    }),

    /**
     * sets up all the period data.
     */
    setupPeriods: action( function setupPeriods() {
   
      const _2hr = function(){ return obx.getRange(2, 'hours');}
      const _24hr = function(){ return obx.getRange(24, 'hours');}
      const _72hr = function(){ return obx.getRange(72, 'hours');}
      const _7day = function(){ return obx.getRange(7, 'days');}

      obx.periods['2hrs'] = {label: "Past 2hrs", resolutionFriendly:"1 min resolution", resolution:"1min", getRange:_2hr};
      obx.periods['24hrs'] = {label: "Past 24hrs", resolutionFriendly:"10 min resolution", resolution:"10min", getRange:_24hr};
      obx.periods['72hrs'] = {label: "Past 72 hrs", resolutionFriendly:"1 hr resolution", resolution:"1hr", getRange:_72hr};
      obx.periods['7days'] = {label: "Past 7 days", resolutionFriendly:"2 hr resolution", resolution:"2hr", getRange:_7day}; 

    }),

    getRange: action( function getRange(amount, units){
      const startTime = moment().subtract(amount, units);
      // Get the current time as the end time
      const endTime = moment();

      // Format the start and end times in ISO8601 format
      const start = startTime.toISOString();
      const end = endTime.toISOString();

      return {start, end};
    }),
    /**
     * gets all the periods options
     */
    getPeriod: action( function getPeriod() {
      return obx.periods[obx.period];
    }),
    setPeriod: action(function(period) {
      obx.period = period;
    }),
  });



  function init() {
    //console.log("Setup Metrics Store");
    obx.setupPeriods();
  }

  init();

return obx;

}

export default MetricsStore();