import React from 'react';
//import axios from 'axios';
//import { useState } from 'react';
import { APP_ROUTES } from '../utils/constants';
import {Helmet} from "react-helmet";

const SignUp = () => {

  /*
  const [email] = useState('');
  const [password] = useState('');
  const [firstname] = useState('');
  const [lastname] = useState('');
  const [setIsLoading] = useState(false);

  const signUp = async () => {
    try {
      setIsLoading(true);
      const response = await axios({
        method: 'POST',
        url: API_ROUTES.SIGN_UP,
        data: {
          email,
          password,
          firstname,
          lastname
        }
      });
      if (!response?.data?.token) {
        console.log('Something went wrong during signing up: ', response);
        return;
      }
      //navigate(APP_ROUTES.SIGN_IN);
    }
    catch (err) {
      console.log('Some error occured during signing up: ', err);
    }
    finally {
      setIsLoading(false);
    }
  };
  */

  return (
    <>
      <div id="signup-embed"></div>
      <Helmet>
          <script src="https://cdn.outseta.com/outseta.min.js" data-options="o_signup_options"></script>
      </Helmet>
      <p className="text-center">
        <span>Already have an account? <a href={APP_ROUTES.SIGN_IN}>Sign in instead</a></span>
      </p>
    </>
  );
}

export default SignUp;