import moment from 'moment-timezone';

const formatTime = (timestamp, timezone, format='MMM d, yyyy @ h:mm a') => {
  return moment(timestamp)
    .tz(timezone)
    .format(format);
};

const DisplayDateTime = ({ timestamp, timezone, format}) => {
  return (formatTime(timestamp, timezone, format));
};

export default DisplayDateTime;