import React, { useState } from 'react';
import {Modal } from 'react-bootstrap';
import { deleteWorkflow } from '../../lib/apiClient';
import { showToast } from '../../utils/helpers';

const DeleteWorkflowDialog = (props) => {
    const [deleting, setDeleting] = useState(false);

    const handleDelete = async()  => {
        // ensure the error message is removed in case it is showing...
        setDeleting(true);
        const options = {
            accountSid : props.store.SessionStore.accountSid,
            bucketSid : props.store.SessionStore.bucket.sid,
            workflowSid : props.workflow.sid
        };
        const result = await deleteWorkflow(options);

        if(result.successful){
            // call the post create function
            props.onPostDelete(props.workflow);
            showToast("Workflow Deleted!");
        }else{
            // TODO: handle error...
        }
    } 

    const handleClose = () => {
        props.close();
    }

  return (
    <>
        <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Delete Workflow</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>This will permanently delete the workflow across all environments, all its data and any background runs.</p>
                <p>Type DELETE and press confirm to continue.</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick={()=>props.close()} className="btn btn-label-secondary" data-bs-dismiss="modal">Cancel</button>
                {!deleting ? (
                    <button type="button" onClick={()=>handleDelete()} className="btn btn-danger">Delete</button>
                ) : (
                    <button className="btn btn-danger" type="button" disabled>
                    <span className="spinner-border me-1" role="status" aria-hidden="true"></span>
                    Deleting...
                </button>
                )}  
            </Modal.Footer>
        </Modal>        
    </>
  );
}

export default DeleteWorkflowDialog;