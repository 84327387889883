import React, { useState, useEffect, useRef } from 'react';
const TimeElapsed = (props) => {    
    const [elapsed, setElapsed] = useState();
    const [seconds, setSeconds] = useState();
    const [createdAt] = useState(props.request.createdAt);
    const intervalRef = useRef(null);

    const timerRefreshInterval = 10000;
    const stopTimerAfter = 86400;
    
    function convertSecondsToReadableString(secondsToConvert) {
        secondsToConvert = secondsToConvert || 0;
        secondsToConvert = Number(secondsToConvert);
        secondsToConvert = Math.abs(secondsToConvert);

        const d = Math.floor(secondsToConvert / (3600 * 24));
        const h = Math.floor(secondsToConvert % (3600 * 24) / 3600);
        const m = Math.floor(secondsToConvert % 3600 / 60);
        const s = Math.floor(secondsToConvert % 60);
        const parts = [];

        if (d > 0) {
            parts.push(d + 'd' /*+ (d > 1 ? 's' : '')*/);
            return parts.join(', ');
        }

        if (h > 0) {
            parts.push(h + 'h');
            return parts.join(', ');
        }

        if (m > 0) {
            parts.push(m + 'm');
            return parts.join(', ');
        }

        if (s > 0) {
            parts.push(s + 's');
        }

        return parts.join(', ');
    }

    useEffect(() => {

        const requestTime = new Date(createdAt);
        const currentTime = new Date().getTime();
        const _seconds = Math.abs(currentTime - requestTime) / 1000;
        
        setSeconds(_seconds);
        
        setElapsed(convertSecondsToReadableString(_seconds));

        intervalRef.current = setInterval(() => {
            const requestTime = new Date(createdAt);
            const currentTime = new Date().getTime();
            const _seconds = Math.abs(currentTime - requestTime) / 1000;

            setSeconds(_seconds);            
            setElapsed(convertSecondsToReadableString(_seconds));
            
        }, timerRefreshInterval);

        return () => clearInterval(intervalRef.current);

      }, [createdAt]);

      // clear the interval now...
      useEffect(() => {
        if (seconds >= stopTimerAfter) {
          clearInterval(intervalRef.current);
        }
      }, [seconds]);

  return (
    <>{elapsed}</>
  );
}

export default TimeElapsed;