import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, Outlet } from "react-router-dom";

import LeftNav from './LeftNav';
import LeftAccountNav from './LeftAccountNav';
import Header from './Header';
import Footer from './Footer';
import UrlHelper from '../../Components/UrlHelper';
import TourOptions from '../../Components/TourOptions';

import { APP_ROUTES } from '../../utils/constants';

const Ready = observer((props) => {
  const navigate = useNavigate();

  useEffect(() => {
    
    async function checkAuthentication() {
        // the user just signed in...get their info..
        const loginResult = await props.store.SessionStore.isAuthenticated();
    
        if(loginResult && loginResult.authenticated){
          if(loginResult.redirectTo){
            navigate(loginResult.redirectTo);
          }          
        }else{
          navigate(APP_ROUTES.SIGN_IN);
        }
    }
    
    // now call the get User Function...
    checkAuthentication();

  // eslint-disable-next-line
  }, [navigate]);


  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    
    if(props.store.SessionStore?.user?.sid){
      
    
      // You can either set the script's content directly
      // Note: Be mindful of security implications and ensure data is sanitized
      script.textContent = `
        window.usetifulTags = { userId : "${props.store.SessionStore.user.sid}" };
      `;

      // Or set its src to load from an external URL
      // script.src = 'URL_TO_YOUR_SCRIPT';

      document.body.appendChild(script);

    }
    // Optional: Remove the script when the component unmounts
    return () => {
      //document.body.removeChild(script);
    };
  }, [props.store.SessionStore.user]); // Dependencies array ensures effect runs if these variables change

    return (
        <>
        {/* Layout wrapper */}
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            {props.store.SessionStore.getContext() === "bucket" ? (<LeftNav {...props} />) : (<LeftAccountNav {...props} />)}

            {/* Layout container */}
            <div className="layout-page">
              
              {/* Content wrapper */}
              <div className="content-wrapper">
                
                {/* Content */}
                <div className="container-fluid flex-grow-1 container-p-y">
               
                  <Header {...props} />    
                  
                  <Outlet />
                  {/*props.children*/}
                </div>

                {/* / Content */}

                {/* Footer */}
                <Footer {...props} />
                {/* / Footer */}

                <div className="content-backdrop fade"></div>
              </div>
              {/* Content wrapper */}
            </div>
            {/* / Layout page */}
          </div>

          {/* Overlay */}
          <div className="layout-overlay layout-menu-toggle"></div>

          {/* Drag Target Area To SlideIn Menu On Small Screens */}
          <div className="drag-target"></div>
        </div>
        {/* / Layout wrapper */}

        <UrlHelper {...props} />
         {/*<NewBucket {...props} />*/}
        <TourOptions {...props} />        
        </>
      
      
    );
});

export default Ready;