
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Chart from "react-apexcharts";
import moment from "moment";

import ChartLoader from './ChartLoader';
import ChartNoData from './ChartNoData';

import { getThroughputMetrics } from '../../lib/apiClient'

const RequestsPerSecond = observer((props) => {

  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);

  const { bucketSid } = useParams();
  
  useEffect(() => {
    
    loadMetrics();
  },
  // eslint-disable-next-line
  [bucketSid, props.store.SessionStore.environment.sid, props.store.MetricsStore.period, props.reloadTime]);

  async function loadMetrics() {
        
    setLoading(true);

    const period = props.store.MetricsStore.getPeriod();
    const range = period.getRange();
    
    const apiResults = await getThroughputMetrics(props.store.SessionStore.getAccountSid(), props.store.SessionStore.environment.sid, {
      startTime: range.start,
      endTime: range.end,
      resolution: period.resolution,
      bucketSid
    });

    if(apiResults.successful){

      setSummary(apiResults.data.summary);
      
      const categories = [];
      const stats = {
        200: [],
        300: [],
        400: [],
        500: []
      }

      for (let i = 0; i < apiResults.data.periods.length; i++) {
        // only include if it is on the hour...
        categories.push(moment(apiResults.data.periods[i].startTime).format('h:mma'));  
        
        stats["200"].push(apiResults.data.periods[i].httpStatus["200"].count);
        stats["300"].push(apiResults.data.periods[i].httpStatus["300"].count);
        stats["400"].push(apiResults.data.periods[i].httpStatus["400"].count);
        stats["500"].push(apiResults.data.periods[i].httpStatus["500"].count);

      }
      
      let _chartOptions = {
        options: {
          chart: {
            id: "requests-per-second",
            type: "area",
            group:'dashboard',
            stacked: false,
            zoom: {
              enabled: false
            },
            toolbar : {
                show: false,
                tools: {
                    download:false,
                    reset: false
                },
                
            },
            animations: {
              enabled: false
            },
            events: {
              mouseMove: function(event, chartContext, config) {
                //updateStatsDetails(config.dataPointIndex);
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1
          },
          markers: {
            onClick: function(e) {
            }
          },
          plotOptions: {
            bar: {
              borderRadius: 10
            }
          },
          xaxis: {
            categories: categories,
            tickAmount: 10
          },
          yaxis: {
            floating: false
          },
          legend:{
            position:'right'
          }
        },
        series: [
          {
            name: "200",
            data: stats["200"]
          },
          {
            name: "300",
            data: stats["300"]
          },
          {
            name: "400",
            data: stats["400"]
          },
          {
            name: "500",
            data: stats["500"]
          }
        ]
      };

      setChartOptions(_chartOptions);

    }
    setLoading(false);
  }
    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between">
                <div>
                  <h5 className="card-title mb-0">Throughput</h5>
                  <small className="text-muted">Requests per second</small>
                </div>   
                {/**
                <div>
                  <small>
                    <span className="text-secondary"><i className="fa-solid fa-circle"></i> Monitoring Available </span>
                    <a href="" className="text-primary">Add Alert <i className="fa-solid fa-circle-arrow-right"></i></a>
                  </small>
                </div>
                 
                <div className="row gy-3">
                  <div className="col-md-3 col-6">
                    <div className="d-flex align-items-center">                      
                      <div className="card-info text-center">
                      <small>2xx</small>
                        <h6 className="mb-0">&lt;1rps</h6>                         
                        <h6 className="mb-0">{statsDetails[200]?.count} reqs</h6>                       
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="d-flex align-items-center">                      
                      <div className="card-info text-center">
                        <small>3xx</small>
                        <h6 className="mb-0">50ms</h6> 
                        <h6 className="mb-0">199ms</h6>                       
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="d-flex align-items-center">
                      <div className="card-info text-center">
                        <small>4xx</small>
                        <h6 className="mb-0">199ms</h6>                        
                        <h6 className="mb-0">199ms</h6>                        
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="d-flex align-items-center">
                      <div className="card-info text-center">
                        <small>5xx</small>
                        <h6 className="mb-0">511ms</h6>
                        <h6 className="mb-0">199ms</h6>                        
                      </div>
                    </div>
                  </div>
                </div>
                */}
            </div>
            <div className="card-body metrics-dashboard-chart-height">
              {loading ? (
                <ChartLoader />
              ) : (
                <>
                  {summary.total.count > 0 ? (
                  <Chart
                  options={chartOptions.options}
                  series={chartOptions.series}
                  height="300"
                  type="area" />)
                      :(<ChartNoData />)}
                </>
                
              )}
                
            </div>
        </div>
      );
});

export default RequestsPerSecond;