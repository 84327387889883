import React, { useState }  from 'react';
import TimeElapsed from './TimeElapsed';
import { useForm } from "react-hook-form";
import { formatNumber } from '../../utils/helpers';
import { Offcanvas } from 'react-bootstrap';
import { useFeatureFlagEnabled } from 'posthog-js/react'

const RequestSummary = (props) => {
    const [showCode, setShowCode] = useState(false);
    const workflowsEnabled = useFeatureFlagEnabled('show-stream-view-workflows');
    const excludeRequestsEnabled = useFeatureFlagEnabled('exclude-requests');
    const getCodeEnabled = useFeatureFlagEnabled('request-code');

    const [showSimilarOptions, setShowSimilarOptions] = useState(false);    
    const [similarRequest, setSimilarRequest] = useState(null);    

    const handleCloseCode = () => setShowCode(false);
    const handleShowCode = () => {
        setShowCode(true);          
    }
    
    const { register, handleSubmit } = useForm({
        defaultValues: async () => {
            return {
                status_code: props.store.TrafficStore.isUserDefinedCriteria("status_code"),
                method: props.store.TrafficStore.isUserDefinedCriteria("method"),
                url_path: props.store.TrafficStore.isUserDefinedCriteria("url_path"),
                //query: props.store.TrafficStore.isUserDefinedCriteria("query"),
                host: props.store.TrafficStore.isUserDefinedCriteria("host")
            }
        }
    });

    const closeFindSimilar = () => setShowSimilarOptions(false);
    const openFindSimilar = (request) => {
        setSimilarRequest(request);
        setShowSimilarOptions(true);
        //console.log(similarRequest);
    }

    const onSubmit = async (data, e) => {
        let filterParams = [];

        Object.keys(data).forEach(key => {
            if(data[key]){
                filterParams.push(`${key} == ${data[key]}`);
            }
        });

        const filterString = filterParams.join(" AND ");
        const newUrl = `${window.location.pathname}?criteria=${filterString}`;
        window.history.pushState(null, '', newUrl);
        
        props.store.TrafficStore.loadTraffic = true;
        props.store.TrafficStore.lastRequestTime = null;
        props.store.TrafficStore.addCriteria(filterString);   
        await props.store.TrafficStore.listTraffic();

    };

    let statusClass = "badge";
    if(props.request?.response?.statusCode){    
        if(props.request.response.statusCode === 200){
            statusClass = `${statusClass} bg-label-success`
        }else if((props.request.response.statusCode >= 500)){
            statusClass = `${statusClass} bg-label-danger`
        }else if((props.request.response.statusCode >= 400)){
            statusClass = `${statusClass} bg-label-warning`
        }else{
            statusClass = `${statusClass} bg-label-secondary`
        }
    } 

  return (
    <>      
        
        <div className="row small request-summary pb-1">
            <div className="col-8 d-flex align-items-center">    
                {props.toggle}
                {props.request.request.host}
            </div>
            <div className="col-1">
                <div className="text-center">
                    {props.request.firewall.blocked === false ? (
                        <span className={statusClass}>{props.request?.response?.statusCode}</span>
                    ):(
                        <i className="fa-solid fa-shield text-danger"></i>
                    )}            
                </div>       
            </div>
            <div className="col-2 d-flex align-items-center">
                {!props.request.capture ? (
                    <>
                    <i className="tf-icons ti ti-clock fs-small small"></i>&nbsp;{formatNumber(props.request?.timings?.duration)} ms
                    </>
                ) : ('')}    
            </div>
            <div className="col-1 text-end">
                {/*
                <i className="tf-icons ti ti-folder fs-small text-primary fw-bold"></i>
                <i className="tf-icons ti ti-layout-columns fs-small text-primary fw-bold"></i>
                <i className="tf-icons ti ti-pencil fs-small text-primary fw-bold"></i>
                <i className="tf-icons ti ti-refresh fs-small text-primary fw-bold"></i>
                <i className="tf-icons ti ti-link fs-small text-primary fw-bold"></i>
                <i className="tf-icons ti ti-trash fs-small text-primary fw-bold"></i>
                
                */}
                <div className="btn-group">
                    <button type="button" className="btn btn-link" data-bs-toggle="dropdown">
                        <i className="ti ti-dots-vertical fs-small text-secondary"></i>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className="dropdown-item btn-link" onClick={()=>openFindSimilar(props.request)} ><i className="ti ti-list-search text-secondary"></i> Find Similar</button></li>
                        {getCodeEnabled && <><li><button className="dropdown-item btn-link" onClick={()=>handleShowCode(props.request)} ><i className="ti ti-code text-secondary"></i> Get Code</button></li></>}
                        {excludeRequestsEnabled && <><li><button className="dropdown-item btn-link" onClick={()=>openFindSimilar(props.request)} ><i className="ti ti-filter text-secondary"></i> Exclude</button></li></>}
                        {workflowsEnabled && <><li><button className="dropdown-item btn-link" onClick={()=>openFindSimilar(props.request)} ><i className="ti ti-switch-3 text-secondary"></i> Add Workflow</button></li></>}
                        {/*
                        https://github.com/Kong/react-apiembed
                        {excludeRequestsEnabled && <><li><button className="dropdown-item btn-link" href="javascript:void(0);" onClick={()=>openFindSimilar(props.request)} >Get Code</button></li>
                        */}
                    </ul>
                </div>
            </div>
        </div>
        <div className="row mt-0 small">
            <div className="col-8">
                <span className="mx-4 ellipsis">
                    &nbsp;<strong>{props.request.request.method}</strong> 
                    {props.request.request.path}
                    {props.request?.request?.url && props.request.request.url.split('?').length === 2 ? (
                        <span className="text-muted fw-light">?{props.request.request.url.split('?')[1]}</span>
                    ):('')} 
                </span>
            </div>
            <div className="col-1"></div>
            <div className="col-2">
                {props.request?.request?.size &&  
                    <><i className="tf-icons ti ti-cloud-upload fs-small small me-1"></i> {formatNumber(props.request.request.size)}</>
                }  
                 {props.request?.response?.size &&  
                    <><i className="tf-icons ti ti-cloud-download fs-small small me-1 ms-1"></i>{formatNumber(props.request.response.size)}</>
                 }
            </div>
            <div className="col-1 text-end fw-lighter justify-sm-content-end"><div><TimeElapsed request={props.request} /></div></div>
        </div>

        <Offcanvas show={showSimilarOptions} onHide={closeFindSimilar} placement="end" id="similar-request-filter">
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filter Similar Requests </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <div className="row mx-2">
                <small className="text-light fw-medium">Checkboxes</small>
                <div className="form-check mt-3">
                    <input {...register("status_code")} className="form-check-input" type="checkbox" value={similarRequest?.response?.statusCode} id="status_code" />
                    <label className="form-check-label" for="status_code">
                        <strong>Status Code</strong><br/>
                        {similarRequest?.response?.statusCode}
                    </label>
                </div>
                <div className="form-check mt-3">
                    <input {...register("method")} className="form-check-input" type="checkbox" value={similarRequest?.request?.method} id="method" />
                    <label className="form-check-label" for="method">
                        <strong>Method</strong><br/>
                        {similarRequest?.request?.method}
                    </label>
                </div>
                <div className="form-check mt-3">
                    <input {...register("url_path")} className="form-check-input" type="checkbox" value={similarRequest?.request?.path} id="url_path" />
                    <label className="form-check-label" for="path">
                        <strong>Path</strong><br/>
                        {similarRequest?.request?.path}
                    </label>
                </div>
                {/*
                {props.request?.request?.url && props.request.request.url.split('?').length === 2 ? (
                    <div className="form-check mt-3">
                    <input {...register("query")} className="form-check-input" type="checkbox" value={similarRequest?.request.url.split('?')[1]} id="query" />
                    <label className="form-check-label" for="query">
                        <strong>Query String</strong><br/>
                        ?{similarRequest?.request.url.split('?')[1]}
                    </label>
                </div>
                ):('')} 
                 */}
                <div className="form-check mt-3">
                    <input {...register("host")} className="form-check-input" type="checkbox" value={similarRequest?.request?.host} id="host" />
                    <label className="form-check-label" for="host">
                        <strong>Hostname</strong><br/>
                        {similarRequest?.request?.host}
                    </label>
                </div>                
            </div>
            <div className="position-absolute bottom-0 end-0 p-2 m-4">
                <button className="btn btn-primary" title="Filter">
                    Filter Requests
                </button>        
            </div>
            </form>
            </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas show={showCode} onHide={handleCloseCode} placement="end" className="offcanvas-fullscreen">
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>Offcanvas</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            Some text as placeholder. In real life you can have the elements you
            have chosen. Like, text, images, lists, etc.
            </Offcanvas.Body>
        </Offcanvas>



    </>
    
  );
}

export default RequestSummary;