import React from 'react';
import { observer } from 'mobx-react';

const Hapi = observer((props) => {

  return (
    <>
      <h3 className="text-primary">Awe Shucks, there is no step 2...</h3>
      <p className="card-text">Unfortunatly we do not have any other SDKs.</p>
    </>
  );
});

export default Hapi;
