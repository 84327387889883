import React from 'react';
import { observer } from 'mobx-react';
import { Link, useLocation } from "react-router-dom";

import { APP_ROUTES } from '../../utils/constants';
import { replacePathPlaceholders } from '../../utils/helpers';

const LeftNav = observer((props) => {

  const location = useLocation();

  const isActive = function(route){
    const checkRoute = replacePathPlaceholders(route, {bucketSid: props.store.SessionStore.getActiveBucketSid()});
    const currentRoute = location.pathname;
    if(currentRoute === checkRoute){
      return "menu-item active";
    }
    return "menu-item";
  }

  return (
    <>
    {/* Menu */}
    
    <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
      <div className="app-brand demo">
        <a href={replacePathPlaceholders(APP_ROUTES.DASHBOARD, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="app-brand-link">
          <img src="/assets/images/apitraffic-logo.svg" width="200" alt="ApiTraffic Logo"/>
        </a>
      </div>

    <div className="menu-inner-shadow"></div>
    
    
    <ul className="menu-inner py-1">
      
          <li className="menu-item mt-4" id="left-account-nav-return-back">
            <Link to={replacePathPlaceholders(APP_ROUTES.DASHBOARD, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link menu-link-back">
              
                <i className="ti ti-arrow-left"></i> <div data-i18n="Return to Bucket">Return to Bucket</div>
              
            </Link>
          </li>

      {/* Apps & Pages */}
          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Settings</span>
          </li>  
            
          <li className={isActive(APP_ROUTES.PROFILE)} id="left-account-nav-profile">
            <Link to={replacePathPlaceholders(APP_ROUTES.PROFILE, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
              <i className="ti ti-user-check me-2 ti-sm"></i>
              <div data-i18n="Profile">Profile</div>
            </Link>
          </li>

          <li className={isActive(APP_ROUTES.ACCOUNT)} id="left-account-nav-account">
            <Link to={replacePathPlaceholders(APP_ROUTES.ACCOUNT, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
              <i className="ti ti-building me-2 ti-sm"></i>
              <div data-i18n="Account">Account</div>
            </Link>
          </li>
          <li className={isActive(APP_ROUTES.ACCOUNT_TEAM)} id="left-account-nav-team">
            <Link to={replacePathPlaceholders(APP_ROUTES.ACCOUNT_TEAM, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
              <i className="ti ti-users me-2 ti-sm"></i>
              <div data-i18n="Team Members">Team Members</div>
            </Link>
          </li>
          <li className={isActive(APP_ROUTES.ACCOUNT_PLAN)} id="left-account-nav-plan">
            <Link to={replacePathPlaceholders(APP_ROUTES.ACCOUNT_PLAN, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
              <i className="flex-shrink-0 ti ti-chart-candle me-2 ti-sm"></i>
              <div data-i18n="Plan">Plan</div>
            </Link>
          </li>

          <li className={isActive(APP_ROUTES.ACCOUNT_BILLING)} id="left-account-nav-billing">
            <Link to={replacePathPlaceholders(APP_ROUTES.ACCOUNT_BILLING, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="menu-link">
              <i className="flex-shrink-0 ti ti-credit-card me-2 ti-sm"></i>
              <div data-i18n="Billing">Billing</div>
            </Link>
          </li>
      
      
      <li className="menu-header small text-uppercase">
        <span className="menu-header-text">Integration</span>
      </li>

      
      <li className={isActive(APP_ROUTES.API_TOKENS)} id="left-account-nav-api-keys">
          <Link to={APP_ROUTES.API_TOKENS} className="menu-link">
              <i className="ti ti-key me-2 ti-sm"></i>
              <div data-i18n="API Keys">API Tokens</div>
          </Link>
      </li>

      <li className={isActive(APP_ROUTES.ENVIRONMENTS)} id="left-account-nav-api-keys">
          <Link to={APP_ROUTES.ENVIRONMENTS} className="menu-link">
              <i className="ti ti-hierarchy me-2 ti-sm"></i>
              <div data-i18n="Environments">Environments</div>
          </Link>
      </li>
     
     </ul>
      
      <div className="mb-3 mx-2 text-secondary text-center">
        <Link to={APP_ROUTES.SIGN_OUT} className="menu-link">
            <i className="ti ti-logout me-2 ti-sm"></i>
            <div data-i18n="Logout">Logout</div>
        </Link>
      </div>
      

  </aside>
  {/* / Menu */}
  </>
  );
});

export default LeftNav;