import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";
import BucketSelector from './BucketSelector';
import EnvironmentSelector from './EnvironmentSelector';
import { getURLSegment, toTitleCase } from '../../utils/helpers';

  const Breadcrumbs = observer((props) => {
    const navigate = useNavigate();
    const [breadcrumb, setBreadbrumb] = useState("");
    
    useEffect(() => {
      const context = props.store.SessionStore.getContext();
      let segment = getURLSegment(3);
      if(context === 'bucket'){
        if(segment && segment.length && props.store.SessionStore.streamView){
          setBreadbrumb(<><EnvironmentSelector {...props} /> / <BucketSelector {...props} /> <span className="text-muted fw-light"> /  {toTitleCase(segment)} / {props.store.SessionStore.streamView.name}</span></>);
        }else if(segment && segment.length){
          setBreadbrumb(<><EnvironmentSelector {...props} /> / <BucketSelector {...props} /> <span className="text-muted fw-light"> /  {toTitleCase(segment)}</span></>);
        }else{
          setBreadbrumb(<><EnvironmentSelector {...props} /> / <BucketSelector {...props} /> <span className="text-muted fw-light"> /  Dashboard</span></>);
        }
      }else if(context === 'account'){
        let segment = getURLSegment(2);

        setBreadbrumb(<> Account <span className="text-muted fw-light"> /  {toTitleCase(segment)}</span></>);
      }
    // eslint-disable-next-line
    }, [navigate, props.store.SessionStore.bucket, props.store.SessionStore.streamView?.name]);

  
  return breadcrumb;

});

export default Breadcrumbs;