import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react'
import { observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from '../utils/constants';
import { Link } from "react-router-dom";

const SignOut = observer((props) => {
  const navigate = useNavigate();
  const posthog = usePostHog();

  useEffect(() => {
    
    async function signUserOut() {
        await props.store.SessionStore.logout();
        // reset the posthog info...
        posthog.reset();

        // give the user about a second to see the actual page...
        setTimeout(() => {
          navigate(APP_ROUTES.SIGN_IN);
        }, "1000");        
    }

    // now call the get User Function...
    signUserOut();

  }, [navigate, posthog, props.store.SessionStore]);


   
    return (
    <>
      <h2 className="mb-1 mx-2">Logging Out</h2>
      <p className="mb-4 mx-2">
        Give us a quick moment while we ensure your account is signed out and secure.
      </p>
      <Link to={APP_ROUTES.SIGN_IN} className="btn btn-primary mb-4 waves-effect waves-light">Sign In Again</Link>
    </>
  );

});

export default SignOut;
