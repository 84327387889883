
import { observer } from 'mobx-react';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Chart from "react-apexcharts";
import moment from "moment";
import ChartLoader from './ChartLoader';
import ChartNoData from './ChartNoData';

import { getResponseTime } from '../../lib/apiClient'

const ResponseTime = observer((props) => {

  const chartRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);

  const { bucketSid } = useParams();
  
  useEffect(() => {
    loadMetrics();
  },
  // eslint-disable-next-line
  [bucketSid, props.store.SessionStore.environment.sid, props.store.MetricsStore.period, props.reloadTime]);

  async function loadMetrics() {
        
    setLoading(true);

    const period = props.store.MetricsStore.getPeriod();
    const range = period.getRange();

    const apiResults = await getResponseTime(props.store.SessionStore.getAccountSid(), props.store.SessionStore.environment.sid, {
      startTime: range.start,
      endTime: range.end,
      resolution: period.resolution,
      bucketSid
    });

    if(apiResults.successful){

      setSummary(apiResults.data.summary);
      
      const categories = [];
      const percentiles = {
        max: [],
        p99: [],
        p95: [],
        p50: []
      }
      
      for (let i = 0; i < apiResults.data.periods.length; i++) {
        // only include if it is on the hour...
        categories.push(moment(apiResults.data.periods[i].startTime).format('h:mma'));  
        
        percentiles["max"].push(apiResults.data.periods[i].maximum);
        percentiles["p99"].push(apiResults.data.periods[i].percentiles["99"].value);
        percentiles["p95"].push(apiResults.data.periods[i].percentiles["95"].value);
        percentiles["p50"].push(apiResults.data.periods[i].percentiles["50"].value);

      }
      
      let _chartOptions = {
        options: {
          chart: {
            id: "response-time",
            type: "area",
            group:'dashboard',
            stacked: false,
            stroke: {
              curve: 'stepline'
            },
            zoom: {
              enabled: false
            },
            toolbar : {
                show: false,
                tools: {
                    download:false,
                    selection: false,
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: true
                }
            },
            animations: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1
          },
          plotOptions: {
            bar: {
              borderRadius: 10
            }
          },
          xaxis: {
            categories: categories,
            tickAmount: 10

          },
          legend:{
            position:'right'
          }
        },
        series: [
          {
            name: "Max",
            data: percentiles.max
          },
          {
            name: "p99",
            data: percentiles.p99
          },
          {
            name: "p95",
            data: percentiles.p95
          },
          {
            name: "p50",
            data: percentiles.p50
          }
        ]
      };

      setChartOptions(_chartOptions);

    }
    setLoading(false);
  }
    return (
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div>
                <h5 className="card-title mb-0">Response Time</h5>
                <small className="text-muted">Measured in milliseconds</small>
              </div>    
              {/**
              <div>
                <small>
                  <span className="text-success"><i className="fa-solid fa-circle"></i> Monitoring Enabled </span>
                  <a href="" className="text-primary">Edit Alert <i className="fa-solid fa-circle-arrow-right"></i></a>
                </small>
              </div>
               
              <div className="row gy-3">
                <div className="col-md-4 col-6">
                  <div className="d-flex align-items-center">                      
                    <div className="card-info text-center">
                      <small>Median</small>
                      <h6 className="mb-0">50ms</h6>                        
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="d-flex align-items-center">
                    <div className="card-info text-center">
                      <small>Minimum</small>
                      <h6 className="mb-0">199ms</h6>                        
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="d-flex align-items-center">
                    <div className="card-info text-center">
                      <small>Maximum</small>
                      <h6 className="mb-0">511ms</h6>
                      
                    </div>
                  </div>
                </div>
              </div>
              */}
            </div>
            <div className="card-body metrics-dashboard-chart-height">
            {loading ? (
                <ChartLoader />
              ) : (
                <>
                  {summary.requests > 0 ? (
                  <Chart
                      ref={chartRef}
                      options={chartOptions.options}
                      series={chartOptions.series}
                      height="300"
                      type="area" />)
                      :(<ChartNoData />)}
                </>
              )}
                

            </div>
        </div>
      );
});

export default ResponseTime;