import React from 'react';

const LoadingRequests = (props) => {
  return (
    <div className="text-center">
      <h5>Loading Requests</h5>
      <p>
        <span className="spinner-grow text-dark" role="status">
          <span className="visually-hidden">Loading...</span>
        </span>
      </p>
  
    </div>
  );
}

export default LoadingRequests;