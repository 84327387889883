import React from 'react';
import { observer } from 'mobx-react';

const AuthLayout = observer((props) => {

  return (
    <>
      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner py-4">
            <div className="card">
              <div className="card-body">
                {/* Content */}
                {props.children}
                {/* / Content */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default AuthLayout;