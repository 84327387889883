import React, { useEffect, useState }  from 'react';
import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';
import { Nav, Navbar, Card } from 'react-bootstrap';

import TrafficList from '../Components/TrafficList';
import WorkflowsList from '../Components/Workflows/List';
import GettingStarted from './GettingStarted';
import StreamViewDetails from '../Components/StreamViews/Details';

const Traffic = observer((props) => {
  const [tab, setTab] = useState('traffic');
  const location = useLocation();
  const { streamViewSid } = useParams();
  
  useEffect(() => {   
    setTab("traffic");
    
    if(streamViewSid){
      // find the stream view and set it to the active one...    
      props.store.SessionStore.streamViews.some(view => {
          if (view.sid === streamViewSid) {
            props.store.SessionStore.streamView = view;
              return true; // Stop the loop
          }
          props.store.SessionStore.streamView = null;
          
          return false;
      });
      
    }

    // Cleanup function
    return () => {
      // when removing, set the stream view back to null
      props.store.SessionStore.streamView = null;
    };

  // eslint-disable-next-line
  }, [location]);
  
    // if the bucket is not yet connected, we need to show the getting started page to get them going with their SDK setup...
    if(!props.store.SessionStore.bucket.appIsConnected){
      return (<GettingStarted {...props} />);
    }

    const renderContent = () => {
      if(tab === 'traffic'){
        return (<TrafficList {...props} view={props.view}  />);
      }else if(tab === 'streamView'){
        return (<div className="p-3"><StreamViewDetails {...props} view={props.view}  /></div>);
      }else if(tab === 'workflows'){
        return (<div className="p-3"><WorkflowsList {...props} view={props.view}  /></div>);
      }
    }

    const refresh = async () => {
      await props.store.TrafficStore.listTraffic({prepend: false, append: false, from: null});
    }

    return (
      <>
      <Card>
      <Card.Header className="p-0">
        <Navbar className="d-flex justify-content-between align-items-center my-0 p-2">
          <Nav variant="pills" activeKey={tab} defaultActiveKey={tab}>
            <Nav.Item className="pe-3">
              <Nav.Link eventKey="traffic" onClick={()=>setTab("traffic")}>Request Log</Nav.Link>
            </Nav.Item>

            {props.store.SessionStore.streamView ? (
                <>
                <Nav.Item className="px-3">
                  <Nav.Link eventKey="streamView" onClick={()=>setTab("streamView")} >Stream View</Nav.Link>
                </Nav.Item>
                {props.store.SessionStore.subscription.getFeature("workflowsEnabled") && <>
                  <Nav.Item className="px-3">
                    <Nav.Link eventKey="workflows" onClick={()=>setTab("workflows")}>Workflows</Nav.Link>
                  </Nav.Item>
                </>}
                </>
            ) : (
                <>
                    {props.store.TrafficStore?.userDefinedCriteria?.length ? (
                      <Nav.Item>
                        <Nav.Link eventKey="streamView" onClick={()=>setTab("streamView")} >Save As View</Nav.Link>
                      </Nav.Item>
                    ):(<></>)}
                </>
            ) }       
          </Nav>
          {tab === "traffic" &&
          <Nav>
            <Nav.Item>
              <Nav.Link href="#extra-link" className="ml-auto" onClick={()=> refresh()}>
                <i className="tf-icons ti ti-refresh"></i> Refresh
              </Nav.Link>
            </Nav.Item>
          </Nav>}
        </Navbar>
      </Card.Header>
      <Card.Body className="p-0 border-top">
        {renderContent()}

      </Card.Body>
      </Card>
      </>
    );


});

export default Traffic;



