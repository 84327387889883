const NoCaptures = (props) => {
  return (
    <div className="row">
      
        <div className="col-lg-12 col-xl-6 text-center">
          {/* https://storyset.com/illustration/ok/amico#54B274FF&hide=speech-bubble&hide=complete */}
          <img src="/assets/images/ok-checkmark.svg" alt="Person saying ok with their hands and a large checkmark illustration" height="400"/>
          
          
        </div>
        
        <div className="col-lg-12 col-xl-6 d-flex align-items-center text-center">
          <div className="text-center flex-column">
            <h3>Everything's A-OK</h3>
            <p>No errors have been captured.</p>
            <p>Any status code 400 or greater will be displayed here.</p>
          </div>  
        </div>  

      </div>
  );
}

export default NoCaptures;