import React, { useRef, useState, useEffect } from 'react';
import { workflowSignin } from '../../lib/apiClient';
import { ACTIVEPIECES_URL } from '../../utils/constants';

const Activepieces = (props) => {

  const iframeRef = useRef(null);
  const [baseUrl, setBaseUrl] = useState(ACTIVEPIECES_URL);
  const [location, setLocation] = useState();
  const [signinData, setSigninData] = useState();
  const [loading, setLoading] = useState(true);
  const [iFrameStyle, setIFrameStyle] = useState({
    width: '100%',
    height: '95%',
    border: 'none'
  });


  useEffect(() => {
    console.log('Mount');
    // Define the message handler function
    const handleMessage = (event) => {
      // Check the origin of the event for security reasons
      if (!event.origin.includes('apitraffic.io')){
        return;
      }

      
      
      if(event.data.topic){
        switch (event.data.topic) {
          
          case "finished-loading":
            setLoading(false);
            setIFrameStyle({
              width: '100%',
              height: '95%',
              border: 'none'
            });
            break;
            default:
              console.log(loading);
         
        }   

      }

      // Handle the received message
      console.log('Message received:', event.data);
    };

    // Add the message event listener
    window.addEventListener('message', handleMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  // eslint-disable-next-line
  }, []); // Empty dependency array ensures this effect runs only once on mount and cleanup on unmount



  useEffect(() => {
    if(signinData){
      console.log('sync Auth');
      syncAuthentication();
    }    
    // eslint-disable-next-line
  }, [signinData]);

  useEffect(() => {
    
    if(props.signinData){
      setSigninData(props.signinData);
      setBaseUrl(props.signinData.baseUrl);
    }

    if(props.workflow && props.view === "builder"){
      updateLocation(`/flows/${props.workflow.environmentExternalWorkflowId}`);
    }
    if(props.workflow && props.view === "runs"){
      updateLocation(`/runs?flowId=${props.workflow.environmentExternalWorkflowId}#Runs`);
    }
    
    // eslint-disable-next-line
  }, [props.workflow]);

  const updateLocation = async function(url){
    setLocation(`${baseUrl}${url}`);
    
    //if signinData is not set, we need to set it. 
    if(!signinData){
      const signinResult = await workflowSignin();
      if(signinResult.successful){
        setSigninData(signinResult.data.data);
        setBaseUrl(signinResult.data.baseUrl);
        setLocation(`${baseUrl}${url}`);
      }else{
        // some error...
        props.store.SessionStore.error({
          message:"There was an error loading the builder, please try again later.",
          onClear: () => {
            props.closeDrawer()
          }
        });
      }
    }
  } 

  const syncAuthentication = () => {
    if (iframeRef.current) {
      const message = {
        token: signinData.token,
        currentUser: JSON.stringify(signinData),
        redirect: location
      };

      console.log('Post Message');
      console.log(message);

      iframeRef.current.contentWindow.postMessage(message, '*');
    }
  };

  return (
    <>
      {/*{loading ? (<WorkflowLoader />) : (<></>)}*/}
       <iframe id="activepieces" 
            ref={iframeRef} 
            title="activepieces"
            src={location} 
            style={iFrameStyle}></iframe>
      
       
    </>
    
  );
}

export default Activepieces;