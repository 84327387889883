import React, { useState } from "react";

const Card = (props) => {
  // eslint-disable-next-line
  const [copied, setCopied] = useState(false);

  return (
    <div className="card card-action shadow-none bg-lighter border border-secondary mb-4">
      <div className="card-alert"></div>
      <div className="card-header p-4">
        <div className="card-action-title d-flex align-items-center">
          <h4 className="m-0 me-2">{props.token.name}</h4>
            {props.token.role === 'ingest' ? (<span className="badge bg-label-primary">Ingest</span>) : (<></>)}
            {props.token.role === 'administrator' ? (<span className="badge bg-label-primary">Administrator</span>) : (<></>)}
            {props.token.role === 'user' ? (<span className="badge bg-label-primary">User</span>) : (<></>)}
        </div>
        <div className="card-action-element">
          <div className="btn-group">
            
            <button type="button" className="btn btn-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown" title="API Token Options">
              <i className="ti ti-dots-vertical ti-sm"></i>
            </button>
            <ul className="dropdown-menu">
                {/*
              <li>
                <a className="dropdown-item" href="javascript:void(0);">Edit</a>
              </li>
              <li>
                <a className="dropdown-item" href="javascript:void(0);">View</a>
              </li>
            */}
              <li>
                <button href="#" className="dropdown-item text-danger" onClick={() => props.delete(props.token)}>Delete</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="card-body">

        <div className="d-flex align-items-center mb-3">
            <p className="me-2 mb-0 fw-medium">{props.token.token}</p>
        </div>

        {/*
        <p className="card-text">
          This is the default key that is being used for the SDK ingestion of
          data.
        </p>
        <p className="card-text">Last Used: Never</p>

           */}
      </div>
   
    </div>
  );
};

export default Card;
