import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";


const useFeatureSecurity = (featureKey, props) => {
    const navigate = useNavigate();
  
    useEffect(() => {
        if(!props.store.SessionStore.subscription.getFeature(featureKey)){
           navigate('/');
        }
    }, [navigate, featureKey, props.store.SessionStore.subscription]);
};
  
  export default useFeatureSecurity;