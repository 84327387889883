import { action, observable, reaction } from 'mobx';
import { deleteCookie, getURLSegment, isLocalStorageSupported, replacePathPlaceholders } from '../utils/helpers';
import { APP_ROUTES } from '../utils/constants';
import { getUser, signout, listBuckets, listEnvironments, getStreamViews, getAccount} from '../lib/apiClient';
import  secureLocalStorage  from  "react-secure-storage";
import Subscription from '../Classes/Subscription';

// Other stores
import TrafficStore from './TrafficStore';

function SessionStore() {

  let obx = observable({
    phase: null,
    account: null,
    subscription: null,
    authenticated: false,
    user: null,
    buckets: [],
    environments: [],
    environment: null,
    streamViews: [],
    streamView: null,
    profile: null,
    accountSid: null,
    bucket: null,
    isSetup: false,
    hasSessionStorage: isLocalStorageSupported(),
    tokenSet: false,
    outsetaToken: null,
    errors: [],
    currentError : {},
    showErrorDialog: false,
    getLocalStorage: action(function(data) {
      obx.userData = data;
    }),

    error: action (async function(options) {
      if(options.message){
        // if the array has no length, set this error as teh current...
        if(!obx.errors.length){
          obx.currentError = options;
        }
        
        obx.errors.push(options);      
        obx.showErrorDialog = true;
      }
    }),

    clearError: action (async function(options) {
      const currentError = obx.currentError;
      if(obx.errors.length > 1){        
        // set the next one as the current...
        obx.currentError = obx.errors[1];
        // remove the error...
        obx.errors = obx.errors.shift();
        if(currentError.onClear){
          currentError.onClear();
        }
      }else{
        if(currentError.onClear){
          currentError.onClear();
        }
        if(currentError.onLastClear){
          obx.currentError.onLastClear();
        }
        obx.showErrorDialog = false;
      }
    }),
    
    isAuthenticated: action(async function() {
      const returnObject = { authenticated: false, message:null, redirectTo: null };
      const queryParameters = new URLSearchParams(window.location.search);
      const wasAuthenticated = obx.authenticated;
      const token = queryParameters.get("session") || secureLocalStorage.getItem('token') || null;
      const outseta_token = queryParameters.get("access_token") || secureLocalStorage.getItem('outseta_token') || null;

      const lookupResult = await obx.getUser(token, outseta_token);   

      if(lookupResult.authenticated){
      
        if(!obx.isSetup){
          // since the user was just signed in, we need to set a few values...
          obx.accountSid = lookupResult.user.defaultAccountSid;
          
          //console.log('Setup Session');

          // call the function to setup the session...
          await obx.setup();
        }

        if(!wasAuthenticated && lookupResult.authenticated && getURLSegment(1) === 'signin'){
          // TODO: What are we going to do if there is no bucket set??
          returnObject.redirectTo = replacePathPlaceholders(APP_ROUTES.DASHBOARD, {bucketSid: obx.bucket.sid});
        }

      }else{
        // ensure we are not on the signin or sign up route...if not redirect
        if(getURLSegment(1) !== 'signin' && getURLSegment(1) !== 'signup'){
          window.location.href = APP_ROUTES.SIGN_IN;
        }        
      }

      returnObject.authenticated = lookupResult.authenticated;
      returnObject.message = lookupResult.message;
          
      return returnObject;

    }),

    setup: action (async function() {
  //    const defaultReturnObject = { authenticated: false, message:"", user: null };

  
      if(obx.phase !== "preparing"){
        obx.phase = "preparing";
        //console.log('Start Setting Up Account');

        try {

            // START BUCKETS: load the buckets for this account...
              await obx.loadAccount();
              await obx.loadBuckets();
              // set the active bucket in the store...
              await obx.setActiveBucket();
              // now get all the views...
              await obx.loadStreamViews();
              // now get all the environments
              await obx.loadEnvironments();
              await obx.setActiveEnvironment();
            // END BUCKETS: load the buckets for this account...

          obx.isSetup = true;

          obx.phase = "ready";

          return true;
        }
        catch (err) {
          console.log('setting up account', err);
          return false;
        }
     }
    }),

    loadAccount: action (async function() {
      try {
            const result = await getAccount(obx.accountSid);

            if(result.successful){
              obx.account = result.data;
              obx.subscription = new Subscription({subscription: result.data.subscription});
            }

        return true;
      }
      catch (err) {
        //console.log('Error loading buckets...', err);
        return false;
      }
    }),

    loadBuckets: action (async function() {
      try {
            const bucketResult = await listBuckets(obx.accountSid);

            if(bucketResult.successful){
              obx.buckets = bucketResult.data.records;
            }

        return true;
      }
      catch (err) {
        //console.log('Error loading buckets...', err);
        return false;
      }
    }),

    loadEnvironments: action (async function() {
      try {
            const result = await listEnvironments(obx.accountSid);

            if(result.successful){
              obx.environments = result.data.records;
            }

        return true;
      }
      catch (err) {
        //console.log('Error loading buckets...', err);
        return false;
      }
    }),

    setActiveEnvironment: action(async function(environmentSid) {
      // if the environmentSid is explictly passed in, use it...
      if(environmentSid){
        obx.environments.forEach((environment) => {
          if(environment.sid === environmentSid){
            obx.environment = environment;
            // set the environmentSid in the local storage as well...
            secureLocalStorage.setItem('activeEnvironmentSid', environment.sid);
            return true;
          }
        });         
      }

      // check to see which is in the local store, if any...

      if(!environmentSid && secureLocalStorage.getItem('activeEnvironmentSid')){
        // make sure that is still an environment...
        const sidFromLocalStore = secureLocalStorage.getItem('activeEnvironmentSid');
        obx.environments.forEach((environment) => {
          if(environment.sid === sidFromLocalStore){
            obx.environment = environment;
            return true;
          }
        });     
      }
      
      
      // find the default one and set it...
      if(!obx.environment){
        obx.environments.forEach((environment) => {
          if(environment.isDefault){
            obx.environment = environment;
            // set the environmentSid in the local storage as well...
            secureLocalStorage.setItem('activeEnvironmentSid', environment.sid);
            return true;
          }
        });
      }
      
      // if we made it here, we just have to use the first one...  
      if(!obx.environment){
        if(obx.environments.length){
          obx.environment = obx.environments[0];
          secureLocalStorage.setItem('activeEnvironmentSid', obx.environments[0].sid);
        }else{
          // there are no envs setup, maybe we should error...
        }      
      }

      return true;
    }),

    getDefaultEnvironment: action(function() {
      obx.environments.forEach((environment) => {
        if(environment.isDefault){
          return environment;
        }
      });

      // if we could not find one...just return the first...
      return obx.environments[0];
    }),

    resetToDefaultEnvironment: action(function() {
      const defaultEnv = obx.getDefaultEnvironment();
      secureLocalStorage.setItem('activeEnvironmentSid', defaultEnv.sid);
      obx.environment =   defaultEnv;   
    }),

  getActiveBucketSid: action(function() {
    return obx.getActiveBucket().sid;  
  }),

  getActiveBucket: action(function() {
    // if the bucketsid is explictly passed in, use it...
    if(obx.bucket?.sid){
      // return the bucket that is in the store...
      return obx.bucket;
    }else if (secureLocalStorage.getItem('activeBucketSid')){
      // return the bucket that is in local storage...
      return secureLocalStorage.getItem('activeBucketSid');
    }else if(obx.buckets[0].length){
      // return the default bucket...
      return obx.buckets[0];
    } else{
      //error
      return null;
    }         
    }),

    setActiveBucket: action(async function(bucketSid) {
      // if the bucketsid is explictly passed in, use it...
      if(bucketSid){
        obx.buckets.forEach((bucket) => {
          if(bucket.sid === bucketSid){
            obx.bucket = bucket;
            // set the bucketSid in the local storage as well...
            secureLocalStorage.setItem('activeBucketSid', bucket.sid);
          }
        });
        return true;   
      }
      
      // if the bucket is in the URL, we can load that specific one...
      if(getURLSegment(1) === 'buckets' && getURLSegment(2).length && obx.buckets.length){
        const bucketSid = getURLSegment(2);
        obx.buckets.forEach((bucket) => {
          if(bucket.sid === bucketSid){
            obx.bucket = bucket;
            secureLocalStorage.setItem('activeBucketSid', bucket.sid);
          }
        });
        
        if(!obx.bucket){
          //console.log('Bucket Not Set');
          return false;
        }
        return true;
      }else{
        // if nothing else just set the first bucket in the array...
        obx.bucket = obx.buckets[0];
        secureLocalStorage.setItem('activeBucketSid', obx.buckets[0].sid);
        return true;
      }
    }),

    setAppIsConnected: action(async function(status) {
      obx.getActiveBucket().appIsConnected = status;
    }),

    getBucketSidFromUrl: action(function() {
      // if the bucket is in the URL, we can load that specific one...
      if(getURLSegment(1) === 'buckets' && getURLSegment(2).length){
        const bucketSid = getURLSegment(2);
              
        if(!bucketSid){
          //console.log('BucketSid Not in URL');
          return null;
        }
        return bucketSid;
      }else{
        // if nothing else just set the first bucket in the array...
        obx.bucket = obx.buckets[0];
        secureLocalStorage.setItem('activeBucketSid', obx.buckets[0].sid);
        return true;
      }
    }),

    getContext: action(function() {
      // if the bucket is in the URL, we can load that specific one...
      if(getURLSegment(1) === 'buckets' && getURLSegment(2).length){
        return "bucket";
      }else if(getURLSegment(1) === 'account' && getURLSegment(2).length){
        return "account";
      }else{
        return null;
      }
    }),

    getUser: action (async function(sessionToken, outsetaToken) {
      const defaultReturnObject = { authenticated: false, message:"", user: null };

      try {
        const response = await getUser(sessionToken);

        const result = {
            authenticated: response.successful,
            user: response.user || null,
            message: null
        }

        if(result.authenticated){
          
          obx.user = result.user;
          obx.authenticated = true;
          obx.token = sessionToken;
          obx.outsetaToken = outsetaToken;

          secureLocalStorage.setItem('user', JSON.stringify(result.user));
          secureLocalStorage.setItem('token', sessionToken);
          secureLocalStorage.setItem('authenticated', true);
          secureLocalStorage.setItem('outseta_token', outsetaToken);
          
        }else{
          secureLocalStorage.clear('user');
        }

        return result;
      }
      catch (err) {
        //console.log('getAuthenticatedUser, Something Went Wrong', err);
        return defaultReturnObject;
      }
    }),

    loadStreamViews: action (async function() {
      try {
            const streamViewsResult = await getStreamViews({accountSid: obx.accountSid, bucketSid: obx.getActiveBucketSid()});

            if(streamViewsResult.successful){
              obx.streamViews = streamViewsResult.data.records;
            }

        return true;
      }
      catch (err) {
        //console.log('Error loading buckets...', err);
        return false;
      }
    }),

    setAsActiveStreamView: action (async function(streamViewSid) {
      for (let i = 0; i < obx.streamViews.length; i++) {
        if (obx.streamViews[i].sid === streamViewSid) {
          obx.streamView = obx.streamViews[i];
          break;
        }
      }
    }),

    
    getAccountSid: action(function() {
      if(obx.hasSessionStorage) {
        return JSON.parse(secureLocalStorage.getItem('user')).defaultAccountSid;
      } else {
        return null;
      }
    }),

    getOutsetaToken: action(function() {
        return secureLocalStorage.getItem('outseta_token');
    }),

    removeLocalStorage: action(function() {
      if(obx.hasSessionStorage) {
        secureLocalStorage.removeItem('userData');
      } else {
        deleteCookie('userData');
      }
    }),
    
    logout: action(async function () {
      const response = await signout();

      if(response){
        secureLocalStorage.clear();
        obx.resetStores();
        return true;
      }else{
        return false;
      }
    }),
    

    resetStores: action(function() {
      // Reset session store (this)
      obx.userData = null;
      obx.profile = null;
      obx.account = null;
      obx.bucket = null;
      obx.buckets = [];
      obx.streamViews = [];
      obx.phase = null;

      // Reset all stores
      TrafficStore.reset();

    })
    
  });

  // listen to any bucket change and react......
  reaction(() => obx.bucket, async (current, previous) => {
    // ensure there was an actual change...
    if(previous?.sid && current?.sid && previous.sid !== current.sid){
      await obx.loadStreamViews();
      
      // reset the traffic stores...
      TrafficStore.reset();
    }

  });

  async function init() {
    await obx.isAuthenticated();
  }

  init();

  return obx;

}

export default SessionStore();