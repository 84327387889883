import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { Dropdown, DropdownButton, Form, Button } from "react-bootstrap";

/**
 * FilterElement component to build and display filters.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.fields - List of available fields for filtering.
 * @param {Object} props.filterAutoComplete - Configuration for auto-completing filter values.
 * @param {string} props.defaultValue - Default filter string value.
 * @param {boolean} props.allowOr - Whether to allow "OR" operator.
 * @param {boolean} props.isRawMode - Whether to display the component in raw mode.
 * @param {Function} props.onFiltersChange - Callback function when filters change.
 * @returns {JSX.Element} The FilterElement component.
 */
const FilterElement = forwardRef(({ fields, initialFilterAutoComplete, defaultValue, allowOr = false, isRawMode = false, onFiltersChange, clearFilter}, ref) => {
  const [filters, setFilters] = useState([]);
  const [showFilterSearch, setShowFilterSearch] = useState(false);
  const [rawValue, setRawValue] = useState(defaultValue || "");
  const prevFilters = useRef(filters);
  const [filterAutoComplete, setFilterAutoComplete] = useState();

  useImperativeHandle(ref, () => ({
    updateContextArray,
  }));

  useEffect(() => {

    setFilterAutoComplete(initialFilterAutoComplete);

    if (defaultValue) {
      setBuilderValue(defaultValue);
    }

    const handleBodyClick = () => {
      setShowFilterSearch(false);
    };
    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
    // eslint-disable-next-line
  }, [defaultValue]);

  useEffect(() => {
    setRawValue(filters.join(" "));
    // make sure there was an actual change...
    if (onFiltersChange && prevFilters.current.join(" ") !== filters.join(" ")) {
      onFiltersChange(filters.join(" "));
      prevFilters.current = filters;
    }
    // eslint-disable-next-line
  }, [filters]);

  // Call this function explicitly to clear the filters
  const clearFiltersFunction = () => {
    setFilters([]);
    setRawValue('');
    if (onFiltersChange) {
      onFiltersChange('');
    }
  };

  useEffect(() => {
    if(clearFilter){
      clearFiltersFunction();
    }
    // eslint-disable-next-line
  }, [clearFilter]);

  /**
     * Update the context array for a given field in filterAutoComplete.
     *
     * @param {string} field - The field name.
     * @param {Array} newContextArray - The new array of contexts.
     */
  const updateContextArray = (field, newContextArray) => {
    setFilterAutoComplete((prevFilterAutoComplete) => ({
      ...prevFilterAutoComplete,
      [field]: {
        ...prevFilterAutoComplete[field],
        context: newContextArray,
      },
    }));
  };


  /**
   * Handle the event when a filter is added.
   *
   * @param {string} filter - The filter string.
   */
  const handleChildEvent = (filter) => {
    let joiner = "AND";
    const [newField, newOperator, value] = getFieldAndValue(filter);

    for (let filter of filters) {
      const [field, operator, val] = getFieldAndValue(filter);
      if (allowOr && newField === field) {
        joiner = "OR";
      }
      if (newField === field && newOperator === operator && value === val) {
        setShowFilterSearch(false);
        return;
      }
    }

    if (filters.length === 0) {
      updateFilters([filter]);
    } else {
      updateFilters([...filters, joiner, filter]);
    }
    setShowFilterSearch(false);
  };

  /**
   * Set the builder value from a filter string.
   *
   * @param {string} value - The filter string value.
   */
  const setBuilderValue = (value) => {
    const joiners = value
      .split(" ")
      .filter((v) => v.toUpperCase() === "AND" || v.toUpperCase() === "OR");
    let current = 0;
    const fls = value
      .split(/\s+AND\s+|\s+OR\s+/i)
      .flatMap((element, index, array) => {
        return index < array.length - 1
          ? [element, (joiners[current++] || "AND").toUpperCase()]
          : [element];
      });
    updateFilters(fls);
  };

  /**
   * Get field, operator, and value from a filter string.
   *
   * @param {string} filter - The filter string.
   * @returns {Array} Array containing field, operator, and value.
   */
  const getFieldAndValue = (filter) => {
    const parts = filter.trim().split(/\s*([=<>!]+|like)\s*/i);
    if (parts.length !== 3) {
      return [];
    }
    const [field, operator, value] = parts;
    return [field, operator, value];
  };

  /**
   * Update the filters state.
   *
   * @param {Array} newVal - New filters array.
   */
  const updateFilters = (newVal) => {
    setFilters(newVal.filter((v) => v !== ""));
    const val = newVal.join(" ");
    setRawValue(val);
    window.queryBuilderValue = val;
    if (window.editor) {
      window.editor.setValue(val);
    }
  };

  /**
   * Remove a filter from the filters array.
   *
   * @param {string} filter - The filter to remove.
   */
  const removeFilter = (filter) => {
    const index = filters.indexOf(filter);
    if (index > 0) {
      filters[index - 1] = filter;
    } else {
      if (filters.length > 0) {
        filters[index + 1] = filter;
      }
    }
    updateFilters(filters.filter((f) => f !== filter));
  };

  /**
   * Toggle the join operator between AND and OR.
   *
   * @param {number} index - Index of the operator to toggle.
   */
  const toggleJoinOperator = (index) => {
    const newFilters = [...filters];
    if (newFilters[index] === "AND" && allowOr) {
      newFilters[index] = "OR";
    } else {
      newFilters[index] = "AND";
    }
    updateFilters(newFilters);
  };

  return (
    <div className="relative w-full" onClick={(e) => e.stopPropagation()}>
      {isRawMode ? (
        <Form.Control
          as="textarea"
          rows={3}
          value={rawValue}
          onChange={(e) => {
            setRawValue(e.target.value);
            setBuilderValue(e.target.value);
          }}
          className="mt-2"
        />
      ) : (
        <div className="d-flex flex-wrap gap-2 m-2 border-slate-400  align-items-center py-2 rounded">
          <i className="ti ti-filter ti-md text-gray-500"></i>
          <div className="d-flex flex-wrap gap-2">
            {filters.map((filter, index) => (
              <React.Fragment key={index}>
                {filter === "AND" || filter === "OR" ? (
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => allowOr && toggleJoinOperator(index)}
                    className="px-2 py-1 rounded-pill"
                  >
                    {filter}
                    {allowOr && <i className="fas fa-sliders-h"></i>}
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    size="sm"
                    className="text-white text-sm font-bold px-2 py-1 rounded-pill"
                  >
                    {filter}
                    <span
                      className="ml-2 text-xs p-1 rounded-full"
                      onClick={() => removeFilter(filter)}
                    >
                      <i className="fas fa-times"></i>
                    </span>
                  </Button>
                )}
              </React.Fragment>
            ))}
            {showFilterSearch ? (
              <FilterSuggestions
                onAddFilter={handleChildEvent}
                fields={fields}
                filterAutoComplete={filterAutoComplete}
                allowOr={allowOr}
              />
            ) : filters.length === 0 ? (
              <Button
                variant="link"
                size="sm"
                onClick={() => setShowFilterSearch(!showFilterSearch)}
                className="text-secondary"
              >
                Click to add filter...
              </Button>
            ) : (
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => setShowFilterSearch(!showFilterSearch)}
                className="px-2 py-1"
              >
                <i className="fas fa-plus"></i>
              </Button>
              
            )}
          </div>
        </div>
      )}
    </div>
  );
});

/**
 * FilterSuggestions component to provide auto-complete suggestions for filter fields.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.onAddFilter - Callback function when a filter is added.
 * @param {Array} props.fields - List of available fields for filtering.
 * @param {Object} props.filterAutoComplete - Configuration for auto-completing filter values.
 * @param {boolean} props.allowOr - Whether to allow "OR" operator.
 * @returns {JSX.Element} The FilterSuggestions component.
 */
const FilterSuggestions = ({
  onAddFilter,
  fields,
  filterAutoComplete,
  allowOr
}) => {
  const [selectedField, setSelectedField] = useState("");
  const [selectedContext, setSelectedContext] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("");
  const [value, setValue] = useState("");
  const operatorDropdownRef = useRef(null);
  const valueDropdownRef = useRef(null);
  const valueInputRef = useRef(null);
  const contextDropdownRef = useRef(null);
  const addButtonRef = useRef(null);
  const [showOperatorDropdown, setShowOperatorDropdown] = useState(false);
  const [showValueDropdown, setShowValueDropdown] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [showContextDropdown, setShowContextDropdown] = useState(false);
  const [isOtherContext, setIsOtherContext] = useState(false);

  /**
   * Handle the selection of a field.
   *
   * @param {string} e - The selected field.
   */
  const handleFieldSelect = (e) => {
    setSelectedField(e);
    setSelectedContext("");
    setSelectedOperator("");
    setValue("");
    setShowAddButton(false);
    setIsOtherContext(false);
    if (filterAutoComplete[e]?.context) {
      setShowContextDropdown(true);
      setTimeout(() => contextDropdownRef.current.firstChild.focus(), 50);
    } else {
      setShowOperatorDropdown(true);
      setTimeout(() => operatorDropdownRef.current.firstChild.focus(), 50);
    }
  };

  /**
   * Handle the selection of a context.
   *
   * @param {string} e - The selected context.
   */
  const handleContextSelect = (e) => {
    if (e === "other") {
      setIsOtherContext(true);
      setSelectedContext("");
      setShowContextDropdown(false);
      setTimeout(() => valueInputRef.current.focus(), 0);
    } else {
      setSelectedContext(e);
      setIsOtherContext(false);
      setShowOperatorDropdown(true);
      setTimeout(() => operatorDropdownRef.current.firstChild.focus(), 50);
    }
  };

  /**
   * Handle the blur event of the context input.
   */
  const handleContextBlur = () => {
    if (isOtherContext && !selectedContext) {
      setIsOtherContext(false);
      setSelectedContext("");
      setShowContextDropdown(true);
    }
  };

  /**
   * Handle the selection of an operator.
   *
   * @param {string} e - The selected operator.
   */
  const handleOperatorSelect = (e) => {
    setSelectedOperator(e);
    setShowOperatorDropdown(false);
    if (filterAutoComplete[selectedField]?.values?.length > 0) {
      setShowValueDropdown(true);
      setTimeout(() => valueDropdownRef.current.firstChild.focus(), 50);
    } else {
      setShowValueDropdown(false);
      setShowAddButton(true);
      setTimeout(() => valueInputRef.current.focus(), 50);
    }
  };

  /**
   * Handle the selection of a value.
   *
   * @param {string} e - The selected value.
   */
  const handleValueSelect = (e) => {
    setValue(e);
    setShowValueDropdown(false);
    setShowAddButton(true);
    setTimeout(() => addButtonRef.current.focus(), 0);
  };

  /**
   * Handle the addition of a filter.
   */
  const handleAddFilter = () => {
    if (selectedField && selectedOperator && value) {
      const contextPart = selectedContext ? `:${selectedContext}` : '';
      const valueWithQuotes =
        filterAutoComplete[selectedField]?.type === 'string' ? `"${value}"` : value;
      onAddFilter(`${selectedField}${contextPart} ${selectedOperator} ${valueWithQuotes}`);
      setSelectedField("");
      setSelectedContext("");
      setSelectedOperator("");
      setValue("");
      setShowValueDropdown(false);
      setShowAddButton(false);
      setTimeout(() => contextDropdownRef.current?.firstChild.focus(), 50);
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddFilter();
    }
  };

  return (
    <div className="d-flex gap-2 align-items-center"> 
      <DropdownButton
        id="dropdown-basic-button"
        size="sm"
        variant="success"
        title={selectedField || "Field"}
        onSelect={handleFieldSelect}
      >
        {fields.map((field, index) => (
          <Dropdown.Item key={index} eventKey={field}>
            {filterAutoComplete[field]?.name || field}
          </Dropdown.Item>
        ))}
      </DropdownButton>

      {selectedField && filterAutoComplete[selectedField]?.context && !isOtherContext && (
        <DropdownButton
          ref={contextDropdownRef}
          id="dropdown-basic-button"
          size="sm"
          variant="success"
          title={selectedContext || "Context"}
          show={showContextDropdown}
          onToggle={(isOpen) => setShowContextDropdown(isOpen)}
          onSelect={handleContextSelect}
          disabled={!selectedField}
        >
          {filterAutoComplete[selectedField].context.filter(Boolean).map((context, index) => (
            <Dropdown.Item key={index} eventKey={context}>
              {context}
            </Dropdown.Item>
          ))}
          {filterAutoComplete[selectedField].context.includes("") && (
            <Dropdown.Item eventKey="other">Other</Dropdown.Item>
          )}
        </DropdownButton>
      )}

      {isOtherContext && (
        <Form.Control
          size="sm"
          type="text"
          placeholder="Enter context"
          variant="success"
          value={selectedContext}
          onChange={(e) => setSelectedContext(e.target.value)}
          onBlur={handleContextBlur}
          className="ml-2"
        />
      )}

      {selectedField && (
        <DropdownButton
          ref={operatorDropdownRef}
          id="dropdown-basic-button"
          size="sm"
          variant="success"
          title={selectedOperator || "Operator"}
          show={showOperatorDropdown}
          onToggle={(isOpen) => setShowOperatorDropdown(isOpen)}
          onSelect={handleOperatorSelect}
          disabled={!selectedField}
        >
          {(filterAutoComplete[selectedField]?.operators || []).map(
            (operator, index) => (
              <Dropdown.Item key={index} eventKey={operator}>
                {operator}
              </Dropdown.Item>
            )
          )}
        </DropdownButton>
      )}

      {selectedOperator && filterAutoComplete[selectedField]?.values?.length > 0 && (
        <DropdownButton
          ref={valueDropdownRef}
          id="dropdown-basic-button"
          size="sm"
          variant="success"
          title={value || "Value"}
          show={showValueDropdown}
          onToggle={(isOpen) => setShowValueDropdown(isOpen)}
          onSelect={handleValueSelect}
          disabled={!selectedField || !selectedOperator}
        >
          
          {filterAutoComplete[selectedField]?.values.map((val, index) => (
            <Dropdown.Item key={index} eventKey={val}>
              {val}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}

      {selectedOperator && !filterAutoComplete[selectedField]?.values?.length > 0 && (
        <Form.Control
          ref={valueInputRef}
          size="sm"
          variant="success"
          type="text"
          placeholder="Value"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setShowAddButton(e.target.value.length > 0);
          }}
          onKeyPress={handleInputKeyPress}
          disabled={!selectedField || !selectedOperator}
        />
      )}

      {showAddButton && (
        <Button
          ref={addButtonRef}
          variant="success"
          size="sm"
          onClick={handleAddFilter}
          disabled={!selectedField || !selectedOperator || !value}
        >
          Add
        </Button>
      )}
    </div>
  );
};

export default FilterElement;
