import React from 'react';
import { observer } from 'mobx-react';

const SecuritySettings = observer((props) => {

    return (
        <>
        <p>
                                    Icing pastry pudding oat cake. Lemon drops cotton candy caramels cake caramels sesame snaps powder. Bear
                                    claw
                                    candy topping.
                                </p>
                                <p className="mb-0">
                                    Tootsie roll fruitcake cookie. Dessert topping pie. Jujubes wafer carrot cake jelly. Bonbon jelly-o
                                    jelly-o ice
                                    cream jelly beans candy canes cake bonbon. Cookie jelly beans marshmallow jujubes sweet.
                                </p>
        </>
    );
});

export default SecuritySettings;



