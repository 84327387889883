import React from 'react';

const TourOptions = (props) => {

    return (
        <>
            <div className="modal fade" id="tourOptions" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-simple">
                    <div className="modal-content p-3 p-md-5">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="text-center mb-4">
                                <h3 className="mb-2">Product Tours</h3>
                                <p className="text-muted">Questions about how to use the product? No problem, select any of the product tours below for more information on how to use that feature.</p>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="form-check custom-option custom-option-basic checked">
                                        <label className="form-check-label custom-option-content ps-3" htmlFor="customRadioTemp1" data-bs-target="#twoFactorAuthOne" data-bs-toggle="modal">
                                            <input name="customRadioTemp" className="form-check-input d-none" type="radio" value="" id="customRadioTemp1" />
                                            <span className="d-flex align-items-start">
                                                <i className="ti ti-settings ti-xl me-3"></i>
                                                <span>
                                                    <span className="custom-option-header">
                                                        <span className="h4 mb-2">Getting Started</span>
                                                    </span>
                                                    <span className="custom-option-body">
                                                        <span className="mb-0">Get code from an app like Google Authenticator or Microsoft Authenticator.</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="form-check custom-option custom-option-basic">
                                        <label className="form-check-label custom-option-content ps-3" htmlFor="customRadioTemp2" data-bs-target="#twoFactorAuthTwo" data-bs-toggle="modal">
                                            <input name="customRadioTemp" className="form-check-input d-none" type="radio" value="" id="customRadioTemp2" />
                                            <span className="d-flex align-items-start">
                                                <i className="ti ti-message ti-xl me-3"></i>
                                                <span>
                                                    <span className="custom-option-header">
                                                        <span className="h4 mb-2">Bucket Management</span>
                                                    </span>
                                                    <span className="custom-option-body">
                                                        <span className="mb-0">We will send a code via SMS if you need to use your backup login method.</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="form-check custom-option custom-option-basic">
                                        <label className="form-check-label custom-option-content ps-3" htmlFor="customRadioTemp2" data-bs-target="#twoFactorAuthTwo" data-bs-toggle="modal">
                                            <input name="customRadioTemp" className="form-check-input d-none" type="radio" value="" id="customRadioTemp2" />
                                            <span className="d-flex align-items-start">
                                                <i className="ti ti-message ti-xl me-3"></i>
                                                <span>
                                                    <span className="custom-option-header">
                                                        <span className="h4 mb-2">Request Details</span>
                                                    </span>
                                                    <span className="custom-option-body">
                                                        <span className="mb-0">We will send a code via SMS if you need to use your backup login method.</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="form-check custom-option custom-option-basic">
                                        <label className="form-check-label custom-option-content ps-3" htmlFor="customRadioTemp2" data-bs-target="#twoFactorAuthTwo" data-bs-toggle="modal">
                                            <input name="customRadioTemp" className="form-check-input d-none" type="radio" value="" id="customRadioTemp2" />
                                            <span className="d-flex align-items-start">
                                                <i className="ti ti-message ti-xl me-3"></i>
                                                <span>
                                                    <span className="custom-option-header">
                                                        <span className="h4 mb-2">Caputure Requests</span>
                                                    </span>
                                                    <span className="custom-option-body">
                                                        <span className="mb-0">We will send a code via SMS if you need to use your backup login method.</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-check custom-option custom-option-basic">
                                        <label className="form-check-label custom-option-content ps-3" htmlFor="customRadioTemp2" data-bs-target="#twoFactorAuthTwo" data-bs-toggle="modal">
                                            <input name="customRadioTemp" className="form-check-input d-none" type="radio" value="" id="customRadioTemp2" />
                                            <span className="d-flex align-items-start">
                                                <i className="ti ti-message ti-xl me-3"></i>
                                                <span>
                                                    <span className="custom-option-header">
                                                        <span className="h4 mb-2">Proxying Requests</span>
                                                    </span>
                                                    <span className="custom-option-body">
                                                        <span className="mb-0">We will send a code via SMS if you need to use your backup login method.</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default TourOptions;