import React from 'react';
import RenderBody from './RenderBody';
import uuid from 'react-uuid';

const RequestTab = (props) => {
  return (
    <>      
        <div className="text-lighter fw-semibold">ApiTraffic ID</div>
        <ul className="list-unstyled pb-3 mx-2 request-detail-request-traffic-sid-container">
            <li>{props.sid}</li>
        </ul>
        
        {!props.capture ? (
            <>
                <div className="text-lighter fw-semibold">FULL URL</div>
                <ul className="list-unstyled pb-3 mx-2 request-detail-request-full-url-container">
                    <li>{props.request.url}</li>
                </ul>
            </>
        ):(<></>)}
        <div className="text-lighter fw-semibold">HEADERS</div>
        <ul className="list-unstyled pb-3 mx-2 request-detail-request-headers-container">
            {Object.keys(props.request.headers).map((key, index) =>                 
                <li key={uuid()}><strong>{key}:</strong> {props.request.headers[key]}</li>
            )}
        </ul>
        {props.request.body ? (
            <>
            <div className="text-lighter fw-semibold">BODY</div>
            <div className="mx-2 request-detail-request-body-container">
                <RenderBody data={props.request} />
            </div>
            </>
        ) : ('')} 
    </>
  );
}

export default RequestTab;