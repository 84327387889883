import React from 'react';
import { JsonViewer } from '@textea/json-viewer'
import XMLViewer from 'react-xml-viewer'
import uuid from 'react-uuid';

const RenderBody = (props) => {


  const renderBody = () => {
    if(props.data?.contentType && props.data?.body && props.data.contentType.toLowerCase().includes("json")){
        let _body = props.data.body;
        try {
            _body = JSON.parse(_body);
          } catch (e) {
            // do nothing
            console.log(e);
          }

            return <JsonViewer  key={uuid()}
                    value={_body} 
                    rootName={false}
                    displayDataTypes={false} 
                    enableClipboard={true}
                    groupArraysAfterLength={10}
                    displayObjectSize={false} />
    }else if(props.data?.contentType && props.data?.body && props.data.contentType.toLowerCase().includes("xml")){
        return <XMLViewer  key={uuid()} 
                           xml={props.data.body} 
                           invalidXml={props.data.body}
                           collapsible={true} />
    }else{
        return props.data?.body || null;
    }
  };

  return renderBody();
}

export default RenderBody;