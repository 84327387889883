import React from 'react';

const Footer = (props) => {
  let fullYear = new Date().getFullYear();
  return (
    <>
        <footer className="content-footer footer bg-footer-theme">
              <div className="container-xxl">
                <div
                  className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column"
                >
                  <div>
                    © {fullYear} Arch Rock Ventures, LLC
                  </div>
                  {/*
                  <div>
                    <a href={APP_ROUTES.SUPPORT} className="footer-link me-4">Support</a>
                    <a href={KB_URL} target="_blank" className="footer-link me-4">Documentation</a>
                    <a href="https://pixinvent.ticksy.com/" target="_blank" className="footer-link d-none d-sm-inline-block"
                      >Support</a
                    >
                  </div>
                   */}
                </div>
              </div>
            </footer>
    </>
    
  );
}

export default Footer;