import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import uuid from 'react-uuid';
import { replacePathPlaceholders, getURLSegment } from '../../utils/helpers';
import { APP_ROUTES } from '../../utils/constants';
import { createBucket } from '../../lib/apiClient';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { limitTextDisplay } from '../../utils/helpers';

const BucketSelector = observer((props) => {
  const navigate = useNavigate();
  const [showNew, setShowNew] = useState(false);


  const handleClose = () => setShowNew(false);
  const handleShow = () => setShowNew(true);
  
  // Handles modifying the URL when the user selects a different bucket...
  const handleBucketSelection = (bucket) => {
    // get the path..
    const pathParts = window.location.pathname.split('/');
    // if the second item is the current bucket...then replace.
    if(getURLSegment(2) !== bucket.sid){
      // set the active bucket...
      props.store.SessionStore.setActiveBucket(bucket.sid);
      // replace the bucketsid in the path with the updated bucket...
      pathParts[2] = bucket.sid;
      // now put back together and navigate the user to the proper place...
      //navigate(pathParts.join('/'));
      navigate(replacePathPlaceholders(APP_ROUTES.DASHBOARD, {bucketSid: bucket.sid}));
    }else{
      navigate(replacePathPlaceholders(APP_ROUTES.TRAFFIC, {bucketSid: props.store.SessionStore.getActiveBucketSid()}));
    }   
  };
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = async(data, e)  => {
        const params = {
            accountSid : props.store.SessionStore.accountSid,
            name: data.bucketName
        };

        const createResult = await createBucket(params);

        if(createResult.successful){
            // Reload the buckets into the store...
            await props.store.SessionStore.loadBuckets();
            // close the modal..
            setShowNew(false);
            // reset the form..
            reset();
            // navigate to the new bucket...
            navigate(replacePathPlaceholders(APP_ROUTES.DASHBOARD, {bucketSid: createResult.data.sid}));
            // Set the newly created bucket as the active bucket.
            props.store.SessionStore.setActiveBucket(createResult.data.sid);
        }else{
            // TODO: handle error...
            //console.log(createResult);
        }

    } 

    const onError = (errors, e) => {
        //console.log(errors);
    };

    // The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button className="btn btn-link text-dark fw-bold mb-1" ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <i className="ti ti-caret-down"></i>
  </button>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, ] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {/* 
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        */}
        <ul className="list-unstyled mb-0">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);

  return (
    <>
    <Dropdown className="d-inline">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {props.store.SessionStore.bucket?.name}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {props.store.SessionStore.buckets.map((bucket) => (
          <Dropdown.Item key={uuid()} onClick={() => handleBucketSelection(bucket)}>{limitTextDisplay(bucket.name, 40)}</Dropdown.Item> 
        ))}
        <Dropdown.Divider />
        <Dropdown.Item key={uuid()} onClick={handleShow}><i className="ti align-top ti-circle-plus"></i> Create Bucket</Dropdown.Item> 
      </Dropdown.Menu>
    </Dropdown>

    <Modal show={showNew} onHide={handleClose}>
      <form className="modal-content" onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Bucket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
          <div className="col mb-3">
              <label htmlFor="bucketName" className="form-label">Name*</label>
              <input {...register("bucketName", { required: true, maxLength: 50 })} className="form-control" placeholder="Bucket Name" />
              {errors.bucketName?.type === 'required' && <div className="error-message">Please enter a bucket name</div>}
              {errors.bucketName?.type === 'maxLength' && <div className="error-message">The bucket name cannot be longer than 50 characters</div>}
              
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
        </form>
      </Modal>



    </>
  );
});

export default BucketSelector;