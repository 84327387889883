import React, { useState } from "react";
import { observer } from "mobx-react";
import { showToast } from '../utils/helpers';
import Button from 'react-bootstrap/Button';
import DisplayDateTime from "../Components/Common/DisplayDateTime";
import EnvironmentModal from "../Layouts/Components/EnvironmentModal";
import { updateEnvironment } from "../lib/apiClient";

const Environments = observer((props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [environment, setEnvironment] = useState();
  const [dialogMode, setDialogMode] = useState();

  const handleEdit = (environment)=>{
    setEnvironment(environment);
    setShowDialog(true);  
    setDialogMode("update");
  }

  const handleDelete = (environment)=>{
    setEnvironment(environment);
    setShowDialog(true);
    setDialogMode("delete");  
  }

  const handleMakeDefault = async(environment)=>{
    const params = {
      accountSid : props.store.SessionStore.accountSid,
      environmentSid: environment.sid,
      isDefault: true
  };
    
    const result = await updateEnvironment(params);

    if(result.successful){
        // Reload the Environments into the store...
        await props.store.SessionStore.loadEnvironments();

        showToast("Default environment changed!");
    }else{
        // TODO: handle error...
        //console.log(createResult);
    }
            
  }

  return (
    <>
      {props.store.SessionStore.subscription.getFeature("environmentsEnabled") &&
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="mb-0">&nbsp;</h2>
        <Button variant="success"  onClick={()=>{
          setDialogMode("create");
          setShowDialog(true);          
          }} >Add Environment</Button> 
      </div>}
      <div className="row">
            <div className="col-md-12">
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">                    
                              <div className="table">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th className="text-center">Default</th>
                                      <th className="text-center">Created</th>
                                      <th className="text-center">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-border-bottom-0">
                                  {props.store.SessionStore.environments.map((environment) => (                      
                                    <tr key={`detail_${environment.sid}`}>
                                      <td>{environment.name}</td>
                                      <td className="text-center">{environment.isDefault ? (<i className="ti ti-circle-check"></i>):(<></>)}</td>
                                      <td className="text-center"><DisplayDateTime timestamp={environment.createdAt} timezone={props.store.SessionStore.user.timezone} /></td>                      
                                      <td className="text-center">
                                        <div className="dropdown text-center">
                                          <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown"><i className="ti ti-dots-vertical"></i></button>
                                          <div className="dropdown-menu">
                                            <button className="dropdown-item btn-link" onClick={()=>handleEdit(environment)} ><i className="ti ti-pencil me-1 mb-1"></i>Edit</button>
                                            {!environment.isDefault && <button className="dropdown-item btn-link" onClick={()=>handleMakeDefault(environment)} ><i className="ti ti-bookmark me-1 mb-1"></i>Make Default</button>}
                                            {(environment.name !== "Production" && !environment.isDefault) && <button className="dropdown-item btn-link" onClick={()=>handleDelete(environment)} ><i className="ti ti-trash me-1 mb-1"></i>Delete</button>}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>    
                                    ))}          
                                  </tbody> 
                                </table>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <EnvironmentModal {...props}
                show={showDialog} 
                close={()=>{
                  setShowDialog(false);
                  setEnvironment(null);
                }}
                mode={dialogMode}
                environment={environment}
                onComplete={(environment)=>{
                  if(dialogMode === "create"){
                    showToast("Environment Created!");
                  }else if(dialogMode === "update"){
                    showToast("Environment Updated!");
                  }else if(dialogMode === "delete"){
                    showToast("Environment has been queued for removal!");
                  }                  
                }}/>
    </>
  );
});

export default Environments;
