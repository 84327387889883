import React from "react";

const ChartLoader = (props) => {
    const defaultLabel = "Please wait...";


    return (
        <div className="d-flex align-items-center">
            <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <small className="text-muted ms-2">
                {props?.label ? (props.label) : (defaultLabel)}
            </small>
        </div>

      );
};

export default ChartLoader;