import {GATEWAY_DOMAIN} from '../../utils/constants';
import { CopyBlock, nord } from "react-code-blocks";

const NoCaptures = (props) => {
  let domain = `${props.store.SessionStore.getActiveBucketSid()}${props.store.SessionStore.environment.sid}.${GATEWAY_DOMAIN}`;
  let curl = `curl --location --request POST 'https://${domain}/some-path' \\
      --header 'Content-Type: application/json' \\
      --data '{"name" : "John Doe","phone" : "+1-123-456-7890"}'`;   
  


  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-xl-4 text-center">
          {/* https://kit8.net/illustrations/new-york-series/man-catching-mail/ */}
          <img src="/assets/images/waiting.svg" alt="Person waiting"/>
        </div>        
        <div className="col-lg-12 col-xl-8">
          <h4>Waiting to capture...</h4>
          <p>What is a capture? Captures allow you to view a request without sending it someplace. For example, if you need to capture webhooks to view the payloads, you can direct them to your capture URL.</p>
          <p>Follow these steps to capture requests:</p>
          <ol>
            <li>Modify the domain of your request to point to: <code>{domain}</code></li>
            <li>Send the request</li>
            <li>Watch for the request on this page and review the details</li>
          </ol>
          <h4>Drop this sample in terminal to capture your first request.</h4>
          <CopyBlock text={curl} language='bash' theme={nord} />
        </div>  
      </div>    
    </>
  );
}

export default NoCaptures;
