import React from 'react';
import uuid from 'react-uuid';
import RenderBody from './RenderBody';

const ResponseTab = (props) => {

  return (
    <>      
        <div className="text-lighter fw-semibold">HEADERS</div>
        <ul className="list-unstyled pb-3 mx-2 request-detail-response-headers-container">
            {Object.keys(props.response.headers).map((key, index) =>                 
                <li key={uuid()}><strong>{key}:</strong> {props.response.headers[key]}</li>
            )}
        </ul>

        <div className="text-lighter fw-semibold">BODY</div>
        <div className="mx-2 request-detail-response-body-container">
          <RenderBody data={props.response} />
        </div>
    </>
    
  );
}

export default ResponseTab;