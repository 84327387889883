import React from 'react';
import { observer } from 'mobx-react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ErrorDialog = observer((props) => {
  const clearError = () => {
    const clearOptions = {
        onLastClear : props.store.SessionStore.currentError.onLastClear,
        onClear : props.store.SessionStore.currentError.onClear
    };
    props.store.SessionStore.clearError(clearOptions);

  }

  return (
    <Modal show={props.store.SessionStore.showErrorDialog}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={clearError}
    >
      <Modal.Body className="text-center">
        <i className="ti ti-alert-circle mb-4 text-danger error-icon"></i>
        <p>
          {props.store.SessionStore.currentError?.message}
        </p>
        <Button variant="secondary" className="mt-4" onClick={clearError}>
            Close
        </Button>
      </Modal.Body>
    </Modal>
  );
});

export default ErrorDialog;



