import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from '../utils/constants';
import {Helmet} from "react-helmet";
import {replacePathPlaceholders} from '../utils/helpers';

const SignIn = observer((props) => {
  const navigate = useNavigate();

  useEffect(() => {
    
    async function getUser() {
      // check to see if the session param is present...
      const queryParameters = new URLSearchParams(window.location.search);
      if(queryParameters.get("session")){
        // the user just signed in...get their info..
        const loginResult = await props.store.SessionStore.isAuthenticated();

        if(loginResult && loginResult.authenticated){
          /*
          if(!loginResult.redirectTo){
            navigate(replacePathPlaceholders(APP_ROUTES.TRAFFIC, {bucketSid: props.store.SessionStore.bucket.sid}));
          }else{
            navigate(loginResult.redirectTo);
          }          
          */
        }else{
          navigate(APP_ROUTES.SIGN_IN);
        }
      }    
    }

    // now call the get User Function...
    getUser();

  }, [navigate, props.store.SessionStore]);


  useEffect(() => {
    
    if(props.store.SessionStore.phase === 'ready'){
      navigate(replacePathPlaceholders(APP_ROUTES.TRAFFIC, {bucketSid: props.store.SessionStore.bucket.sid}));
    }
  
  // eslint-disable-next-line
  }, [props.store.SessionStore.phase]);

    return (
      <>
      {props.store.SessionStore.phase !== "preparing" ? (
        <>
          <Helmet>
            <script src="https://cdn.outseta.com/outseta.min.js" data-options="o_login_options"></script>
          </Helmet>
          <div className="text-center">
            <img src="/assets/images/apitraffic-logo.svg" alt="ApiTraffic Logo" width="300"/>
          </div>
          <div id="login-embed"></div>
          <p className="text-center">
            <span>Are you a new customer? <a href="https://apitraffic.io/pricing">Start a free trial</a></span>
          </p>
        </>
      ) : (
      <>
        <div className="text-center">
        <h5 className="card-title mt-4">Loading Account Data</h5>
          <p className="card-text mb-4">
            <span className="spinner-grow text-dark" role="status">
              <span className="visually-hidden">Loading...</span>
            </span>
          </p>
        </div>
      </>
      )}
    </>
  );

});

export default SignIn;
