import React from 'react';
import Button from 'react-bootstrap/Button';

const NewWorkflowButton = (props) => {
  return (
    <Button variant="success"  onClick={()=>props.setShowNewFromScratch(true)} >Add Workflow</Button> 
  );
}
// eslint-disable-next-line
{/* 
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Add Workflow
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item  onClick={()=>props.setShowNewFromScratch(true)} >Build From Scratch</Dropdown.Item>
       
        <Dropdown.Item  onClick={()=>props.handleShowBuilder("newFromTemplate")} >Use Template</Dropdown.Item>
        <Dropdown.Item  onClick={()=>props.handleShowBuilder("attachExisting")} >Attach Existing</Dropdown.Item>
        
      </Dropdown.Menu>
    </Dropdown>
    */}

export default NewWorkflowButton;