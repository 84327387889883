import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";

import { APP_ROUTES } from '../../utils/constants';

const Preparing = observer((props) => {
  const navigate = useNavigate();

  useEffect(() => {
    
    async function checkAuthentication() {
        // the user just signed in...get their info..
        const loginResult = await props.store.SessionStore.isAuthenticated();
    
        if(loginResult && loginResult.authenticated){
          if(loginResult.redirectTo){
            navigate(loginResult.redirectTo);
          }          
        }else{
          navigate(APP_ROUTES.SIGN_IN);
        }
    }
    
    // now call the get User Function...
    checkAuthentication();

  // eslint-disable-next-line
  }, [navigate]);

    return (
          <div className="container-fluid">
            <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-4">
                <div className="card">
                <div className="card-body text-center">
                  <h5 className="card-title mt-4">Loading Account Data</h5>
                  <p className="card-text mb-4">
                    <span className="spinner-grow text-dark" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  </p>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
});

export default Preparing;