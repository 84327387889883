import React from 'react';
import { observer } from 'mobx-react';
import { Navigate } from "react-router-dom";

import { APP_ROUTES } from '../utils/constants';
import { replacePathPlaceholders } from '../utils/helpers';
import DefaultDashboard from '../Components/Dashboard/Default';
import GettingStarted from './GettingStarted';

const BucketDashboard = observer((props) => {

  const noRecordsPath = replacePathPlaceholders(APP_ROUTES.TRAFFIC, {bucketSid: props.store.SessionStore.getActiveBucketSid()});

  // if the bucket is not yet connected, we need to show the getting started page to get them going with their SDK setup...
  if(!props.store.SessionStore.bucket?.appIsConnected){
    return (<GettingStarted {...props} />);
  }

  return (<DefaultDashboard {...props} NoRecordsFound={<Navigate to={noRecordsPath} />} />);

});

export default BucketDashboard;