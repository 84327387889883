import React from 'react';

import Activepieces from './Activepieces';
import NewWorkflowFromScratch from './NewFromScratch';

const WorkflowsMain = (props) => {

  return (
    <>
      {props.builderView === "newFromScratch" && <NewWorkflowFromScratch {...props} />}
      {props.builderView === "newFromTemplate" && <>{props.builderView}</>}
      {props.builderView === "attachExisting" && <>{props.builderView}</>}
      {props.builderView === "runs" && <Activepieces {...props} />}
      {props.builderView === "builder" && <Activepieces {...props} />}
    </>
  );
}

export default WorkflowsMain;