// Import stores
import TrafficStore from './TrafficStore';
import SessionStore from './SessionStore';
import MetricsStore from './MetricsStore';
import ApiKeyStore from './ApiKeyStore';

// Combine stores
const store = {
    TrafficStore,
    SessionStore,
    MetricsStore,
    ApiKeyStore
};
TrafficStore.stores = store;


export default store;