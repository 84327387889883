import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { createStreamView, updateStreamView, deleteStreamView } from '../../lib/apiClient';
import { showToast } from '../../utils/helpers';
import { APP_ROUTES } from '../../utils/constants';
import { replacePathPlaceholders } from '../../utils/helpers';

const StreamViewDetails = observer((props) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);    
    const [selectedIcon, setSelectedIcon] = useState("search");
    
    const getFormValues = () =>{
       
        if(props.store.SessionStore.streamView){
            setSelectedIcon(props.store.SessionStore.streamView.icon);
            return {
                name: props.store.SessionStore.streamView.name,
                icon : props.store.SessionStore.streamView.icon,
            }
        }else{
            return {
                name: "",
                icon : "search"
            }
        }

}

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: async () => {
            return getFormValues()
        }
    });

    useEffect(() => {
        if(show){
            setSelectedIcon("search");
            reset(getFormValues());            
        }    
    // eslint-disable-next-line    
    }, [show, reset]);

    const firstRowIconArray = [
        {class: "ti-search", name:"search"},
        {class: "ti-star", name:"star"},
        {class: "ti-alert-triangle", name:"alert"},
        {class: "ti-circle-check", name:"check"}
    ];
    const secondRowIconArray = [
        {class: "ti-bolt", name:"bolt"},
        {class: "ti-bookmark", name:"bookmark"},
        {class: "ti-flag", name:"flag"},
        {class: "ti-heart", name:"heart"}
    ];

    const onSubmit = async (data, e) => {
        const params = {
            accountSid : props.store.SessionStore.accountSid,
            bucketSid : props.store.SessionStore.bucket.sid,
            name: data.name,
            icon: data.icon,
            criteria: props.store.TrafficStore.userDefinedCriteria
        };
        
        if(props.store.SessionStore.streamView){
            // editing
            params.streamViewSid = props.store.SessionStore.streamView.sid;

            const updateResult = await updateStreamView(params);

            if(updateResult.successful){
                await props.store.SessionStore.loadStreamViews();
                hideForm();
                showToast("Stream View Updated!");
            }
        }else{
            // creating
            const createResult = await createStreamView(params);
            if(createResult.successful){
                await props.store.SessionStore.loadStreamViews();
                await props.store.SessionStore.setAsActiveStreamView(createResult.data.sid);
                hideForm();
                showToast("Stream View Created!");
            }
        }
    };

    const handleDelete = async () => {
        const params = {
            accountSid : props.store.SessionStore.accountSid,
            bucketSid : props.store.SessionStore.bucket.sid,
            streamViewSid : props.store.SessionStore.streamView.sid
        };
        
        const deleteResult = await deleteStreamView(params);

        if(deleteResult.successful){
            hideForm();
            navigate(replacePathPlaceholders(APP_ROUTES.TRAFFIC, {bucketSid: props.store.SessionStore.getActiveBucketSid()}));
            await props.store.SessionStore.loadStreamViews();
            showToast("Stream View Deleted!");
        }

    };

    function hideForm(event) {
        event?.preventDefault();
        setShow(false);
    }

    const outputIconRow = (iconRow) => {
        return (
                <>
                {iconRow.map((icon, index) => {
                    return (
                            <div className="col-md mb-md-0 mb-2" key={icon.name}>
                                <div className={`form-check custom-option custom-option-image custom-option-image-radio text-center ${selectedIcon === icon.name ? 'bg-primary text-white' : ''}`}>
                                    <label className="form-check-label custom-option-content" htmlFor={icon.name}>
                                        <span className="custom-option-body">
                                            <i className={`m-1 tf-icons ti ${icon.class}`}></i>
                                        </span>
                                    </label>
                                        <input
                                            {...register("icon", { required: true})}
                                            name="icon"
                                            className="form-check-input"
                                            type="radio"
                                            value={icon.name}
                                            id={icon.name}
                                            defaultChecked={selectedIcon === icon.name}
                                            onClick={()=> setSelectedIcon(icon.name)}/>
                                    
                                </div>
                            </div>
                    );
                })}
                </>
        );
    };

  return (
    <>
    
        <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name*</label>
                    <input {...register("name", { required: true, maxLength: 50 })} type="text" className="form-control" id="name" placeholder="Stream View Name" aria-describedby="name" />
                    {errors.name?.type === 'required' && <div className="text-danger">Please enter a stream name</div>}
                    {errors.name?.type === 'maxLength' && <div className="text-danger">The stream name cannot be longer than 50 characters</div>}
                </div>
            </div>
            <div className="row">                
                <label htmlFor="defaultFormControlInput" className="form-label">Icon*</label>
                {outputIconRow(firstRowIconArray)}
            </div>
            <div className="row mt-2">
                {outputIconRow(secondRowIconArray)}
            </div>
            <div className="row mt-3">
                <div className="col-md d-flex justify-content-center align-items-center">
                    <button className="btn btn-primary" title={props.store.SessionStore.streamView ? 'Update View' : 'Create View'}>
                        {props.store.SessionStore.streamView ? 'Update' : 'Create'}
                    </button>        
                </div>                
            </div>
        </form>
        <div className="position-absolute bottom-0 end-0 p-2 m-4">
            <button onClick={handleDelete} className="btn btn-link text-danger" title="Delete View">Delete View</button>
        </div>
  </>
    
  );
});
export default StreamViewDetails;