import React from 'react';
import { observer } from 'mobx-react';
import { CopyBlock, nord, hybrid } from "react-code-blocks";

const Hapi = observer((props) => {


    let install = `npm install @apitraffic/express`;   
    let configure = `
// require/import the ApiTraffic SDK in your main server file...
const apiTraffic = require('@apitraffic/express');

// register ApiTraffic middleware with the express server...
app.use(apiTraffic({
    token : "${props.apiToken}", 
    bucket : "${props.store.SessionStore.getBucketSidFromUrl()}" 
}));`;   

  return (
    <>
        <h3 className="text-primary">Step {props.step}: Integrate into application</h3>
        <h4>Install SDK</h4>
        <p className="card-text">Use npm (or similar) to install apitraffic-express.</p>
        <CopyBlock text={install} language='javascript' theme={nord} />

        <h4 className="mt-4">Configure Application</h4>
        <p className="card-text">In the file that sets up your Hapi server, include the following code to hook in the ApiTraffic SDK.</p>
        <CopyBlock text={configure} language='javascript' theme={hybrid} />
    </>
  );
});

export default Hapi;