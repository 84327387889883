import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import Hapi from '../Components/GettingStarted/Hapi';
import Express from '../Components/GettingStarted/Express';
import Koa from '../Components/GettingStarted/Koa';
import Fastify from '../Components/GettingStarted/Fastify';
import Proxy from '../Components/GettingStarted/Proxy';
import Other from '../Components/GettingStarted/Other';
import TokenStep from '../Components/GettingStarted/TokenStep';

const BucketSettings = observer((props) => {
    const [sdk, setSdk] = useState('');
    const [apiToken, setApiToken] = useState();
    const [tokenStatus, setTokenStatus] = useState(null);
    const [, setTokens] = useState([]);
    const [appIsConnected, ] = useState(props.store.SessionStore.bucket?.appIsConnected);
    //const [appPreviouslyConnected] = useState(props.store.SessionStore.bucket?.appIsConnected);
    let intervalId = null;

    useEffect(() => {

        listApiTokens();
        setTokens(props.store.ApiKeyStore.getEnvironmentTokens(props.store.SessionStore.environment.sid, "ingest"));
      
      // eslint-disable-next-line
      }, [props.store.ApiKeyStore]);

    
    useEffect(() => {

        // Start the interval to call the function every second
        clearInterval(intervalId);

        //intervalId = setInterval(getBucketData, 3000);
    
    // eslint-disable-next-line
    }, [apiToken]);

    const handleSdkChange = (selectedSdk) => {
        // Update the state to the new value of the select input
        setSdk(selectedSdk);        
    };

    async function listApiTokens(prepend=true) {
        await props.store.ApiKeyStore.loadApiTokens();
    }

    const passTokenToCodeSample = ()=>{
        if(apiToken){
            return apiToken.token;
        }else{
            return "Api Token Placeholder";
        }
    }

    const DisplaySdkOption = (options)=>{

        return (
        <div class="col-md-4 mb-6 sdk-tile">
            <div class="form-check custom-option custom-option-icon">
            <label class="form-check-label custom-option-content" for={`sdk_${options.key}`}>
                <span class="custom-option-body">
                <img src={options.icon} style={{"max-height": "85px", "max-width" : "125px"}} alt={`${options.name} Logo`}/>
                <span class="custom-option-title">{options.name}</span>
                </span>
                <input name="sdk" class="form-check-input" type="radio" value={options.key} id={`sdk_${options.key}`} onClick={()=>{handleSdkChange(options.key)}} />
            </label>
            </div>
        </div>
       )
    }
    
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card" >
                    <div className="card-body">
                        <h2 className="card-title">Getting Started</h2>
                        <h6 className="card-subtitle mb-2 text-muted">Follow the steps below to connect your app to this bucket in a matter of minutes.</h6>
                        <p className="card-text"></p>
                        
                        <hr/>

                        <h3 className="text-primary">Step 1: Node SDK Selection</h3>
                        <p className="card-text">Select the framework that your application is written with.</p>

                        <div class="row">
                            {DisplaySdkOption({
                                                key: "express",
                                                name: "ExpressJS",
                                                icon: "/assets/images/sdks/express.png"
                                            })}
                            {DisplaySdkOption({
                                                key: "fastify",
                                                name: "Fastify",
                                                icon: "/assets/images/sdks/fastify.png"
                                            })}
                            {DisplaySdkOption({
                                                key: "hapi",
                                                name: "Hapi",
                                                icon: "/assets/images/sdks/hapi.png"
                                            })}
                            {DisplaySdkOption({
                                                key: "koa",
                                                name: "Koa",
                                                icon: "/assets/images/sdks/koa.png"
                                            })}
                        </div>

                        <hr/>

                        { sdk === 'hapi' ? (<Hapi {...props} apiToken={passTokenToCodeSample()} step="2" />) : (<></>)}
                        { sdk === 'fastify' ? (<Fastify {...props} apiToken={passTokenToCodeSample()} step="2" />) : (<></>)}
                        { sdk === 'express' ? (<Express {...props} apiToken={passTokenToCodeSample()} step="2" />) : (<></>)}
                        { sdk === 'koa' ? (<Koa {...props} apiToken={passTokenToCodeSample()} step="2" />) : (<></>)}
                        { sdk === 'proxy' ? (<Proxy {...props} apiToken={passTokenToCodeSample()} step="2" />) : (<></>)}
                        { sdk === 'other' ? (<Other {...props} apiToken={passTokenToCodeSample()}  step="2"/>) : (<></>)}

                        { sdk !== '' && sdk !== 'other' ? (
                            <TokenStep {...props} 
                                        setApiToken={setApiToken} 
                                        apiToken={apiToken} 
                                        setTokenStatus={setTokenStatus}
                                        tokenStatus={tokenStatus}
                                        step="3" />
                        ) : (
                            <></>
                        )}


                        { (apiToken || tokenStatus === "existing" ) ? (
                            <>
                                <h3 className="text-primary mt-4">Step 4: Run your app</h3>
                                <p className="card-text">Now run your application and make a few page requests. Once we start to receive data the message below will update automatically.</p>
                                { !appIsConnected ? (
                                    <p>
                                        <div className="alert alert-secondary" role="alert">
                                            <div className="spinner-grow spinner-grow-sm text-danger" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                                
                                            </div> Listening for data from your application.
                                        </div>
                                    </p>
                                ) : (
                                    <p>
                                        <div className="alert alert-success" role="alert">
                                            <i className="menu-icon tf-icons ti ti-circle-check text-success pe-2 mt-2"></i> Awesome, we have received some data! Click on All Traffic in the left menu to see your request.
                                        </div>
                                    </p>
                                )}
                            </>

                            ) : (
                                <></>
                            )}

                    </div>
                </div>

            </div>
        </div>
    );
});

export default BucketSettings;



