import React from 'react';
import { observer } from 'mobx-react';
import NewBucket from './NewBucket';
import NoSearchResults from './NoSearchResults';

const NoRequestsFound = observer(({props}) => {
    return (
        <>
            {props.store.TrafficStore.hasUserDefinedCriteria ? (<NoSearchResults props={props} />) : (<NewBucket props={props} />)}
        </>
    );
});

export default NoRequestsFound;



