import React from 'react';
import { observer } from 'mobx-react';
import {GATEWAY_DOMAIN} from '../../utils/constants';

const NewBucket = observer((props) => {
    let domain = `${props.store.SessionStore.getActiveBucketSid()}.${GATEWAY_DOMAIN}`;
    let curl = `https://www-boredapi-com-${domain}/api/activity`;   
    
    return (
        <>
            <div className="row">      
                <div className="col-lg-12 col-xl-6 text-center">
                    {/* https://storyset.com/illustration/application-programming-interface/amico */}
                    <img src="/assets/images/api-image.svg" alt="Person saying ok with their hands and a large checkmark illustration" height="300"/>
                </div>                
                <div className="col-lg-12 col-xl-6 d-flex align-items-center">
                    <div className="flex-column">
                        <h3>Let's Get Started!</h3>
                        <p>This bucket does not have any requests. Before we make your first request let's learn how to proxy requests through ApiTraffic. </p>
                    </div>  
                </div>  
            </div>

            <div className="row">                     
                <div className="col-lg-12 col-xl-6 d-flex align-items-center">
                    <div className="flex-column">
                        <h3>Proxy Options</h3>
                        <p>ApiTraffic offers two options for proxying and capturing traffic:</p>
                        <p>
                            <strong>Gateway URL</strong><br/>
                            The Gateway URL is a specially formatted URL that contains the target API information in the URL. The nice thing about Gateway URLs is only the host portion of the URL changes, the path, headers, request data, query string parameters, etc are all unchanged.
                        </p>
                        <p>
                            <strong>Domain Aliasing</strong><br/>
                            Direct all your traffic through ApiTraffic to fully realize the logging and security capabilties. 
                        </p>
                    </div>  
                </div>  
                <div className="col-lg-12 col-xl-6 text-center">
                    {/* https://storyset.com/illustration/on-the-way/amico */}
                    <img src="/assets/images/proxy-choice.svg" alt="Person saying ok with their hands and a large checkmark illustration" height="300"/>
                </div> 
            </div>

            <div className="row">      
                <div className="col-lg-12 col-xl-6 text-center">
                    {/* https://storyset.com/illustration/application-programming-interface/amico */}
                    <img src="/assets/images/man-typing.svg" alt="Person saying ok with their hands and a large checkmark illustration" height="300"/>
                </div>                
                <div className="col-lg-12 col-xl-6 d-flex align-items-center">
                    <div className="flex-column">
                        <h3>Send Sample Request</h3>
                        <p>Follow these steps to send proxy your first reqeust in a matter of seconds.</p>
                        <ul>
                            <li>For this example we are goign to proxy the request to an open API endpoint that returns a random activity to complete if you are bored.</li>
                            <li>First, click <a href="https://www.boredapi.com/api/activity" target="_blank" rel="noreferrer">https://www.boredapi.com/api/activity</a> to visit the endpoint without proxying.</li>
                            <li>Now, click <a href={curl} target="_blank" rel="noreferrer">{curl}</a> to visit the endpoint WITH proxying.</li>
                        </ul>
                    </div>  
                </div>  
            </div>
{/*
            <h4>Proxy Options</h4>
            <p>API Traffic offers two options for proxying traffic through the gateway, Gateway URL and domain aliasing.</p>

            <h5>Gateway URL</h5>
            <p>
                The gateway url method is the simplest way to get started, but is not a method that would be used if you want to proxy all your traffic through the system.
                The Gateway URL is a specially formatted URL that contains the target API information in the URL. The nice thing about Gateway URLs is only the host portion of the URL changes, the path, headers, request data, query string parameters, etc are all unchanged.
            </p>
            <p>
                The structure of the Gateway URL is:
                <ul>
                    <li>Grab the scheme and the host of the target API. For example: {sampleApiHost}.</li>
                    <li>The dots are replaced with dashes. If there are already dashes, just double them. {sampleApiHostDashed}</li>
                    <li>In order to send through the right API Traffic bucket, add a dash and then the bucket id. {sampleApiHostDashed}-{props.store.SessionStore.getActiveBucketSid()}</li>
                    <li>Lastly, add a period and the domain of apitraffic.io. {sampleApiHostDashed}-{props.store.SessionStore.bucket?.sid}.apitraffic.io</li>
                </ul>
                You have just created your first Gateway URL and are ready to start sending requests to the gateway for capture. We offer a URL Helper in the left menu to make generating these URLs a snap.
            </p>
            <h6>Use Cases</h6>
            <p>
                The Gateway URL method of sending requests to the gateway is perfect for these situations:
                <ul>
                    <li><strong>Testing and debugging:</strong> Randomingly, testing and debugging your API. Since you can easily geneate the URLs and swap out for the regular hostname, it is perfect for catpuring some requests. </li>
                    <li><strong>Outbound API Requests:</strong> If your application consumes other APIs, you can send those requests through the gateway to ensure you have full visiblity into what is happening in realtime.</li>
                </ul>
            </p>
           
            <h5>Domain Aliasing</h5>
*/}
        </>
    );
});

export default NewBucket;



