import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { POST_HOG_HOST, POST_HOG_KEY } from './utils/constants';
import { PostHogProvider} from 'posthog-js/react'

const postHogOptions = {
  api_host: POST_HOG_HOST,
  opt_in_site_apps: true
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  
      <PostHogProvider apiKey={POST_HOG_KEY} options={postHogOptions}>
          <App />
      </PostHogProvider>

  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
