import React from 'react';
import { observer } from 'mobx-react';

const Avatar = observer((props) => {

    function buildInitials(){
        if(props.store.SessionStore.user?.firstName && props.store.SessionStore.user?.lastName){
            return `${props.store.SessionStore.user?.firstName.charAt(0).toUpperCase()}${props.store.SessionStore.user?.lastName.charAt(0).toUpperCase()}`;
        }else{
            return '';
        }       
    }



  return (
    <>
    <div className="avatar-wrapper">
        <div className="avatar avatar-sm">
        <span className="avatar-initial rounded-circle bg-label-primary">{buildInitials()}</span>
        </div>
    </div>
    </>
  );
});

export default Avatar;