import React from 'react';
import { Navbar, Container, Button } from 'react-bootstrap';

const BuilderHeader = (props) => {
    
  return (
    <>
        <Container fluid>
            <Navbar expand="lg">
                <Container fluid>
                    <div className="d-flex justify-content-between w-100 align-items-center">
                        {/* Title on the left */}
                        <Navbar.Brand>Workflow Builder</Navbar.Brand>

                        {/* Centered content */}
                        <div className="mx-auto text-center">
                        {props.store.SessionStore.environment.name} / {props.workflow.name}
                        </div>

                        {/* Toolbar on the right */}
                        <div className="d-flex">
                            {/* 
                            <ButtonGroup aria-label="Basic example" className="pe-1">
                                <Button variant="primary" title="View past runs" onClick={()=>handleClose()} ><i className="ti ti-list-search pe-2"></i> Runs</Button>
                                <Button variant="primary" title="View previously published versions" onClick={()=>handleClose()} ><i className="ti ti-history pe-2"></i> Versions</Button>
                                <Dropdown as={ButtonGroup}>
                                <Button variant="primary" title="Publish current version" onClick={()=>handleClose()} ><i className="ti ti-stack-push pe-2"></i> Publish</Button>

                                    <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Workflow Associations</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Action 2</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Action 3</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ButtonGroup>
                            */}

                            <Button variant="secondary" title="Close builder" onClick={()=>props.close()} ><i className="ti ti-x"></i></Button>
                        </div>
                    </div>
                </Container>
            </Navbar>
        </Container>
    </>
    
  );
}

export default BuilderHeader;