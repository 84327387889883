import { toast } from 'react-toastify';

/**
 * Get query params from a string
 * @export
 * @param {name} string
 * @param {url} string
 */
export function getParam(name, url) {
  name = name.replace(/\[\]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  let results = regex.exec(url);

  if(!results) {
    return null;
  }

  if(!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Get URL segment
 * @export
 * @param {number} segment
 */
export function getURLSegment(segment) {

  const pathname = window.location.pathname;

  let pathArray = pathname.split('/');

  if (pathArray[0] === '') {
    pathArray.splice(0, 1);
  }

  // clear


  if(segment > 0){
    return pathArray[segment - 1];
  }else{
    return pathArray;
  }
  
}


/**
* Check if sessionStorage is available
* @export
*/
export function isLocalStorageSupported() {
  const testKey = 'test';
  const storage = window.sessionStorage;

  try {
    storage.setItem(testKey, '1');
    storage.removeItem(testKey);
    return true;
  }
  catch (error) {
    return false;
  }
}

/**
* Get cookie by name
* @export
* @param {name} string
*/
export function getCookie(name) {
  const match = document.cookie.match(new RegExp(name + '=([^;]+)'));

  if(match) {
    return match[1]
  }
}

/**
* Delete cookie by name
* @export
* @param {name} string
*/
export function deleteCookie(name) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/**
* Replaces all the placeholders in the path
* @export
* @param {name} string
*/
export function replacePathPlaceholders(path, placeholders) {
  let newPath = path;
  Object.keys(placeholders).map((key, index) =>{
    newPath = newPath.replaceAll(`:${key}`, placeholders[key]);
    return newPath;
  });
return newPath;   
}

/**
* Takes a string and converts it to title case.
* @export
* @param {name} string
*/
export function toTitleCase(string) {
  // Step 1: Replace dashes with spaces
  let str = string.replace(/-/g, ' ');

  // Step 2: Convert to title case
  str = str.split(' ').map(word => {
    // Capitalize the first letter and lowercase the rest of the word
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');

  return str;
}

/**
* Formats a number into something that is readable.
* @export
* @param {name} string
*/
export function formatNumber(number) {
  return new Intl.NumberFormat('en-US').format(number);
}

/**
* Replaces all the placeholders in the path
* @export
* @param {text} string The text to check.
* @param {maxLength} numeric The text to check.
* @param {append} string If anything should be appended if the string is too long. Default is "..."
*/
export function limitTextDisplay(text, maxLength, appendString="...") {
  if(text && text.length > maxLength){
    const trimmedString = text.substring(0, maxLength);
    if(appendString.length > 0){
      return trimmedString + appendString;
    }
    return trimmedString;
  }else{
    return text;
  }
}

export function showToast(message){
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    });
}

