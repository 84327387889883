import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import StreamViewOptions from './Request/StreamViewOptions';
import Filter from './FilterElement';
import { Button } from "react-bootstrap";

const TrafficSearch = observer((props) => {
  //const [useEditor, setUseEditor] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [filters, setFilters] = useState(props.store.TrafficStore.userDefinedCriteria);
  const [clearFilter, setClearFilter] = useState(false);
  const filterElementRef = useRef();
  const queryButtonRef = useRef(null);


  const isFirstRender = useRef(true);

  useEffect(() => {
    
    // We do not want to run this on first render as things will not be all setup yet...
    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false;
      return;
    }

    if(!props.store.TrafficStore.userDefinedCriteria.length){
      handleClearingFilters();
    }

    setFilters(props.store.TrafficStore.userDefinedCriteria);

    // eslint-disable-next-line
  }, [props.store.TrafficStore.userDefinedCriteria]);

  useEffect(() => {
    updateFieldContexts("request_header", props.store.TrafficStore.filterContextProperties?.requestHeaders);
    updateFieldContexts("response_header", props.store.TrafficStore.filterContextProperties?.responseHeaders);
    updateFieldContexts("tag", props.store.TrafficStore.filterContextProperties?.tags);
    
    // eslint-disable-next-line
  }, [props.store.TrafficStore.filterContextProperties]);

  const onSubmit = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("criteria", filters);  
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState(null, '', newUrl);
    //navigate(newUrl);
    await props.store.TrafficStore.listTraffic({prepend: false, append: false, from: null});
    setHasChanges(false);
  }

  const updateFieldContexts = async (field, properties) => {
    // update all the context
    if(Array.isArray(properties)){
      const keyArray = [];
      // get all the keys...
      properties.forEach((item, index) => {
        if(item.key?.length){
          keyArray.push(item.key);
        }        
      });

      filterElementRef.current.updateContextArray(field, keyArray);
    }else{
      filterElementRef.current.updateContextArray(field, []);
    }
    
  };

  const handleFiltersChange = async (newFilters) => {
    
    // This only needs to be done if there is an actual change...
    if(filters !== newFilters){
      console.log(`>>>> handleFiltersChange: Old: ${filters} New: ${newFilters}`);
      props.store.TrafficStore.addCriteria(newFilters);        
      setFilters(newFilters);

      // if the last filter was removed, just run refresh the search..
      if(!newFilters.length){
        setHasChanges(false);
        // clear the URL...
        window.history.pushState(null, '', window.location.pathname);
        // clear from...
        props.store.TrafficStore.from = null;

        await props.store.TrafficStore.listTraffic({prepend: false, append: false, from: null});
      
      }else{
        /*
        Decided not to have the button show up...
        setHasChanges(newFilters !== filters);
        if (queryButtonRef.current) {
          console.log(queryButtonRef);
          queryButtonRef.current.focus();
        }
          */
        await props.store.TrafficStore.listTraffic({prepend: false, append: false, from: null});
      }
    }
    
  };

  const handleClearingFilters = () => {
    setClearFilter(true);       
    setTimeout(()=> setClearFilter(false), 100);
  };

  const fields = [
    "method",
    "status_code",
    "url_path",
    "duration",
    //"has_errors",
    "request_header",
    "request_size",
    "response_header",    
    "response_size",
    "host",
    "url",
    "query_param",
    //"path_param",
    //"request_type",
    //"service_version",
    "tag"
  ];

  const string_operators = ["==", "!=", "like"];
  const string_operators_with_values = ["==", "!="];
  const number_operators = ["==", ">", "<", "!=", ">=", "<="];

  const filterAutoComplete = {
    method: {
      name : "Method",
      type: "string",
      operators: string_operators_with_values,
      values: [
        "GET",
        "POST",
        "PUT",
        "PATCH",
        "DELETE",
        "OPTIONS",
        "HEAD",
        "CONNECT",
        "TRACE",
      ]
    },
    status_code: {
      name : "Status Code",
      type: "number",
      operators: number_operators,
      values: [
        200, 201, 202, 203, 204, 300, 301, 302, 400, 401, 402, 403, 404, 405,
        406, 500, 501, 502, 503, 504,
      ]
    },
    /*
    request_type: {
      name : "Request Type",
      type: "string",
      operators: string_operators_with_values,
      values: ["Outgoing", "Incoming"]
    },
  */
    tag: {
      name : "Tag",
      type: "string",
      context: ["customerID"],
      operators: string_operators
    },
    duration: {
      name : "Duration",
      type: "number",
      operators: number_operators
    },
    response_size: {
      name : "Response Size",
      type: "number",
      operators: number_operators
    },
    request_size: {
      name : "Request Size",
      type: "number",
      operators: number_operators
    },
    url_path: {
      name : "URL Path",
      type: "string",
      operators: string_operators
    },
    request_header: {
      name : "Request Header",
      type: "string",
      context: ["customerID"],
      operators: string_operators
    },
    response_header: {
      name : "Response Header",
      type: "string",
      context: ["customerID"],
      operators: string_operators
    },
    host: {
      name : "Host",
      type: "string",
      operators: string_operators
    },
    url: {
      name : "URL",
      type: "string",
      operators: string_operators,
    },
    query_param: {
      name : "Query Param",
      type: "string",
      operators: string_operators
    }
  };

  return (
    <>
       
    <div className="row">
      <div className="action-btns d-flex justify-content-end">
     {/* 
        <label className="switch">
          <input type="checkbox" className="switch-input" checked={useEditor} onChange={() => setUseEditor(!useEditor)} />
          <span className="switch-toggle-slider">
            <span className="switch-on">
              <i className="ti ti-check"></i>
            </span>
            <span className="switch-off">
              <i className="ti ti-x"></i>
            </span>
          </span>
          <span className="switch-label">Use editor</span>
        </label>*/}
          
          {hasChanges && 
            <Button variant="primary" size="sm" className="mt-2" title="Search" id="request-search-button" onClick={onSubmit} ref={queryButtonRef}>
              <i className="tf-icons ti ti-search"></i> Run Query
            </Button>}
        
      </div>
    </div>
    
    <div className="mb-1 border-bottom">
        <form className="needs-validation px-2">
          <Filter ref={filterElementRef} fields={fields} 
              initialFilterAutoComplete={filterAutoComplete}
              defaultValue={filters}
              allowOr={false}
              isRawMode={false}
              onFiltersChange={handleFiltersChange}
              clearFilter = {clearFilter} />

          {props.store.TrafficStore.hasError ? (
            <div className="d-flex justify-content-between align-items-center mt-2 text-danger">
              {props.store.TrafficStore.errorMessage}
            </div>
            )
          :(<></>)
          }
        </form>
    </div>
    {props?.view !== 'captures' ? (<StreamViewOptions {...props} />) : (<></>)}
    </>
  );
});

export default TrafficSearch;