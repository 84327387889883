import React from 'react';
import { observer } from 'mobx-react';

import { APP_ROUTES } from '../../utils/constants';
import { replacePathPlaceholders } from '../../utils/helpers';

const WebProxy = observer((props) => {

  return (
    <>
      <h3 className="text-primary">Step 2: Generate Proxy URL</h3>
        <button type="button" className="btn btn-primary" id="url-helper-menu-item" href={replacePathPlaceholders(APP_ROUTES.TRAFFIC, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} data-bs-toggle="modal" data-bs-target="#urlHelper">
          <i className="menu-icon tf-icons ti ti-wand"></i>
          <div data-i18n="URL Helper">URL Helper</div>
        </button>



        <div className="alert alert-secondary mt-4" role="alert">
          NOTE: The web proxy integration method will not track outgoing traffic unless the traffic also uses a proxy URL. 
        </div>
    </>
  );
});

export default WebProxy;