import React, { useState } from 'react';
import RequestSummary from './RequestSummary';
import RequestDetail from './RequestDetail';
import uuid from 'react-uuid';

const RequestTableRow = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [requestData, setRequestData] = useState(null);
  const [loading, setLoading] = useState(null);

  function CustomToggle({ children, eventKey }) {

      const toggleRequest = () => {
        
        if(expanded){
          setExpanded(false);
        }else{
          loadRequestExtraData();
        }
      };
  


    return (
      <>
      <span className="pe-2">
      {!loading ? (
        <i className={`tf-icons ti fs-small cursor-pointer ${expanded ? 'ti-circle-minus request-details-collapes' : 'ti-circle-plus request-details-expand'}`} onClick={toggleRequest} title={expanded ? 'Collapse request details' : 'View full request details'}></i>
      ) : (
        <i className="tf-icons ti fs-small ti-loader-2 fa-spin" title="Loading request details"></i>
      )}
      </span>
     </>
    );
  }

  // Define a function to handle the action in RequestSummary
  const loadRequestExtraData = async () => {
    setLoading(true);
    // if the data is on there, fetch it...
    if(requestData === null){
      const requestData = await props.store.TrafficStore.getRequest(props.store.SessionStore.getAccountSid(), props.store.SessionStore.getBucketSidFromUrl(), props.request.sid);
      if(requestData.successful){
        setRequestData(requestData.data);
      }
    }
    
    setLoading(false);
    setExpanded(true);

  };


  return (
    <>      
        <li className="pt-2 request-card">
            <div className="card border border-traffic collapsed">
                <div className="card-body p-0">
                  <div className="p-2">
                    <RequestSummary key={uuid()}  {...props} request={props.request} toggle={<CustomToggle eventKey={props.request.sid} />} />
                  </div>  
                  {expanded && requestData !== null ? (
                    <RequestDetail key={uuid()} request={requestData} />
                  ) : (<></>)}
                </div>
            </div>
        </li>
    </>
  );
}

export default RequestTableRow;