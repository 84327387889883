import { KB_URL } from '../../utils/constants';
const NoCaptures = ({props}) => {
    return (
      <div className="row">
        {props.store.TrafficStore.hasError ? (
            <>
            <div className="col-lg-12 col-xl-6 text-center">
                    {/* https://storyset.com/illustration/404-error-with-a-tired-person/amico#54B274FF&hide=Shadows,Text,Numbers&hide=complete */}
                    <img src="/assets/images/error.svg" alt="Person looking at computer that says no data." height="400"/>            
                </div>
                <div className="col-lg-12 col-xl-6 d-flex align-items-center text-center">
                    <div className="text-center flex-column">
                        <h3>Search Criteria Error</h3>
                        <p className="text-danger">{props.store.TrafficStore.errorMessage}</p>
                        <p>Adjust your search string and try again.</p>
                        <p><a href={`${KB_URL}/articles/jW7r4J9q/searching-requests`} target="_blank" rel="noreferrer">Check our docs</a>, if you need help constructing your search string.</p>
                    </div>  
                </div>    
            </>
        ) : (
            <>
                <div className="col-lg-12 col-xl-6 text-center">
                    {/* https://storyset.com/illustration/no-data/amico#54B274FF&hide=&hide=complete */}
                    <img src="/assets/images/no-search-results.svg" alt="Person looking at computer that says no data." height="400"/>            
                </div>
                <div className="col-lg-12 col-xl-6 d-flex align-items-center text-center">
                    <div className="text-center flex-column">
                        <h3>No Matching Requests Found</h3>
                        <p>There were no requests found using the search criteria provided.</p>
                        <p>Review your search string and try again.</p>
                        <p><a href={`${KB_URL}/articles/jW7r4J9q/searching-requests`} target="_blank" rel="noreferrer">Check our docs</a>, if you need help constructing your search string.</p>
                    </div>  
                </div>    
            </>
        )}
    </div>         
    );
  }
  
  export default NoCaptures;