import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";

import RequestTable from './Request/Table';
import LoadingRequests from './Request/LoadingRequests';

const TrafficLog = observer((props) => {
  const [endMessage] = useState("");
  const [loadingMore, setLoadingMore] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    props.store.TrafficStore.reset();
  // eslint-disable-next-line
  }, [navigate]);

  const loadNextBatch = async () => {
    setLoadingMore("Loading more requests...");
    await props.store.TrafficStore.listTraffic({append: true});
  };

  return (
    <>
      {props.store.TrafficStore.loadingTraffic === true ? (<LoadingRequests />) : (
        <>
          <div className="p-0">
            {props.store.TrafficStore.requests.length > 0 ? (
              <InfiniteScroll
                  dataLength={props.store.TrafficStore.requests.length}
                  next={loadNextBatch}
                  hasMore={props.store.TrafficStore.hasMore}
                  loader={loadingMore}
                  endMessage={endMessage}>
                <RequestTable requestData={props.store.TrafficStore.requests} props={props} />
              </InfiniteScroll>
            ) : (
              <>
              {props.NoRecordsFound}
              </>
            )}
          </div>
        </>
      )}

    </>
  );
});

export default TrafficLog;



