import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react'
import { observer } from 'mobx-react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { APP_ROUTES } from './utils/constants';
import { getURLSegment } from './utils/helpers';
import store from './stores/';

import Dashboard from './Pages/Dashboard';
import SignIn from './Pages/SignIn';
import SignOut from './Pages/SignOut';
import SignUp from './Pages/SignUp';
import Traffic from './Pages/Traffic';
import Shared from './Pages/Shared';
import GettingStarted from './Pages/GettingStarted';
import BucketSettings from './Pages/BucketSettings';
import ApiTokens from './Pages/ApiTokens';
import AppLayout from './Layouts/AppLayout';
import MiscLayout from './Layouts/MiscLayout';
import AuthLayout from './Layouts/AuthLayout';
import Account from './Pages/Account';
import Environments from './Pages/Environments';
import OutsetaProvider from './Providers/OutsetaProvider';
import BucketWorkFlows from './Pages/BucketWorkFlows';
import ErrorDialog from './Components/Common/ErrorDialog';

const stores = {store}

function NavigationListener() {
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
      posthog.capture('$pageview');
  }, [location, posthog]);

 


  return null; // This component does not render anything
}


const App = observer(() => {

  const posthog = usePostHog();
 

    if(getURLSegment(1) !== 'signin' && getURLSegment(1) !== 'signup'){

      useEffect(() => {
      
        async function checkAuthentication(token) {
          
          // the user just signed in...get their info..
            const loginResult = await store.SessionStore.isAuthenticated();

            if(loginResult && loginResult.authenticated){
              // Do nothing...
              // Identify sends an event, so you want may want to limit how often you call it
              posthog?.identify(store.SessionStore.user.sid, {
                email: store.SessionStore.user.email,
                accountSid: store.SessionStore.accountSid,
                name: `${store.SessionStore.user.firstName} ${store.SessionStore.user.lastName}`
              })
              //posthog?.group('company', user.company_id)



            }else{
              window.location.href = APP_ROUTES.SIGN_IN;
            }
        }
        
        // now call the get User Function...
        checkAuthentication();
    
      }, [posthog]);
    
    }

    return (
      <>
      <ToastContainer />
      <ErrorDialog {...stores}/>
      
        <BrowserRouter>
          <NavigationListener />
          <Routes>
              
              <Route path={APP_ROUTES.SIGN_UP} exact element={<AuthLayout {...stores}><SignUp {...stores} /> </AuthLayout>} />
              <Route path={APP_ROUTES.SIGN_IN} element={<AuthLayout {...stores}><SignIn {...stores} /> </AuthLayout>} />         
              <Route path={APP_ROUTES.DEFAULT} element={<AuthLayout {...stores}><SignIn {...stores} /> </AuthLayout>} />
              <Route path={APP_ROUTES.SIGN_OUT} exact element={<MiscLayout  {...stores}> <SignOut {...stores} /> </MiscLayout>} />  
              {/* 
              <Route exact path={APP_ROUTES.DASHBOARD} element={
                                              <AppLayout {...stores}> 
                                                <Dashboard {...stores} /> 
                                              </AppLayout>
                                              } />
              
              */}
              
              <Route path={APP_ROUTES.BUCKET_BASE} element={
                <OutsetaProvider {...stores}>
                  <AppLayout {...stores}/>
                </OutsetaProvider>
                }>
                <Route path={APP_ROUTES.DASHBOARD} element={<Dashboard {...stores} />} /> 
                <Route path={APP_ROUTES.TRAFFIC} element={<Traffic {...stores} criteria="capture == false" view="all-traffic" />} /> 
                <Route path={APP_ROUTES.ERRORS} element={<Traffic {...stores} criteria="capture == false AND status_code >= 400" view="errors" /> } />
                <Route path={APP_ROUTES.CAPTURES} element={ <Traffic {...stores} criteria="capture == true" view="captures"  /> } />
                <Route path={APP_ROUTES.STREAM_VIEW} element={<Traffic {...stores} criteria="capture == false" />} /> 
                <Route path={APP_ROUTES.WORKFLOWS} element={<BucketWorkFlows {...stores} />} /> 
                <Route path={APP_ROUTES.BUCKET_TRAFFIC} element={<Traffic {...stores} /> } /> 
                <Route path={APP_ROUTES.COLLECTIONS} element={ <Traffic {...stores} /> } />            
                <Route path={APP_ROUTES.SHARED} element={ <Shared {...stores} /> } />
                <Route path={APP_ROUTES.SETTINGS} element={ <BucketSettings {...stores} /> } />  
                <Route path={APP_ROUTES.GETTING_STARTED} element={<GettingStarted {...stores} />} /> 
              </Route>

              <Route path={APP_ROUTES.ACCOUNT_BASE} element={
                <OutsetaProvider {...stores}>
                  <AppLayout {...stores}/>
                </OutsetaProvider>
                }>
                <Route path={APP_ROUTES.API_TOKENS} element={<ApiTokens {...stores} /> } />
                <Route path={APP_ROUTES.ENVIRONMENTS} element={<Environments {...stores} /> } />
                <Route path={APP_ROUTES.PROFILE} element={<Account {...stores} /> } />
                <Route path={APP_ROUTES.ACCOUNT} element={<Account {...stores} />} />
                <Route path={APP_ROUTES.ACCOUNT_BILLING} element={<Account {...stores} /> } />
                <Route path={APP_ROUTES.ACCOUNT_PLAN} element={<Account {...stores} /> } />
                <Route path={APP_ROUTES.ACCOUNT_TEAM} element={<Account {...stores} />} />
                <Route path={APP_ROUTES.ACCOUNT_PLAN_CHANGE} element={<Account {...stores} />} />
                <Route path={APP_ROUTES.SUPPORT} element={<Account {...stores} />} />
              </Route>            
              
          </Routes>
        </BrowserRouter>
        </>
    );
  }
);

export default App;
