import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import RequestsPerSecond from '../Charts/RequestsPerSecond';
import ResponseTime from '../Charts/ResponseTime';
//import TopEndpoints from '../Charts/TopEndpoints';
//import TrafficHeatmap from '../Charts/TrafficHeatMap';
import SummaryBlock from '../Dashboard/SummaryBlock';

import { formatNumber } from '../../utils/helpers';
import { getSummary } from '../../lib/apiClient'

let dashboardRefreshInterval;
const dashboardRefreshDelayValue = 10000;

const Dashboard = observer((props) => {
  const [loadingMetrics, setLoadingMetrics] = useState(true);
  const [requests, setRequests] = useState();
  const [responseTime, setResponseTime] = useState();
  const [errors, setErrors] = useState();
  const [blocked, setBlocked] = useState();
  const [dashboardRefreshDelay, setDashboardRefreshDelay] = useState(null);
  const [reloadTime, setReloadTime] = useState(null);
  
  const { bucketSid } = useParams();

  useEffect(() => {
    getSummaryMetrics();

    async function setupDashboardRefresh() {
      // set the interval so the requests will continue to run...
      dashboardRefreshInterval = setInterval(async () => {
        setReloadTime(new Date().getTime() / 1000);
        //await props.store.TrafficStore.checkForNewRequests(props.store.SessionStore.accountSid, props.store.SessionStore.bucket.sid);
      }, dashboardRefreshDelay);
    }

    if(dashboardRefreshDelay !== null){
      setupDashboardRefresh();
    }    

    return () => {
      // ensure the interval is clear so we are not monitoring for new requests...
      clearInterval(dashboardRefreshInterval);
    };
  


  },
  // eslint-disable-next-line
  [bucketSid, props.store.SessionStore.environment, props.store.MetricsStore.period, reloadTime, dashboardRefreshDelay]);

  async function getSummaryMetrics() {
        
    setLoadingMetrics(true);

    const period = props.store.MetricsStore.getPeriod();
    const range = period.getRange();

    const summaryResults = await getSummary(props.store.SessionStore.getAccountSid(), props.store.SessionStore.environment.sid, {
      startTime: range.start,
      endTime: range.end,
      bucketSid
    });

    if(summaryResults.successful){
      
      setRequests(
        {
          value: summaryResults.data.requests.current.amount,
          change: summaryResults.data.requests.change.amount,
          changeUnits: summaryResults.data.requests.change.units,
        }
      );

      setResponseTime(
        {
          value: summaryResults.data.responseTime.current.amount,
          change: summaryResults.data.responseTime.change.amount,
          changeUnits: summaryResults.data.responseTime.change.units,
        }
      );
      setErrors(
        {
          value: summaryResults.data.errors.current.amount,
          change: summaryResults.data.errors.change.amount,
          changeUnits: summaryResults.data.errors.change.units,
        }
      );

      setBlocked(
        {
          value: summaryResults.data.blocked.current.amount,
          change: summaryResults.data.blocked.change.amount,
          changeUnits: summaryResults.data.blocked.change.units,
        }
      );
    }

    setLoadingMetrics(false);
  }

  const handlePeriodChange = (periodSelected) => {
    props.store.MetricsStore.setPeriod(periodSelected);    
  };

  const toggleAutoReload = () => {
    if(dashboardRefreshDelay === null){
      // this is if the state is currently paused.
      setDashboardRefreshDelay(dashboardRefreshDelayValue);
    }else{
      // this is if the state is currently reloading.
      setDashboardRefreshDelay(null);
      // ensure the interval is clear so we are not monitoring for new requests...
      if(dashboardRefreshInterval){
        clearInterval(dashboardRefreshInterval);
      }      
    }
  };


  return (
    <>
      
        <div className="text-end mb-3">
            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
            <div className="btn-group" role="group">
                <button className="btn btn-outline-primary dropdown-toggle waves-effect" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {props.store.MetricsStore.getPeriod()?.label}
                </button>
                <ul className="dropdown-menu metrics-time-picker" aria-labelledby="dropdownMenu">
                {Object.keys(props.store.MetricsStore.periods).map((period) => (
                <li key={period}>
                    <button className="dropdown-item" type="button" onClick={() => handlePeriodChange(period)}>
                    {props.store.MetricsStore.periods[period].label} <small>{props.store.MetricsStore.periods[period].resolutionFriendly}</small>
                    </button>
                </li>
                ))}
                </ul>
            </div>
            <button type="button" title={ `${dashboardRefreshDelay ? 'Pause automatic reloading' : 'Automatically reload data every 10 seconds'}` } className={ `btn waves-effect ${dashboardRefreshDelay ? 'btn-outline-primary' : 'btn-primary'}` } onClick={toggleAutoReload}><i className={ `ti ${dashboardRefreshDelay ? 'ti-player-pause' : 'ti-player-play'}` }></i></button>               
            </div>
        </div>

        <div className="row">
        
            {/* Sales last year */}
            <SummaryBlock 
                title="Requests"
                value={formatNumber(requests?.value)}
                change={requests?.change}
                loading={loadingMetrics} />

            <SummaryBlock 
                title="Avg. Response Time"
                value={responseTime?.value && responseTime?.value > 0 ? formatNumber(Math.round(responseTime?.value)) + " ms" : "--"}
                change={responseTime?.change}
                loading={loadingMetrics} />

            <SummaryBlock 
                title="Errors"
                value={formatNumber(errors?.value)}
                change={errors?.change}
                loading={loadingMetrics} />
  
            <SummaryBlock 
                title="Blocked"
                value={formatNumber(blocked?.value) || 0}
                change={blocked?.change}
                loading={loadingMetrics} />

        </div>

        {/*
        <div className="row mb-3">
            <div className="col-md-12">
            <Events />
            </div>
        </div>
            */}
        <div className="row mb-3">
            <div className="col-md-12">
            <RequestsPerSecond store={props.store} reloadTime={reloadTime}  />
            </div>
        </div>

        <div className="row mb-3">
            <div className="col-md-12">
            <ResponseTime store={props.store} reloadTime={reloadTime} />
            </div>
        </div>
{/** 
        <div className="row mb-3">
            <div className="col-md-12">
            <TrafficHeatmap store={props.store} reloadTime={reloadTime} />
            </div>
        </div>
        
             <div className="row mb-3">
                <div className="col-md-12">
                <TopEndpoints />
                </div>
            </div>
        */}
       
      
     </>
  );
});

export default Dashboard;



