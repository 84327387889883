import React from 'react';
import { observer } from 'mobx-react';

const GenericLoader = observer((props) => {

  const displayMessage = ()=>{
    if(props.message){
      return props.message;
    }
    return 'Loading...'
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
    <div className="text-center">
        <div className="col-md">
            <div className="spinner-border spinner-border-lg text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
        <p className="mt-3">{displayMessage()}</p>
    </div>
</div>
  );
});

export default GenericLoader;
