import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useForm } from "react-hook-form";
import { createEnvironment, updateEnvironment, deleteEnvironment } from '../../lib/apiClient';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const EnvironmentModal = observer((props) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ mode: 'onSubmit'});


    useEffect(() => {
      // set the default on load...
      if(props.environment){
        reset({
          environmentName: props.environment ? props.environment.name : ""
        });
      }
      return ()=>{
        reset();
      }
    // eslint-disable-next-line
    }, [props.environment]);

    const resetForm = ()  => {
      reset({
        //environmentName: props.environment ? props.environment.name : ""
        environmentName: ""
      });
    }

    const onSubmit = async(data, e)  => {
        
        let result;

        if(!props.environment){
            const params = {
                accountSid : props.store.SessionStore.accountSid,
                name: data.environmentName,
                isDefault: false
            };
            result = await createEnvironment(params);
        }else{
            const params = {...props.environment};
            params.accountSid = props.store.SessionStore.accountSid;
            params.name = data.environmentName;
            
            result = await updateEnvironment(params);
        }
         

        if(result.successful){
            // Reload the Environments into the store...
            await props.store.SessionStore.loadEnvironments();
            // close the modal..
            props.close();            
            // reset the form..
            resetForm();

            if(props.onComplete){
                props.onComplete(result.data);
            }

        }else{
            // TODO: handle error...
            //console.log(createResult);
        }

    } 

    const onError = (errors, e) => {
        //console.log(errors);
    };

    const handleClose = () => {
      //console.log(errors);
      resetForm();
      props.close();
    };
   
    const handleDeleteEnvironment = async(data, e)  => {
  
      const params = {
        accountSid: props.store.SessionStore.getAccountSid(),
        environmentSid: props.environment.sid
      }
  
      const response = await deleteEnvironment(params);
  
      if(response.successful){
        // did we just delete the environment we are in?
        if(props.environment.sid === props.store.SessionStore.environment.sid){
          props.store.SessionStore.resetToDefaultEnvironment();
        }       
        // Reload the Environments into the store...
        await props.store.SessionStore.loadEnvironments();
        // reset the form..
        resetForm();
        // close the modal..
        props.close(); 

        if(props.onComplete){
          props.onComplete(response.data);
        }
      }
     
    };

  return (
    <>
    {props.mode !== "delete" ? (
    <Modal show={props.show} onHide={handleClose}>
      <form className="modal-content" onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Header closeButton>
          <Modal.Title>{!props.environment ? (<>Create New</>):(<>Edit</>)} Environment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
          <div className="col mb-3">
              <label htmlFor="environmentName" className="form-label">Name*</label>
              <input {...register("environmentName", { required: true, maxLength: 50 })} className="form-control" placeholder="Environment Name" />
              {errors.environmentName?.type === 'required' && <div className="error-message">Please enter a environment name</div>}
              {errors.environmentName?.type === 'maxLength' && <div className="error-message">The environment name cannot be longer than 50 characters</div>}
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
        </form>
      </Modal>

      ) :(

      <Modal show={props.show} onHide={handleClose} >
          <form className="modal-content" onSubmit={handleSubmit(handleDeleteEnvironment, onError)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Environment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            When deleting <strong>{props.environment?.name}</strong> environment, the following will occur:

            <ul className="mt-3">
              <li>API Tokens associated to the environment will be deleted.</li>
              <li>Workflows associated to the environment will be unlinked. The workflows will not actually be deleted.</li>
              <li>All request data will be deleted. However, this does not reduce monthly request utilization count.</li>
            </ul>
            To proceed with deleting the environment, type <i><strong>DELETE {props.environment?.name}</strong></i> below.
            <input {...register("deleteEnvironmentName", { required: true, validate: (value)=> {
                if(value !== `DELETE ${props.environment.name}`){
                  return `You must type DELETE ${props.environment.name} exactly.`
                }
                return true;          
            }})} className="form-control mt-2" placeholder={`DELETE ${props.environment?.name}`} />
            {errors.deleteEnvironmentName && <div className="error-message text-danger">{errors.deleteEnvironmentName.message}</div>}
            {errors.deleteEnvironmentName?.type === 'required' && <div className="error-message text-danger">You must type <i>DELETE {props.environment.name}</i> exactly.</div>}       

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="danger" type="submit">Delete Environment</Button>
          </Modal.Footer>
          </form>
      </Modal>

      )}
      



    </>
  );
});

export default EnvironmentModal;