import React, { useState } from 'react';
import { observer } from 'mobx-react';
import LoadingRequests from '../Components/Request/LoadingRequests';



const Traffic = observer((props) => {

  const [loading] = useState(true);

  return (
    <>
        <div className="row">
          <div className="">
            <div className="card">
              {loading === true ? (<LoadingRequests />) : (
                <>
                  <div className="card-body">
                   
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      

    </>
  );
});

export default Traffic;



