
import { DEFAULT_FEATURE_SETTINGS } from '../utils/constants'
class Subscription {

    constructor(options) {

        this.accountSid = options.subscription.accountSid;
        this.isActive = options.subscription.isActive;
        this.isDemo = options.subscription.isDemo;
        this.isTrial = options.subscription.isTrial;
        this.planId = options.subscription.planId;
        this.stageId = options.subscription.stageId;
        this.stageLabel = options.subscription.stageLabel;
        this.trialExpiresDate = options.subscription.trialExpiresDate;
        this.expiresDate = options.subscription.expiresDate;
        this.renewalDate = options.subscription.renewalDate;

        // merge the account features with the default...
        this.features = {...DEFAULT_FEATURE_SETTINGS, ...options.subscription.features};

        return this;
    }

    /**
     * Check if the subscription has a specific feature.
     * 
     * @param {string} feature - The feature to check for in the subscription.
     * @returns {boolean} - Returns true if the feature is present, otherwise false.
     */
    hasFeature(feature){
        if(this.features && this.features[feature]){
            return true;
        }
        return false;    
    }
    
    /**
     * Retrieves a specific feature from the subscription if it exists.
     * 
     * @param {string} feature - The feature to retrieve from the subscription.
     * @returns {any} - The value of the feature if present, otherwise null.
     */
    getFeature(feature){
        if(this.hasFeature(feature)){
            return this.features[feature];
        }
        return null;
    }
};

export default Subscription;