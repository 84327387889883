import { observer } from 'mobx-react';
import { KB_URL } from '../../utils/constants';
import NewWorkflowButton from './NewButton';

const NoWorkflows = observer((props) => {
    return (
        <div className="text-center flex-column">
            <h3>No Workflows created</h3>
            <p>There were no requests found using the search criteria provided.</p>
            <p>Review your search string and try again.</p>
            <p><a href={`${KB_URL}/articles/jW7r4J9q/searching-requests`} target="_blank" rel="noreferrer">Check our docs</a>, if you need help constructing your search string.</p>
            <NewWorkflowButton {...props} handleShowBuilder={props.handleShowBuilder} />
        </div>  
    );
  });
  
  export default NoWorkflows;