import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { createWorkflow } from '../../lib/apiClient';
import { Modal } from 'react-bootstrap';
import AlertMessage from '../Common/Alert';

const NewWorkflowFromScratch = (props) => {
    const { streamViewSid } = useParams();    
    const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm();
    const [errorMessage, setErrorMessage] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const onSubmit = async(data, e)  => {
        // ensure the error message is removed in case it is showing...
        setShowErrorMessage(false);

        const params = {
            accountSid : props.store.SessionStore.accountSid,
            bucketSid : props.store.SessionStore.bucket.sid,
            environmentSid : props.store.SessionStore.environment.sid,
            isPerEnvironment : false,
            streamViewSid: streamViewSid,
            name: data.workflowName,
            signin: true
        };

        const createWorkflowResult = await createWorkflow(params);

        if(createWorkflowResult.successful){
            // set the form.
            reset();
            // call the post create function
            props.onPostCreation(createWorkflowResult.data.workflow, createWorkflowResult.data.signin);
        }else{
            // TODO: handle error...
            setErrorMessage(createWorkflowResult.message);
            setShowErrorMessage(true);
        }
    } 

    const onError = (errors, e) => {
        console.log(errors);
    };

    const handleClose = () => {
        // reset the form...
        reset();
        props.setShow(false);
    }

  return (
    <>

    <Modal show={props.show} onHide={handleClose}>
    <form onSubmit={handleSubmit(onSubmit, onError)}>
    <Modal.Header closeButton>
          <Modal.Title>Create Workflow</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <AlertMessage icon="ti-alert-circle" show={showErrorMessage}>{errorMessage}</AlertMessage>
                <div className="col mb-3">
                    <label htmlFor="workflowName" className="form-label">Name*</label>
                    <input {...register("workflowName", { required: true, maxLength: 50 })} className="form-control" placeholder="Workflow Name" autoComplete='false' />
                    {errors.workflowName?.type === 'required' && <div className="error-message">Please enter a workflow name</div>}
                    {errors.workflowName?.type === 'maxLength' && <div className="error-message">The workflow name cannot be longer than 50 characters</div>}
                </div>
            </div>
            
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick={()=>props.setShow(false)} className="btn btn-label-secondary" data-bs-dismiss="modal">Cancel</button>
                {!isSubmitting ? (
                    <button type="submit" className="btn btn-primary">Create</button>
                ) : (
                    <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border me-1" role="status" aria-hidden="true"></span>
                    Creating...
                </button>
                )}  
            </Modal.Footer>
            </form>
      </Modal>

        
    </>
  );
}

export default NewWorkflowFromScratch;