import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { createApiToken, deleteApiToken } from '../../lib/apiClient';
import { showToast } from '../../utils/helpers';
import Card from './Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AlertMessage from "../Common/Alert";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const EnvironmentTokens = observer((props) => {
  //const [loading] = useState(true);
  const [show, setShow] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [tokenToDelete, setTokenToDelete] = useState({});
  const formRef = useRef(null);
  const [created, setCreated] = useState(false);
  const [token, setToken] = useState();
  const [copied, setCopied] = useState(false);
  const [validated, setValidated] = useState(false);
  
  useEffect(() => {    
    setTokens(props.store.ApiKeyStore.getEnvironmentTokens(props.environment.sid));
  
    // eslint-disable-next-line
  }, [props.store.ApiKeyStore.tokens]);

  const closeDelete = () => {

    setShowDelete(false);
  };

  const showDeleteConfirmation = (token) => {
    setTokenToDelete(token);
    setShowDelete(true);
  }
  
  const deleteToken = async ()  => {
    
    const params = {
      accountSid: props.store.SessionStore.getAccountSid(),
      apiTokenSid: tokenToDelete.sid
    }

    const response = await deleteApiToken(params);

    if(response.successful){
      await props.listApiTokens();
      closeDelete();
      showToast("API Token Deleted!");
    }
   
  };

  const closeCreate = () => {
    setValidated(false);
    setShow(false)
    setCreated(false);
    formRef.current?.reset();
  };
  const showCreate = () => {
    
    setShow(true);
    setValidated(false);
    setCreated(false);
    // make sure the form is reset...
    formRef.current?.reset();
  }

  const handleSubmit = async (event)  => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    setValidated(true);

    event.preventDefault();
    event.stopPropagation();

    const formData = new FormData(formRef.current);
    
    const params = {
      accountSid: props.store.SessionStore.getAccountSid(),
      environmentSid: props.environment.sid,
      name: formData.get('name'),
      role: formData.get('role')
    }

    const response = await createApiToken(params);

    if(response.successful){
      setToken(response.data);
      await props.listApiTokens();
      setCreated(true);
      
    }
  
  };

  const NewTokenButton = () => {
    return (
            <>
              <button className="btn btn-primary" onClick={showCreate} title="Create a new API Token">
                <i className="ti ti-circle-plus me-1"></i> New Token
              </button>
            </>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
        
          <div className="card mb-4">
            <div className="card-header d-flex flex-column flex-sm-row text-sm-start text-center">
              <div className="flex-grow-1">
                <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                <h5>{props.environment.name} Tokens</h5>
                {tokens.length > 0 ? (<NewTokenButton />):(<></>)}
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  {tokens.length > 0 ? (
                    <>
                    {tokens.map((token) => (
                      <Card key={token.sid} token={token} store={props.store} delete={showDeleteConfirmation} />
                    ))}
                    </>
                  ) : (
                    <>
                      <div className="card shadow-none bg-lighter border border-secondary">
                        <div className="card-body text-center mt-3">
                          <p>There are no API tokens created for the <strong>{props.environment.name}</strong> environment.</p>
                          <p><NewTokenButton /></p>                          
                        </div>
                      </div>
                    </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={closeCreate} dialogClassName="api-token-dialog">
            {!created ? (
              <>
               <Modal.Header closeButton>
                <Modal.Title>New {props.environment.name} Token</Modal.Title>
              </Modal.Header>
                    <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>

                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control required type="text" name="name"  placeholder="API Token Name" />
                      <Form.Control.Feedback type="invalid">
                            Name is required
                      </Form.Control.Feedback>
                      <Form.Text className="text-muted">
                        Provide a name for this API token.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="role">
                      <Form.Label>Role</Form.Label>
                      <Form.Select required name="role" aria-label="Role">
                        <option></option>
                        <option value="administrator">Administrator</option>
                        <option value="user">User</option>
                        <option value="ingest">Ingest</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                            Select a role for this token.
                      </Form.Control.Feedback>
                      <Form.Text className="text-muted">
                        Select the role for this API token.
                      </Form.Text>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button type="submit">Create Token</Button>
                  </Modal.Footer>
                </Form>
              </>
            ) : (
              <>
               <Modal.Header closeButton>
                <Modal.Title>Token Created</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AlertMessage show="true" variant="primary">
                  The new token has been created and is provided below.<br/><br/><strong>Be sure you copy this to a safe place, you will only see it this one time.</strong>
                </AlertMessage>
                
                <p className="text-center pt-3">
                  {token.token}
                  <CopyToClipboard text={token.token} onCopy={() => {
                    setCopied(true);
                    // only show the message for a bit.
                    setTimeout(() => {
                        setCopied(false);
                    }, "2000");
                    }}>
                    <Button type="button" variant="secondary" className="mt-4">Copy Token</Button>
                  </CopyToClipboard>              
                </p>              
                {copied && <p className="text-danger text-center">Token copied to clipboard.</p>}
              </Modal.Body>
              <Modal.Footer>
                <Button type="button" onClick={()=>closeCreate()}>Close</Button>
              </Modal.Footer>
              </>
            )}
            
      </Modal>

      <Modal show={showDelete} onHide={closeDelete} >
        
          <Modal.Header closeButton>
            <Modal.Title>Delete API Token</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete <strong>{tokenToDelete.name}</strong>?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={deleteToken}>Delete Token</Button>
          </Modal.Footer>
        
      </Modal>

    </>
  );
});

export default EnvironmentTokens;
