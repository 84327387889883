

import React from 'react';
import uuid from 'react-uuid';
import {KB_URL} from '../../utils/constants';

const TracesTab = (props) => {
    let lastTraceTime = 0;
  return (
    <>
        {props.traces.length > 0 ? (
            <ul className="timeline">
                <li className="timeline-item timeline-item-transparent">
                    <span className="timeline-point timeline-point-primary"></span>
                    <div className="timeline-event">
                        <div className="timeline-header mb-3">
                            <h6 className="mb-0 text-body">Request Started</h6>
                            <small className="text-muted"></small>
                        </div>
                    </div>
                </li>
        
                {props.traces.map((item, index) => {
                    const previousTraceTime = lastTraceTime;
                    lastTraceTime = item.time;
                    return (   
                        <li key={uuid()} className="timeline-item timeline-item-transparent">
                            <span className="timeline-point timeline-point-primary"></span>
                            <div className="timeline-event">
                                <div className="timeline-header">
                                    <h6 className="mb-0 text-body">{item.data}</h6>
                                    <small className="text-muted">+ {Math.round(((item.time) - previousTraceTime) / 1000)} ms</small>
                                </div>
                            </div>
                        </li>
                    )})}
                </ul>
        ) : (
            <div className="alert alert-warning" role="alert">
                No traces were logged for this request. <a href={`${KB_URL}/articles/y9gpP8WM/tracing-requests`} className="alert-warning" target="_blank" rel="noreferrer"><u>Learn more</u></a> about adding tracing requests.
            </div>
        )}      
    </>
  );
}

export default TracesTab;

