import React from 'react';
import { observer } from 'mobx-react';
import { Link } from "react-router-dom";
import Avatar from './Avatar';
import Breadcrumbs from './Breadcrumbs';
import { KB_URL, APP_ROUTES } from '../../utils/constants';
import { replacePathPlaceholders } from '../../utils/helpers';

  const Header = observer((props) => {
  
  return (
    <>

    
    <nav id="layout-top-navbar"> 

                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h4 className="fw-bold py-0 mb-0">
                      <Breadcrumbs {...props} />
                      
                    </h4>
                  </div>
                  <div className="email-pagination d-sm-flex d-none align-items-center flex-wrap justify-content-between justify-sm-content-end">
                  <ul className="navbar-nav flex-row align-items-center ms-auto">
                {/* <!-- Data Location --> 
                <li className="nav-item dropdown-language dropdown me-2 me-xl-0">
                  <a className="nav-link dropdown-toggle hide-arrow" href={handleClick} data-bs-toggle="dropdown">
                    <i className="fi fi-us fis rounded-circle me-1 fs-3"></i>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href={handleClick} data-language="en">
                        <i className="fi fi-us fis rounded-circle me-1 fs-3"></i>
                        <span className="align-middle">US</span>
                      </a>
                    </li>
                  </ul>
                </li>*/}
                {/* <!--/ Data Location --> */}

            

                {/* <!-- User --> */}
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  
                  {/* eslint-disable-next-line */}
                  <a className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <Avatar {...props} />
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    {/*
                    <li>
                      <a className="dropdown-item" href="pages-account-settings-account.html">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <Avatar {...props} />
                          </div>
                          
                          <div className="flex-grow-1">
                            <span className="fw-semibold d-block">{props.store.SessionStore.user?.firstName} {props.store.SessionStore.user?.lastName}</span>
                            <small className="text-muted">Admin</small>
                          </div>
                          
                        </div>
                      </a>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    */}
                    <li>
                      <Link to={replacePathPlaceholders(APP_ROUTES.PROFILE, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="dropdown-item" data-o-person-activity="Clicked to view profile">
                        <i className="ti ti-user-check me-2 ti-sm"></i>
                        <span className="align-middle">Profile</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={replacePathPlaceholders(APP_ROUTES.ACCOUNT, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="dropdown-item" data-o-person-activity="Clicked to view account">
                        <i className="ti ti-building me-2 ti-sm"></i>
                        <span className="align-middle">Account</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={replacePathPlaceholders(APP_ROUTES.ACCOUNT_TEAM, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="dropdown-item" data-o-person-activity="Clicked to view team members">
                        <i className="ti ti-users me-2 ti-sm"></i>
                        <span className="align-middle">Team Members</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={replacePathPlaceholders(APP_ROUTES.ACCOUNT_PLAN, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="dropdown-item" data-o-person-activity="Clicked to view plan">
                        <span className="d-flex align-items-center align-middle">
                          <i className="flex-shrink-0 ti ti-chart-candle me-2 ti-sm"></i>
                          <span className="flex-grow-1 align-middle">Plan</span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={replacePathPlaceholders(APP_ROUTES.ACCOUNT_BILLING, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="dropdown-item" data-o-person-activity="Clicked to view billing">
                        <span className="d-flex align-items-center align-middle">
                          <i className="flex-shrink-0 ti ti-credit-card me-2 ti-sm"></i>
                          <span className="flex-grow-1 align-middle">Billing</span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <Link to={replacePathPlaceholders(APP_ROUTES.API_TOKENS, {bucketSid: props.store.SessionStore.getActiveBucketSid()})} className="dropdown-item" data-o-person-activity="Clicked to view api tokens">
                        <i className="ti ti-key me-2 ti-sm"></i>
                        <span className="align-middle">API Tokens</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={APP_ROUTES.ENVIRONMENTS} className="dropdown-item" data-o-person-activity="Clicked to view environments">
                        <i className="ti ti-hierarchy me-2 ti-sm"></i>
                        <span className="align-middle">Environments</span>
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <a className="dropdown-item" href={KB_URL} target="_blank" rel="noreferrer" data-o-person-activity="Clicked to view documentation">
                        <i className="ti ti-book me-2 ti-sm"></i>
                        <span className="align-middle">Help / Documentation</span>
                      </a>
                    </li>
                    <li>
                      <Link className="dropdown-item" id="posthog-early-access-features-button" data-o-person-activity="Clicked early feature access">
                        <i className="ti ti-gift me-2 ti-sm"></i>
                        <span className="align-middle">Early Feature Access</span>
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <Link to={APP_ROUTES.SIGN_OUT} className="dropdown-item" data-o-person-activity="Clicked to log out">
                        <i className="ti ti-logout me-2 ti-sm"></i>
                        <span className="align-middle">Log Out</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                {/*<!--/ User -->*/}
              </ul>
              </div>
            </div>
           
          </nav>
          </>
  );
});

export default Header;