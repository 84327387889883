import React, { useEffect, useState }  from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import TrafficLog from './TrafficLog';
import TrafficSearch from './TrafficSearch';
import NoRequestsFound from './Request/NoRequestsFound';
import NoErrorsFound from './Request/NoErrorsFound';
import NoCapturesFound from './Request/NoCapturesFound';
import GettingStarted from '../Pages/GettingStarted';

const TrafficList = observer((props) => {
  const [noRecordsComponent, setNoRecordsComponent] = useState();
  //const { streamViewSid } = useParams();
  const location = useLocation();

  useEffect(() => {   
    // reset the store all together...
    props.store.TrafficStore.resetView(props.criteria, props.view);
/*
    if(streamViewSid){
      // find the stream view and set it to the active one...    
      props.store.SessionStore.streamViews.some(view => {
          if (view.sid === streamViewSid) {
            props.store.SessionStore.streamView = view;
              return true; // Stop the loop
          }
          props.store.SessionStore.streamView = null;
          
          return false;
      });
      console.log(props.store.SessionStore.streamView);
    }
*/
    const searchParams = new URLSearchParams(location.search);
    const hasCriteria = searchParams.get('criteria');

    if(hasCriteria){
      props.store.TrafficStore.resetView(props.criteria, props.view);
      props.store.TrafficStore.addCriteria(hasCriteria);
    } 
    listTraffic({prepend: false, append: false, from: null});   
    
    // decide which no records component to use based on the view...
    if(props.view === 'errors'){
      setNoRecordsComponent(<NoErrorsFound props={props} />);
    }else if(props.view === 'captures'){
      setNoRecordsComponent(<NoCapturesFound {...props} />);
    }else{
      setNoRecordsComponent(<NoRequestsFound props={props} />);
    }


    // Cleanup function
    return () => {
      // when removing, set the stream view back to null
      //props.store.SessionStore.streamView = null;
    };

  // eslint-disable-next-line
  }, [location, props.store.SessionStore.environment]);

  async function listTraffic(options) {
    await props.store.TrafficStore.listTraffic(options);
  }

    // if the bucket is not yet connected, we need to show the getting started page to get them going with their SDK setup...
    if(!props.store.SessionStore.bucket.appIsConnected){
      return (<GettingStarted {...props} />);
    }

  return (
    <>
        <TrafficSearch {...props} view={props.view}  />  
        <div className="p-2">
          <TrafficLog {...props} 
                view={props.view} 
                criteria={props.criteria} 
                defaultUserDefinedCriteria={props.store.TrafficStore.userDefinedCriteria} 
                NoRecordsFound={noRecordsComponent} />
        </div>
    </>
  );
});

export default TrafficList;



