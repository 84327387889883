import React from 'react';
import { observer } from 'mobx-react';
import RequestTableRow from './Row';

const RequestTable = observer(({props, requestData}) => {
  return (
    <>
      <div className="alert alert-warning" role="alert" style={{ display: props.store.TrafficStore.hasNewRequests  ? 'block' : 'none' }}>
        
      <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex overflow-hidden align-items-center">
              {props.store.TrafficStore.newRequestCount === 1 ? (
                <>There is {props.store.TrafficStore.newRequestCount} new request.</>
              ) : (
                <>There are {props.store.TrafficStore.newRequestCount} new requests.</>
              )}
            </div>
            <div className="d-flex align-items-center">
              <button type="button" className="btn btn-xs btn-outline-warning me-2">
                  <span className="tf-icons ti-xs ti ti-refresh me-1"></span> Load New
              </button>
            </div>
          </div>
        
      </div>
      <ul className="list-unstyled mb-0">
        {requestData.map((request) => (
            <RequestTableRow key={request.sid} request={request} store={props.store} />
        ))}               
      </ul>
    </>
  );
});

export default RequestTable;