import React from 'react';
import { observer } from 'mobx-react';
import ChartLoader from '../Charts/ChartLoader';

const SummaryBlock = observer((props) => {

    return (
        <>
        <div className="col-xl-3 col-md-4 col-6 mb-4">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">{props.title} </h5>
              <small className="text-muted">{props.subtitle}</small>
            </div>
            <div className="card-body pt-0">
              <div className="d-flex justify-content-between align-items-center mt-3 gap-3">
                {props.loading  ? (<>
                  <ChartLoader />
                </>) : (
                  <>
                    <h4 className="mb-0">{props?.value}</h4>
                    <small className="text-danger">{props?.change ? (<>{props.change}%</>) : (<></>)}</small>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        </>
    );
});

export default SummaryBlock;
