import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { updateBucket, getBucket, deleteBucket } from '../../lib/apiClient';
import { showToast, replacePathPlaceholders } from '../../utils/helpers';
import { APP_ROUTES } from '../../utils/constants';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const GeneralSettings = observer((props) => {
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState(false);

    const getFormValues = async() =>{
        const bucket = await getBucket({accountSid : props.store.SessionStore.accountSid, bucketSid : props.store.SessionStore.bucket.sid});
        if(bucket.successful){
            return {
                bucketName: bucket.data.name,
                verifySslCerts : bucket.data.verifySslCerts
            }
        }else{
            // display some error...
        }
    }
    
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: async () => {
            return await getFormValues()
        }
    });


    useEffect(() => {

        async function setupValues() {
            reset(await getFormValues());
        }
          setupValues();
        
      // eslint-disable-next-line
      }, [props.store.SessionStore.bucket]);
    

    const onSubmit = async (data, e) => {
        const params = {
            accountSid : props.store.SessionStore.accountSid,
            bucketSid : props.store.SessionStore.bucket.sid,
            name: data.bucketName,
            verifySslCerts: data.verifySslCerts === 'true' || data.verifySslCerts ? true : false,
        };
        
        const updateResult = await updateBucket(params);

        if(updateResult.successful){
            showToast("Bucket Updated!");
            // Reload the buckets into the store...
            await props.store.SessionStore.loadBuckets();
            //reset();
            setValue('bucketName', data.bucketName);
            setValue('verifySslCerts', data.verifySslCerts);
            
            // make sure this bucket was updated as the active which will push the changes in to the active bucket object in the store...
            props.store.SessionStore.setActiveBucket(props.store.SessionStore.bucket.sid);
        }else{
            // TODO: handle error...
            //console.log(updateResult);
        }


    }

    const onError = (errors, e) => {
        //console.log(errors);
    };


    const closeDelete = () => {

        setShowDelete(false);
      };
    
      const showDeleteConfirmation = (token) => {
        
    
        setShowDelete(true);
      }
      
      const handleDeleteBucket = async ()  => {
        const params = {
            accountSid : props.store.SessionStore.accountSid,
            bucketSid : props.store.SessionStore.bucket.sid
        };
        
        const response = await deleteBucket(params);
        
        
        if(response.successful){
            showToast("Bucket Deleted!");
            await props.store.SessionStore.loadBuckets();
            if(props.store.SessionStore.buckets.length){
                await props.store.SessionStore.setActiveBucket(props.store.SessionStore.buckets[0].sid);
            }            
            closeDelete();          
            navigate(replacePathPlaceholders(APP_ROUTES.DASHBOARD, {bucketSid: props.store.SessionStore.bucket.sid}));
        }
        
      };

    return (
        <>
        
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="row">
                    <div className="col-sm">
                        <div className="mb-3">
                            <label htmlFor="bucketName" className="form-label">Name*</label>
                            <input {...register("bucketName", { required: true, maxLength: 50 })} className="form-control" placeholder="Bucket Name" />
                            {errors.bucketName?.type === 'required' && <div className="text-danger">Please enter a bucket name</div>}
                            {errors.bucketName?.type === 'maxLength' && <div className="text-danger">The bucket name cannot be longer than 50 characters</div>}

                        </div>
                        <div className="form-check mt-3">
                            <input {...register("verifySslCerts")} className="form-check-input" type="checkbox" value="true" id="verifySslCerts" />
                            <label className="form-check-label" htmlFor="verifySslCerts">
                                Verify SSL Certificates
                            </label>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-12 text-center mt-4">
                        <button type="submit" className="btn btn-primary me-sm-3 me-1 waves-effect waves-light">Save Changes</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-end mt-4">
                        {/*eslint-disable-next-line*/}
                        <a href="#" className="dropdown-item text-danger" onClick={showDeleteConfirmation}>Delete Bucket</a>
                    </div>
                </div>
                
            </form>

            <Modal show={showDelete} onHide={closeDelete} >
        
                <Modal.Header closeButton>
                    <Modal.Title>Delete Bucket</Modal.Title>
                </Modal.Header>
                {props.store.SessionStore.buckets.length > 1 ? (
                <Modal.Body>
                    
                    Are you sure you want to delete <strong>{props.store.SessionStore.bucket.name}</strong>?
                    <br/><br/>
                    Your bucket will remain recoverable for 30 days. After this period, it and all associated requests will be permanently deleted automatically.
                </Modal.Body>
                ) : (
                    <Modal.Body>
                        You cannot delete this bucket since all accounts must have at least one bucket. Create a new bucket first. 
                        <br /><br />
                    </Modal.Body>
                )}
                {props.store.SessionStore.buckets.length > 1 ? (
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteBucket}>Delete Bucket</Button>
                </Modal.Footer>
                ) : (<></>)}
                
            </Modal>



        </>
    );
});

export default GeneralSettings;



