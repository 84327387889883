import {API_URL, API_ROUTES } from '../utils/constants';
import axios from 'axios';
import  secureLocalStorage  from  "react-secure-storage";

async function request(options) {
    
    if(!options.headers){
        options.headers = {};
    }

    // allow for a token to be passed into options...
    if(!options.hasOwnProperty("token")){
      options.headers.Authorization = `Bearer ${getToken()}`;
    }else{
      options.headers.Authorization = `Bearer ${options.token}`;
    }
        
    if(!options.method){
        options.method = "GET";
    }

    const response = await axios(options);

    return response;

}

export function getToken() {
    const queryParameters = new URLSearchParams(window.location.search)
    return secureLocalStorage.getItem('token') || queryParameters.get("session");
}

export async function getUser(token) {
  
  try {
    
    const response = await request({
        url: API_ROUTES.GET_USER,
        token: token || getToken()
      });

    const result = {
        successful: response.status === 200,
        user: response.data || null
    }

    return result;
  }
  catch (err) {
    //console.log('getAuthenticatedUser, Something Went Wrong', err);
    return {successful: false};
  }
}

export async function signout(token) {
  
  try {
    
    const response = await request({
        url: `${API_URL}/authentication/signout`,
        token: token || getToken()
      });

    const result = {
        // Consider successful even if the token was not located b/c that means it is no longer valid - this is an error code of 2005
        successful: response.status === 200 || (response.status === 401 && response.data.code === 2005),
        user: response.data || null
    }

    return result;
  }
  catch (err) {
   // console.log('getAuthenticatedUser, Something Went Wrong', err);
    return {successful: false};
  }
}

export async function getAccount(accountSid) {
  
  try {
    
    const response = await request({
        url: `${API_URL}/accounts/${accountSid}?expand=true`
      });

    const result = {
        successful: response.status === 200,
        data: response.data || null
    }

    return result;
  }
  catch (err) {
    return {successful: false, error: err};
  }
}
export async function listBuckets(accountSid) {
  
    try {
      
      const response = await request({
          url: `${API_URL}/accounts/${accountSid}/buckets`
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function listApiTokens(accountSid) {
  
    try {
      
      const response = await request({
          url: `${API_URL}/accounts/${accountSid}/apiTokens`
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function createApiToken(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/apiTokens`;

      const response = await request({
          url: _url,
          method: 'POST',
          data : {
            name : params.name,
            role : params.role,
            environmentSid: params.environmentSid
          }
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function updateApiToken(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/apiTokens/${params.apiTokenSid}`;

      const response = await request({
          url: _url,
          method: 'PUT',
          data : {
            name : params.name,
            role : params.role || "user",
          }
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function deleteApiToken(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/apiTokens/${params.apiTokenSid}`;

      const response = await request({
          url: _url,
          method: 'DELETE'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function listRequests(accountSid, bucketSid, environmentSid, options) {
  
    try {
      
      let _url = `${API_URL}/accounts/${accountSid}/buckets/${bucketSid}/requests`;
      let queryOptions = [];

      queryOptions.push(`environmentSid=${environmentSid}`);

      if(options?.criteria?.length){
        queryOptions.push(`criteria=${options.criteria.trim()}`);
      }

      if(options?.lastRequestTime?.length){
        queryOptions.push(`last=${options.lastRequestTime}`);
      }

      if(options?.from?.length){
        queryOptions.push(`from=${options.from}`);
      }

      if(options?.limit){
        queryOptions.push(`limit=${options.limit}`);
      }

      if(options?.summary?.length){
        queryOptions.push(`summary=${options.summary}`);
      }

      if(queryOptions.length){
        _url = `${_url}?${queryOptions.join('&')}`
      }
      
      const response = await request({
          url: _url
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      //console.log(err);
      return {successful: false, error: err};
    }
  }

  export async function getRequest(accountSid, bucketSid, requestSid) {
  
    try {
      
      let _url = `${API_URL}/accounts/${accountSid}/buckets/${bucketSid}/requests/${requestSid}`;
      
      const response = await request({
          url: _url
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      //console.log(err);
      return {successful: false, error: err};
    }
  }

  export async function createBucket(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/buckets`;

      const response = await request({
          url: _url,
          method: 'POST',
          data : {
            name : params.name
          }
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function updateBucket(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/buckets/${params.bucketSid}`;

      const response = await request({
          url: _url,
          method: 'PUT',
          data : {
            name : params.name,
            verifySslCerts : params.verifySslCerts,
          }
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function deleteBucket(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/buckets/${params.bucketSid}`;

      const response = await request({
          url: _url,
          method: 'DELETE'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

 

  export async function getBucket(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/buckets/${params.bucketSid}`;

      const response = await request({
          url: _url,
          method: 'GET'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function getWorkflows(params) {
  
    try {

      let queryOptions = [];

      let _url = `${API_URL}/accounts/${params.accountSid}/buckets/${params.bucketSid}/workflows`;

      if(params?.streamViewSid?.length){
        queryOptions.push(`streamViewSid=${params.streamViewSid.trim()}`);
      }
      
      if(params?.environmentSid?.length){
        queryOptions.push(`environmentSid=${params.environmentSid.trim()}`);
      }
      
      if(queryOptions.length){
        _url = `${_url}?${queryOptions.join('&')}`
      }
      
      const response = await request({
          url: _url,
          method: 'GET'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function getWorkflow(params) {
  
    try {

      let _url = `${API_URL}/accounts/${params.accountSid}/buckets/${params.bucketSid}/workflows/${params.workflowSid}`;
      
      const response = await request({
          url: _url,
          method: 'GET'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function deleteWorkflow(params) {
  
    try {

      let _url = `${API_URL}/accounts/${params.accountSid}/buckets/${params.bucketSid}/workflows/${params.workflowSid}`;
      
      const response = await request({
          url: _url,
          method: 'DELETE'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function workflowSignin() {
  
    try {

      let _url = `${API_URL}/authentication/workflow`;
      
      const response = await request({
          url: _url,
          method: 'GET'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  /**
 * Creates a new workflow for a specific account and bucket.
 * 
 * @param {Object} params - The parameters for creating the workflow.
 * @param {string} params.accountSid - The account ID for which the workflow is created.
 * @param {string} params.bucketSid - The bucket ID under which the workflow is created.
 * @param {string} params.name - The name of the workflow.
 * @param {string} [params.streamViewSid=null] - The stream view ID associated with the workflow (optional, default is null).
 * @param {boolean} [params.signin=false] - Indicates if sign-in is required for the workflow (optional, default is false).
 * 
 * @returns {Object} An object containing the success status and data of the created workflow.
 */
  export async function createWorkflow(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/buckets/${params.bucketSid}/workflows`;

      const response = await request({
          url: _url,
          method: 'POST',
          data : {
            name: params.name,
            streamViewSid: params.streamViewSid || null,
            environmentSid : params.environmentSid,
            isPerEnvironment: params.isPerEnvironment || false,
            signin: params.signin || false
          }
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      let error;

      if(err.response?.data?.message){
        error = err.response.data.message;
      }
      
      return {successful: false, message: error};
    }
  }

  export async function getStreamViews(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/buckets/${params.bucketSid}/streamViews`;

      const response = await request({
          url: _url,
          method: 'GET'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function createStreamView(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/buckets/${params.bucketSid}/streamViews`;

      const response = await request({
          url: _url,
          method: 'POST',
          data : {
            name: params.name,
            icon: params.icon,
            criteria: params.criteria
          }
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function updateStreamView(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/buckets/${params.bucketSid}/streamViews/${params.streamViewSid}`;

      const response = await request({
          url: _url,
          method: 'PUT',
          data : {
            name: params.name,
            icon: params.icon,
            criteria: params.criteria
          }
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function deleteStreamView(params) {
    
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/buckets/${params.bucketSid}/streamViews/${params.streamViewSid}`;

      const response = await request({
          url: _url,
          method: 'DELETE'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function getSummary(accountSid, environmentSid, options) {
  
    try {
      
      let _url = `${API_URL}/accounts/${accountSid}/metrics/summary`;

      let queryOptions = [];

      queryOptions.push(`environmentSid=${environmentSid}`);

      if(options?.startTime?.length){
        queryOptions.push(`startTime=${options.startTime.trim()}`);
      }
      if(options?.endTime?.length){
        queryOptions.push(`endTime=${options.endTime.trim()}`);
      }
      if(options?.bucketSid?.length){
        queryOptions.push(`bucketSid=${options.bucketSid.trim()}`);
      }
      if(options?.apiSid?.length){
        queryOptions.push(`apiSid=${options.apiSid.trim()}`);
      }
      if(options?.pathSid?.length){
        queryOptions.push(`pathSid=${options.pathSid.trim()}`);
      }
      

      if(queryOptions.length){
        _url = `${_url}?${queryOptions.join('&')}`
      }

      const response = await request({
          url: _url,
          method: 'GET'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function getThroughputMetrics(accountSid, environmentSid,  options) {
  
    try {
      
      let _url = `${API_URL}/accounts/${accountSid}/metrics/throughput`;

      let queryOptions = [];

      queryOptions.push(`environmentSid=${environmentSid}`);

      if(options?.startTime?.length){
        queryOptions.push(`startTime=${options.startTime.trim()}`);
      }
      if(options?.endTime?.length){
        queryOptions.push(`endTime=${options.endTime.trim()}`);
      }
      if(options?.resolution?.length){
        queryOptions.push(`resolution=${options.resolution.trim()}`);
      }
      if(options?.bucketSid?.length){
        queryOptions.push(`bucketSid=${options.bucketSid.trim()}`);
      }
      if(options?.apiSid?.length){
        queryOptions.push(`apiSid=${options.apiSid.trim()}`);
      }
      if(options?.pathSid?.length){
        queryOptions.push(`pathSid=${options.pathSid.trim()}`);
      }
      

      if(queryOptions.length){
        _url = `${_url}?${queryOptions.join('&')}`
      }

      const response = await request({
          url: _url,
          method: 'GET'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function getResponseTime(accountSid, environmentSid, options) {
  
    try {
      
      let _url = `${API_URL}/accounts/${accountSid}/metrics/responseTime`;

      let queryOptions = [];

      queryOptions.push(`environmentSid=${environmentSid}`);

      if(options?.startTime?.length){
        queryOptions.push(`startTime=${options.startTime.trim()}`);
      }
      if(options?.endTime?.length){
        queryOptions.push(`endTime=${options.endTime.trim()}`);
      }
      if(options?.resolution?.length){
        queryOptions.push(`resolution=${options.resolution.trim()}`);
      }
      if(options?.bucketSid?.length){
        queryOptions.push(`bucketSid=${options.bucketSid.trim()}`);
      }
      if(options?.apiSid?.length){
        queryOptions.push(`apiSid=${options.apiSid.trim()}`);
      }
      if(options?.pathSid?.length){
        queryOptions.push(`pathSid=${options.pathSid.trim()}`);
      }
      

      if(queryOptions.length){
        _url = `${_url}?${queryOptions.join('&')}`
      }

      const response = await request({
          url: _url,
          method: 'GET'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function getTrafficPunchcard(accountSid, environmentSid, options) {
  
    try {
      
      let _url = `${API_URL}/accounts/${accountSid}/metrics/trafficPunchcard`;

      let queryOptions = [];

      queryOptions.push(`environmentSid=${environmentSid}`);

      if(options?.startTime?.length){
        queryOptions.push(`startTime=${options.startTime.trim()}`);
      }
      if(options?.endTime?.length){
        queryOptions.push(`endTime=${options.endTime.trim()}`);
      }
      if(options?.bucketSid?.length){
        queryOptions.push(`bucketSid=${options.bucketSid.trim()}`);
      }
      if(options?.apiSid?.length){
        queryOptions.push(`apiSid=${options.apiSid.trim()}`);
      }
      if(options?.pathSid?.length){
        queryOptions.push(`pathSid=${options.pathSid.trim()}`);
      }
      

      if(queryOptions.length){
        _url = `${_url}?${queryOptions.join('&')}`
      }

      const response = await request({
          url: _url,
          method: 'GET'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function listEnvironments(accountSid) {
  
    try {
      
      const response = await request({
          url: `${API_URL}/accounts/${accountSid}/environments`
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function createEnvironment(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/environments`;

      const response = await request({
          url: _url,
          method: 'POST',
          data : {
            name : params.name,
            isDefault : params.isDefault
          }
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function updateEnvironment(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/environments/${params.sid || params.environmentSid}`;

      const data = {};

      if(params.hasOwnProperty('name')){
        data.name = params.name;
      }

      if(params.hasOwnProperty('isDefault')){
        data.isDefault = params.isDefault;
      }

      const response = await request({
          url: _url,
          method: 'PUT',
          data
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }

  export async function deleteEnvironment(params) {
  
    try {
      
      let _url = `${API_URL}/accounts/${params.accountSid}/environments/${params.environmentSid}`;

      const response = await request({
          url: _url,
          method: 'DELETE'
        });
  
      const result = {
          successful: response.status === 200,
          data: response.data || null
      }
  
      return result;
    }
    catch (err) {
      return {successful: false, error: err};
    }
  }