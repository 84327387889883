import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";

import Ready from './Components/Ready';
import Preparing from './Components/Preparing';

import { APP_ROUTES } from '../utils/constants';

const AppLayout = observer((props) => {
  const navigate = useNavigate();

  useEffect(() => {
    
    async function checkAuthentication() {
        // the user just signed in...get their info..
        const loginResult = await props.store.SessionStore.isAuthenticated();
    
        if(loginResult && loginResult.authenticated){
          if(loginResult.redirectTo){
            navigate(loginResult.redirectTo);
          }          
        }else{
          navigate(APP_ROUTES.SIGN_IN);
        }
    }
    
    // now call the get User Function...
    checkAuthentication();

  // eslint-disable-next-line
  }, [navigate]);

    return (
      <>
        {props.store.SessionStore.phase === 'ready' ? (<Ready {...props} />) : (<Preparing {...props} />)}
      </>
    );
});

export default AppLayout;